import React, {  useContext, useEffect,  useRef, useState } from "react";
import Table from "@tiptap/extension-table";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import styleTableEditor from "./styleTableEditor";
import { CustomTableCell } from "./helpers/main";
import ReactComponent from "./TableCell/Extension";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";



const TableVue = ({  contentDefault, }) => {
  const { values } = useContext(PanelContext);
  const cl = styleTableEditor({blockClick: values.blockClickCreateItemsBlock });
  const ref = useRef(null);
  const [disableButton, setDisableButton] = useState(true);


  const editor = useEditor({
    onFocus({ editor, event }) {

    },
    extensions: [StarterKit,
      Image.configure({
        inline: true,
        allowBase64: true
      }),
      Table.configure({
        resizable: true
      }), TableRow, TableHeader,
      CustomTableCell(),
      ReactComponent,
      // History,
    ],
    content:  contentDefault,
    editable: false,
    editorProps: {
      attributes: {
        class: "wrapper-table"
      }
    }
  });



  const handleDisable = () => {
    // editor.commands.setCellAttribute('backgroundColor', 'rgba(200, 200, 255, 0.4)')
    if (disableButton) {
      setDisableButton(false);
    }
  };




  return (<div className={cl.root}>
    <div className={cl.editorWrapper}>
      <div id="table-container"></div>
    </div>

      <div className={cl.tableWrapper}>
        <div className={cl.editorWrapperVue} ref={ref} onClick={handleDisable} >
          {editor && <EditorContent editor={editor} />}
        </div>
      </div>

  </div>);
};

export default TableVue;

