import React, {createContext, useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import CreateItems from "./CreateItems";
import AddToolbar from "../../../../../BlockPlugin/components/AddToolbar";
import {createInProgressSnack} from "@zert-packages/components/common/snackBar";
import {FormattedMessage} from "react-intl";
import {useSnackbar} from "notistack";
import {createImgApi, initImgApi, storeBlockApi} from "@zert-packages/components/InfoElement/components/API";
import {useDispatch, useSelector} from "react-redux";
import {uploadImageFile} from "../../../../../PhrasePlugin/servicesMiddlewares";
import {v4 as uuidv4} from "uuid";

export const BlockItemsContext = createContext(null);

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        // maxWidth: 414,
        padding: 11,
        background: "#E2F2F6"
    },

}))

const AddToolbarDialog = ({values, it, tableData, setValues, setTableData}) => {
    const cl = useStyles();
    const [block, setBlock] = useState({
        browseBlock: null,
        createType: null,
        item: [],
        open: true,
        phraseEditor: {
            editorState: null,
            contentStateJSON: null,
            maxLength: null,
            phrase: '',
        },
    })
    const snackbar = useSnackbar();
    const {mylocale} = useSelector((state) => state);
    const dispatch = useDispatch();



    const handleClose = () => {
        setBlock(pre => ({...pre, open: false}))
    };

    const saveImg = async (val) => {
        const createImgItem = await createImgApi(values.tableDefault.elementInfo.versionId, mylocale, {
            imageName: val.info.image.name,
            imagePreview: val.right
        });

        console.log('createImgItem', createImgItem)

    };

    const createImg = async () => {
        const generatingSnackbar = createInProgressSnack(
            snackbar,
            <FormattedMessage id="CLM.UploadingFile.CreateImage" defaultMessage="CreateImage..."/>
        );
        generatingSnackbar;
        // await initImgApi(
        //     {
        //         locale: mylocale,
        //         parentVersionId: values.tableDefault.elementInfo.versionId
        //     });

        dispatch(uploadImageFile(uuidv4(), saveImg));
        snackbar.closeSnackbar(generatingSnackbar);
    }



    return (<Dialog
        open={block.open}
        onClose={handleClose}
        classes={{
            paperScrollPaper: cl.root
        }}
    >
        <BlockItemsContext.Provider value={{block, setBlock, values: block, setValues: setBlock}}>
            <AddToolbar contextBlock={BlockItemsContext} createImg={createImg}/>
            {block.createType && <CreateItems
                blockCreate={block}
                setBlockCreate={setBlock}
                values={values}
                it={it}
                tableData={tableData}
                setValues={setValues}
                setTableData={setTableData}
            />}
        </BlockItemsContext.Provider>
    </Dialog>)
}

export default AddToolbarDialog