import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { AppBar, Tabs, Tab } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  AppBar: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    zIndex: 10
  }
});

const tabsList = [
  {
    label: <FormattedMessage id="Administration.RiskRowTemplateView.TabGeneral" defaultMessage="Save" />
  },
  {
    label: <FormattedMessage id="Administration.RiskRowTemplateView.TabGeneral" defaultMessage="Another" />
  }
];

function Header() {
  const [tabIndex, setTabIndex] = useState(null);
  const [operationDone, setOperationDone] = useState(false);
  console.log(tabIndex);

  const onChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const isDisabled = (index) => {
    if (tabIndex !== index) {
      return true;
    }
    return false;
  };

  return (
    <AppBar position="static" color="default" className={useStyles().AppBar}>
      <Tabs
        value={tabIndex}
        variant="scrollable"
        scrollButtons
        indicatorColor="primary"
        textColor="primary"
        onChange={onChangeTabIndex}
        allowScrollButtonsMobile>
        {tabsList.map((tab, index) => (
          <Tab disabled={false} {...tab} key={index} />
        ))}
      </Tabs>
    </AppBar>
  );
}

export default injectIntl(Header);
