import React, { useEffect, useState } from "react";
import { Checkbox, Dialog, FormControlLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { handleValue } from "clm/src/plugins/BlockPlugin/utils/main";
import getTranslation from "@zert-packages/utils/getTranslation.old";
import DropMenu from "../../../DropMenu";
import { useSelector } from "react-redux";
import { storeBlockApi } from "../API";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 262,
    padding: "5px 10px",
    paddingBottom: 10,
    background: "#E2F2F6",
    minHeight: 168
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "flex-end",
    "& svg": {
      cursor: "pointer"
    }
  },
  wrapperList: {
    marginTop: 5,
    border: "1px solid lightgray",
    minHeight: 100,
    padding: 5
  },
  listItem: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#CECECE"
    }
  },
  listItemActive: {
    background: "blue",
    color: "#ffffff"
  },
  wrapperCheckbox: {
    "& label": {
      marginBottom: 0
    }
  },
  wrapperDropMenu: {
    display: "grid",
    rowGap: 7
  }
}));

const AvailableDialog = ({ block, setBlock, parentItemCreate }) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const { formatTemplate } = useSelector((state) => state);
  const [template, setTemplate] = useState({});
  const blockItem = block.blockItem;
  const [available, setAvailable] = useState({
    indexSelectedAvailable: 0,
    languageSeparated: blockItem.languageSeparated,
    itemGroup: [],
    selectedAvailable: null,
    template: null,
    blockItem: blockItem,
    change: false
  });
  const settings = available.blockItem?.settings?.settings ? available.blockItem?.settings?.settings : available.template?.settings?.properties;
  const classItem = "se.zert.backend.rest.common.pojo.ClientSettings$ChoiceSetting";



  const handleProperty = (val, name) => {
    setTemplate(pre => ({ ...pre, [name]: getTranslation(val.labelObject) }));
    if (!available.blockItem?.settings) {
      const res = settings.reduce((acu, cur, idx) => {
        if (idx === val.index) {
          return [...acu, {
            ...cur,
            value: [val.value],
            values: [val.value],
            property: settings[idx],
            class: classItem
          }];
        }
        const value = [cur.items.find(it => it.selected).value];
        return [...acu, { ...cur, value: value, values: value, property: settings[idx], class: classItem }];
      }, []);
      setAvailable(pre => ({
        ...pre, blockItem: {
          ...pre.blockItem,
          settings: { settings: res, template: { properties: settings } },
          format: available.template
        },
        change: true
      }));
      return setBlock(pre => ({
        ...pre, blockItem: {
          ...pre.blockItem,
          settings: { settings: res, template: { properties: settings } },
          format: available.template
        }
      }));
    }
    const res = settings.reduce((acu, cur, idx) => {
      if (idx === val.index) {
        return [...acu, { ...cur, value: [val.value], values: [val.value] }];
      }
      return [...acu, cur];
    }, []);

    const dataResult = {
      ...available.blockItem, settings: {
        ...available.blockItem.settings,
        settings: res
      }
    };
    setAvailable(pre => ({
      ...pre, blockItem: dataResult, change: true
    }));
    setBlock(pre => ({
      ...pre, blockItem: dataResult
    }));
  };

  const handleClose = async () => {
    setOpen(false);
    const data = {};
    if (available.indexSelectedAvailable === 0) {
      const val = { ...available.blockItem, settings: null, format: null };
      data.blockItem = val;
      setBlock(pre => ({ ...pre, blockItem: val }));
    } else {
      const val = {
        ...available.blockItem,
        format: available.template
      };
      data.blockItem = val;
      setBlock(pre => ({ ...pre, blockItem: val }));
    }
    const items = parentItemCreate.items.reduce((acu, cur) => {
      if (cur.identifier === data.blockItem.identifier) {
        return [...acu, data.blockItem];
      }
      return [...acu, cur];
    }, []);
    const createItemData = {
      name: parentItemCreate.name,
      targetLocale: parentItemCreate.targetLocale,
      format: parentItemCreate.format,
      items
    };
      await storeBlockApi(parentItemCreate.versionId, parentItemCreate.targetLocale.language, createItemData);
  };

  const handleCheckbox = (e) => {
    const val = e.target.checked;
    setAvailable(pre => ({
      ...pre,
      languageSeparated: val,
      blockItem: { ...pre.blockItem, languageSeparated: val },
      change: true
    }));
    setBlock(pre => ({ ...pre, blockItem: { ...pre.blockItem, languageSeparated: val } }));
  };
  const handleAvailable = (val, name, group) => {
    handleValue(val, name, setAvailable, group);
  };


  const handleSelectedAvailable = (val, idx) => {
    if (available.indexSelectedAvailable === idx) {
      return;
    }
    if (idx > 0) {
      const findTemplate = formatTemplate.paragraphFormats.find(el => el.identifier === val.identifier);
      const findSelected = findSelectedTemplate(findTemplate);
      if (findSelected) {
        setTemplate(findSelected);
      }
      const property = findTemplate?.settings?.properties;
      if (property) {
        const findProperty = property.reduce((acu, cur, idx) => {
          const value = [cur.items.find(it => it.selected).value];
          return [...acu, { ...cur, value: value, values: value, property: property[idx], class: classItem }];
        }, []);
        setAvailable(pre => ({
          ...pre,
          blockItem: {
            ...pre.blockItem,
            settings: { settings: findProperty, template: findTemplate },
            format: available.template
          },
          selectedAvailable: val,
          indexSelectedAvailable: idx,
          template: findTemplate,
          change: true
        }));
        return setBlock(pre => ({
          ...pre, blockItem: {
            ...pre.blockItem,
            settings: { settings: findProperty, template: findTemplate },
            format: available.template
          }
        }));
      }
      return handleAvailable(false, false, {
        selectedAvailable: val,
        indexSelectedAvailable: idx,
        change: true,
        template: findTemplate
      });
    }
    handleAvailable(false, false, {
      selectedAvailable: val,
      indexSelectedAvailable: idx,
      change: true
    });
  };

  const findSelectedTemplate = (findTemplate) => {
    if (!findTemplate) {
      return;
    }
    if (!blockItem?.format || blockItem.format.identifier !== available.itemGroup[available.indexSelectedAvailable].identifier) {
      return findTemplate.settings.properties.reduce((acu, cur) => {
        const name = cur.items.find(el => el.selected).labelObject;
        return { ...acu, [cur.identifier]: name ? getTranslation(name) : "" };
      }, {});
    }
    if (settings && settings.length > 0) {
      return settings.reduce((acu, cur) => {
        const name = cur.property.items.find(el => el.value === cur?.value[0])?.labelObject;
        return { ...acu, [cur.property.identifier]: name ? getTranslation(name) : "" };
      }, {});
    }
  };

  useEffect(() => {
    if (parentItemCreate.availableChildFormats && formatTemplate) {
      const itemsTemplate = [{ identifier: "No paragraph format" }, ...parentItemCreate.availableChildFormats];
      const findSelected = blockItem?.format ? itemsTemplate.findIndex(el => el.identifier === blockItem?.format.identifier) : 0;
      console.log('parentItemCreate', parentItemCreate);
      setAvailable(pre => ({
        ...pre,
        indexSelectedAvailable: findSelected,
        itemGroup: itemsTemplate
      }));
    }
  }, [parentItemCreate, formatTemplate]);

  useEffect(() => {
    if (available.indexSelectedAvailable > 0 && formatTemplate?.paragraphFormats) {
      const findTemplate = formatTemplate.paragraphFormats.find(el => el.identifier === available.itemGroup[available.indexSelectedAvailable].identifier);
      const findSelected = findSelectedTemplate(findTemplate);
      if (findSelected) {
        setTemplate(findSelected);
      }
      setAvailable(pre => ({ ...pre, template: findTemplate }));
    }
  }, [available.indexSelectedAvailable]);

  console.log('available', {available, parentItemCreate});

  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperList}>
      {available.itemGroup.map((el, i) => <div
        className={available.indexSelectedAvailable === i ? cl.listItemActive : cl.listItem}
        key={i} onClick={() => handleSelectedAvailable(el, i)}>
        {el.label ?  getTranslation(el.label) : el.identifier }</div>)}
    </div>
    <div className={cl.wrapperCheckbox}>
      <FormControlLabel
        control={<Checkbox
          checked={available.languageSeparated}
          onChange={handleCheckbox}
        />} label="Language separated" />
    </div>
    {available.indexSelectedAvailable === 0
      ? <p> No properties available</p>
      : <div className={cl.wrapperDropMenu}>
        {available.template && available.template?.settings.properties.map((el, i) => <div key={i}>
          {el?.labelObject && getTranslation(el.labelObject)}
          <DropMenu
            selected={template[el?.identifier]}
            dropItem={el?.items.map(it => ({ ...it, name: getTranslation(it.labelObject), index: i }))}
            name={el?.identifier}
            setDropItem={handleProperty}
          />
        </div>)}
      </div>}
  </Dialog>);
};

export default AvailableDialog;
