import React, {useState} from 'react';
import {SparePartContext} from "../../store/SparePartContext";
import Structure from "./Structure";
import PartOf from "../../../LayoutPlugin/components/PartOf";
import MettaData from "@zert-packages/components/InfoElement/mettaData";

const SideMenu = () => {
    const [expandedPanel, setExpandedPanel] = useState({
        expandedPanelOne: true,
        expandedPanelTwo: false,
        expandedPanelThree: false
    });
    return (
        <div style={{width: '300px', height: '100%', display: "flex", flexDirection: "column",}}>
            <Structure expanded={expandedPanel} setExpanded={setExpandedPanel} context={SparePartContext} />
            <PartOf expanded={expandedPanel} setExpanded={setExpandedPanel}/>
            <MettaData
                expanded={expandedPanel}
                setExpanded={setExpandedPanel}
                context={SparePartContext}
            />
        </div>
    );
};

export default SideMenu;