import {
  handlePost,
  handlePostParamAsString,
  handlePostResultAsString,
  handlePostTextAsParam,
  handleQuery
} from '@zert-packages/actions/api';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { createInProgressSnack } from '@zert-packages/components/common/snackBar';
import exportRMPMockUP from '@zert-packages/utils/exportRMPMockUP';

const keyAdapter = (key) => {
  if (key === 'identifier') return 'Id';
  if (key === 'value') return 'Value';
  return key;
};

const reduceProperties = (values) => {
  const newValues = values.flatMap(({ key, value }) => {
    if (typeof value === 'object') {
      return Object.keys(value).map((newKey) => {
        //  let obj = {};

        // obj[keyAdapter(newKey) + key] = value[newKey];
        return { key: `${key}/${keyAdapter(newKey)}`, value: typeof value[newKey] === 'object' ? '' : value[newKey] };
      });
    }
    return [{ key, value }];
  });
  console.log(newValues);
  return newValues;
};
export const duplicateCommonAPI = (versionId, copyTranslations, name) =>
  handlePostTextAsParam(`/common/duplicateCommon/${versionId}/${copyTranslations || false}`, name);

export const duplicateChecklistAPI = async (versionId, name, snackbar) => {
  const generatedReport = exportRMPMockUP();
  const generatingSnackbar = createInProgressSnack(
    snackbar,
    <FormattedMessage id="RM.RiskAssessment.Snackbar.Duplicate" defaultMessage="...duplicating Risk Assessment" />
  );

  const result = handlePostTextAsParam(`/checklist-react/duplicateCheckList/${versionId}`, name);
  await checkReportStatus(result.right, snackbar, generatingSnackbar, generatedReport);

  return result;
};

export const duplicateProjectAPI = (versionId, name) =>
  handlePostTextAsParam(`/project/duplicateCommonProject/${versionId}`, name);

export const duplicateRiskAnalysesApi = async ({
  name,
  versionId,
  removeMeasures,
  removeResponsible,
  removeDeadline,
  removeParticipants,
  removeSessions,
  templateVersionId,
  snackbar,
  cellsMoved
}) => {
  const generatedReport = exportRMPMockUP();
  const generatingSnackbar = createInProgressSnack(snackbar, '...duplicating Risk Assessment');

  const query = [
    '/riskanalises/duplicateRiskAnalises',
    versionId,
    removeMeasures,
    removeResponsible,
    removeDeadline,
    removeParticipants,
    removeSessions,
    templateVersionId
  ].join('/');

  const result = await handlePost(query, { name, cellsMoved });
  await checkReportStatus(result.right, snackbar, generatingSnackbar, generatedReport);

  return result;
};

export const checkReportStatus = async (report, snackbar, generatingSnackbar) => {
  try {
    while (report !== null && report.active) {
      report = await handleQuery(`/report-react/updateProgressStatus/${report.callbackId}/false`);
    }
  } catch (e) {
    snackbar.closeSnackbar(generatingSnackbar);
  }
};

const reduceCriterias = ({ className, values }) => {
  return {
    className,
    values: reduceProperties(values)
  };
};

export const reduceValues = ({ allCompanies, criterias }) => {
  return {
    allCompanies,
    criterias: criterias.map((criteria) => reduceCriterias(criteria))
  };
};
export function renameApi(versionId, name) {
  return handlePostParamAsString(`/common/rename/${versionId}`, name);
}
export function getRootNodeApi() {
  return handleQuery('/explorer/getRootNode');
}

export function getChildNodesApi(ownerid) {
  return handleQuery(`/explorer/getChildNodes/${ownerid}`);
}

export function getNodeContentApi(ownerid) {
  return handleQuery(`/explorer/getNodeContent/${ownerid}`);
}
export function removeMultipleApi(callback_uuid, folder, elementIds) {
  return handlePost(`/explorer/remove/${callback_uuid}/${folder != null ? folder.versionId : -1}/true`, elementIds);
}
export function createSearchFolderAPI(loadingContentNodeId, folderName, search) {
  return handlePostResultAsString(`/search/createSearchFolder/${loadingContentNodeId}`, {
    name: folderName,
    clientSearch: reduceValues(search)
  });
}

export function updateSearchFolderAPI(id, folderName, search) {
  return handlePostResultAsString(`/search/updateSearchFolder/${id}`, {
    name: folderName,
    clientSearch: reduceValues(search)
  });
}

export function archiveMultipleApi(type, callback_uuid, elementIds) {
  if (type) {
    return handlePost(`/common/archive/${callback_uuid}`, elementIds);
  }
  return handlePost(`/common/unarchive/${callback_uuid}`, elementIds);
}
export function searchApi(search) {
  return handlePost('/search/search', reduceValues(search));
}
export function getSearchFolderApi(folderId) {
  return handleQuery(`/search/getSearchFolderQuery/${folderId}`);
}
export function cutAPI(folder, elementsid) {
  return handlePostResultAsString(`/explorer/cut/${folder != null ? folder.versionId : -1}`, elementsid);
}

export function addElementsToFolderApi(folderId, versionIds) {
  return handlePost(`/explorer/insertVersions/${folderId}`, versionIds);
}

export function createChildFolderApi(elementId, name) {
  return handlePostTextAsParam(`/explorer/createChildFolder/${elementId}`, name);
}

export function unArchiveMultipleApi(callback_uuid, folder, elementIds) {
  return handlePost(`/explorer/unarchive/${callback_uuid}/${+(folder != null ? folder.id : -1)}`, elementIds);
}
