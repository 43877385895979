import React, { useCallback, useContext, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import { createContext, useEffect, useRef, useState } from "react";
import {
  Editor,
  EditorState,
  getDefaultKeyBinding,
  convertFromRaw, KeyBindingUtil, convertToRaw, CompositeDecorator
} from "draft-js";

import { loadPhrase } from "../API";
import { updateDecorator } from "../utils/decorator";
import { adobeJsonEditor, findStyleImg, handleValue } from "../utils/main";
import { converterPhrase, addInsertText } from "../utils/converterPhrase";
import { getLengthOfSelectedText } from "../utils/getLengthOfSelectedText";
import mediaBlockRenderer from "./mediaBlockRenderer";
import TranslatorDropBlock from "./TranslatorDropBlock";
import { useSelector } from "react-redux";
import Header from "./header/Header";
import { createStyleMap } from "../utils/createStyleMap";
import SkeletonLoader from "./SkeletonLoader";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import { addImg, findTranslatorWord } from "../utils/PhraseEditor";
import { BlockItemsContext } from "../../BlockPlugin/BlockItems";
import "draft-js/dist/Draft.css";
import { findDecorator } from "./table/helpers/main";
import { v4 as uuidv4 } from "uuid";


const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    minWidth: 425,
  },
  editorWrapper1: {

  },
  editorWrapper: (props) => ({
    "& span": {
      fontFamily: "system-ui"
    },
    "& span::before": (props) => ({
      display: props.vue ? "" : "none"
    }),
    "& img": (props) => ({
      cursor: props.vue ? "pointer" : "default",
      backgroundColor: "#C8C8C8"
    }),
    "& .DraftEditor-root": {
      padding: props.vue ? 5 : 0
    },
    "& .notranslate.public-DraftEditor-content": {
      background: "none"
    },
    "& .public-DraftEditorPlaceholder-root": {
      marginBottom: -21,
      paddingLeft: 10
    },
    "& .DraftEditor-editorContainer": {
      zIndex: 100,
      position: "relative",
      "& figure": {
        display: "inline-block",
        margin: "0px 5px"
      },
      "&  div": {
        display: "inline-block",
        minWidth: 2
      }
    },
    "& .public-DraftEditor-content": {
      margin: 0,
      padding: 0,
      wordBreak: "break-all",
      minHeight: 24.75,
      boxSizing: "border-box",
      "&:focus": {
        borderColor: "#ffffff"
      }
    }
  }),
  disableContainer: {
    "&:before": {
      content: "\"\"",
      display: "block",
      height: "100%",
      width: "100%",
      position: "absolute",
      zIndex: 1000
    }
  }
}));

export const PhraseContext = createContext(null);


const PhraseEditor = ({ el, vue: vueProps, setDialogAddWord, context, newFraze, setPhrase, loadOne, phrase }) => {
  const ref = useRef(null);
  const approvedAt = el && el.elementInfo.approvedAt;
  const vue = vueProps && !approvedAt;
  const cl = useStyles({ vue });
  const contextPanel = useContext(context ? context : PanelContext);
  const [values, setValues] = useState({
    defaultText: null,
    editorState: EditorState.createEmpty(),
    aliasDecoratorWord: [],
    aliasDecorator: [],
    decorator: [],
    blockEditorGetApi: true,
    ref: ref,
    maxLengthValue: "",
    lengthValue: 0,
    styleMap: null,
    blockFormats: null,
    translator: false,
    disable: false,
    loader: false,
    typingTimeout: null,
    vue,
    treeItemSelectedContext: null,
    loadPhrase: false,
    selectedElement: null,
  });
  const [startSearch, setStartSearch] = useState(false);
  const { formatTemplate } = useSelector((state) => state);
  const { CLM, myLanguage } = useSelector((state) => state);
  const { block, setBlock } = useContext(BlockItemsContext) ?? {};
  const decoratorWord = [...values.decorator, ...values.aliasDecorator];
  const compositeDecorator = new CompositeDecorator(decoratorWord);
  const treeItemSelectedContext = contextPanel?.values?.treeItem;
  const mylocale = myLanguage.value


  const loadPhraseWrapper = async () => {
    if(values.loadPhrase || (el.elementInfo.versionId === values?.selectedElement?.elementInfo.versionId)){
      return
    }
    setValues(pre => ({ ...pre, loader: true, loadPhrase: true }));
    const phrase = await loadPhrase(el.elementInfo.versionId, mylocale, true);
    if (phrase) {
      adobeJsonEditor(phrase.elements,
        "editorState",
        setValues, convertFromRaw, EditorState, compositeDecorator, vue);
      const getLimitOfChars = phrase.limitOfChars ? phrase.limitOfChars : 0;
      setValues(pre => ({ ...pre, loader: false, maxLengthValue: getLimitOfChars, defaultText: phrase.text }));
    }
    setValues(pre => ({ ...pre, loader: false, loadPhrase: false, selectedElement: el }));
  };

// if
  const keyBindingFn = (event) => {
    if (!startSearch) {
      setStartSearch(true);
    }
    if (event.keyCode === 13) {
      return "add";
    }
    if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 13) {
      return "add";
    }
    if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 40) {
      return "focus";
    }
    return getDefaultKeyBinding(event);
  };

  const handleKeyCommand = (command) => {
    if (command === "space") {
      const res = addInsertText("\n ", values.editorState, EditorState);
      setValues(pre => ({ ...pre, editorState: res }));
      return "handled";
    }
    if (command === "add") {
      const res = addInsertText("↵", values.editorState, EditorState);
      setValues(pre => ({ ...pre, editorState: res }));
      return "handled";
    }
    return "not-handled";
  };

  const maxLengthEditor = () => {
    const currentContent = values.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = getLengthOfSelectedText(values);
    if (values.maxLengthValue !== 0 && values.maxLengthValue !== "" && currentContentLength - selectedTextLength > values.maxLengthValue - 1) {
      return "handled";
    }
  };

  const getFormatTemplate = () => {
    const createCustomStyleMap = createStyleMap(formatTemplate.textFormats);
    setValues(pre => ({ ...pre, styleMap: createCustomStyleMap }));
  };

  const onClick = () => {
  };


  const onChange = (editorState) => {
    if (values.typingTimeout) {
      clearTimeout(values.typingTimeout);
    }
    const currentContent = editorState.getCurrentContent();
    const contentStateJSON = convertToRaw(currentContent);
    const currentText = currentContent.getPlainText();
    if (values.disable) {
      return;
    }
    let text = currentText;

    const adobeAtomic = contentStateJSON.blocks.reduce((acu, cur, idx) => {
      const uuid = uuidv4();
      if (contentStateJSON.blocks[contentStateJSON.blocks.length - 1].type === "atomic" && cur.type === "atomic" && idx === contentStateJSON.blocks.length - 1) {
        if (cur.text.trim().length > 0) {
          return [...acu, { ...cur, "type": "unstyled", key: cur.key + uuid }];
        }
        return acu;
      }
      return [...acu, { ...cur, key: cur.key + uuid }];
    }, []);

    const rawContent = convertFromRaw({
      entityMap: contentStateJSON.entityMap,
      blocks: adobeAtomic
    });
    const state = EditorState.createWithContent(rawContent, compositeDecorator);
    const findAtomic = contentStateJSON.blocks[contentStateJSON.blocks.length - 1].type === "atomic";
    const resultState = findAtomic ? EditorState.moveFocusToEnd(state) : editorState;


    if (values.aliasDecoratorWord.length > 0) {
      text = values.aliasDecoratorWord.reduce((acc, el) => {
        return acc.replace(new RegExp(el.word, "g"), "");
      }, currentText);
    }
    if (values.defaultText === currentText || currentText.length === 0) {
      setValues((prev) => ({
        ...prev,
        editorState: resultState
      }));
      return;
    }
    if(contextPanel.setValues){
      contextPanel.setValues(pre => ({...pre, changeBlock: true}))
    }

    const timeout = setTimeout(() => {
      findTranslatorWord(
        text,
        resultState,
        mylocale,
        el,
        values,
        setValues
      );
    }, 2000);



    setValues((prev) => ({
      ...prev,
      typingTimeout: timeout,
      editorState: resultState

    }));
  };


  useEffect(() => {
    if (vue && !newFraze) {
      contextPanel.setValues(pre => ({
        ...pre,
        blockKeyRedactor: true
      }));
      const editorState = values.editorState.getCurrentContent();
      const content = convertToRaw(editorState);
      adobeJsonEditor(content,
        "editorState",
        setValues, convertFromRaw, EditorState, compositeDecorator, vue, newFraze);
      setValues(pre => ({ ...pre, vue }));
    }
  }, [vue]);

  useEffect(() => {
    if (formatTemplate?.textFormats) {
      getFormatTemplate();
    }
  }, [formatTemplate]);

  const memoizedOnChange = useMemo(() => onChange, [onChange]);

  useEffect(() => {
    if (+values.maxLengthValue > 0 && setBlock) {
      setBlock(pre => ({
        ...pre, phraseEditor: {
          ...pre.phraseEditor,
          maxLength: values.maxLengthValue
        }
      }));
    }
  }, [values.maxLengthValue]);

  useEffect(() => {
    if (values.editorState) {
      const newEditorStateWithDecorator = EditorState.set(values.editorState, {
        decorator: new CompositeDecorator(decoratorWord)
      });
      setValues(pre => ({ ...pre, editorState: newEditorStateWithDecorator }));
    }
  }, [values.decorator, values.aliasDecorator, values.lengthValue]);

  useEffect(() => {
    if (el?.elementInfo?.name.includes("<alias")) {
      const decorators = findDecorator(el.elementInfo.name);
      const res = updateDecorator(decorators);
      setValues(pre => ({ ...pre, aliasDecorator: res, aliasDecoratorWord: decorators }));
    }
  }, [el, vue]);

  useEffect(() => {
    if (vue && newFraze) {
      setTimeout(() => {
        const res = EditorState.moveFocusToEnd(values.editorState);
        setValues(pre => ({ ...pre, editorState: res }));
      }, 100);
    }
  }, [vue, el]);

  useEffect(() => {
    if (CLM.newImage && vue) {
      addImg(CLM.newImage.imageVersionId, values, setValues, EditorState);
    }
  }, [CLM.newImage, vue]);

  useEffect(() => {
    if (treeItemSelectedContext) {
      setValues(pre => ({ ...pre, treeItemSelectedContext }));
    }
  }, [treeItemSelectedContext]);

  useEffect(() => {
    if (values.editorState) {
      const contentState = values.editorState.getCurrentContent();
      const currentContentLength = contentState.getPlainText("").length;
      handleValue(currentContentLength, "lengthValue", setValues);
      const contentStateJSON = convertToRaw(contentState);
      const valueEditor = {
        editorState: values.editorState,
        contentStateJSON: contentStateJSON,
        maxLength: values.maxLengthValue,
        phrase: contentState.getPlainText("")
      };

      if (setBlock) {
        setBlock(pre => ({
          ...pre,
          phraseEditor: valueEditor
        }));
      }
      if (setPhrase) {
        setPhrase(valueEditor);
      }
    }
  }, [values.editorState]);

  useEffect(() => {
    if(vue && (values.defaultText || newFraze)){
      if(values.lengthValue === 0){
        if (!contextPanel?.values?.blockClickCreateItemsBlock) {
          contextPanel.setValues(pre => ({
            ...pre,
            blockClickCreateItemsBlock: true
          }));
        }
      }else {
        if(contextPanel.values.blockClickCreateItemsBlock){
          contextPanel.setValues(pre => ({
            ...pre,
            blockClickCreateItemsBlock: false
          }));
        }
      }
    }
  }, [values.lengthValue])

  useEffect(() => {
    if (phrase) {
      adobeJsonEditor(phrase.elements, "editorState", setValues, convertFromRaw, EditorState, compositeDecorator, vue);
      const getLimitOfChars = phrase.limitOfChars ? phrase.limitOfChars : 0;
      setValues(pre => ({ ...pre, loader: false, maxLengthValue: getLimitOfChars, defaultText: phrase.text }));
    }
  }, [phrase]);

  useEffect(() => {
    const type = el?.elementInfo?.mimeType.split("/").pop();
    if (!newFraze && el && !loadOne && !phrase && type === "zert-phrase" && mylocale) {
      loadPhraseWrapper();
    }
  }, [el, mylocale]);

  useEffect(() => {
    if (loadOne && !newFraze && el && mylocale && !el.softWareText) {
      loadPhraseWrapper();
    }
  }, [mylocale]);

  // console.log('el',mylocale,  el, )
  if (vue) {
    // console.log("block", block.phraseEditor.contentStateJSON);
    // console.log("CLM.newImage", CLM.newImage);
  }

  return (<PhraseContext.Provider value={{ values, setValues }}>
    {/*<div>*/}
    {/*  <p>1111</p>*/}
    {/*  <PhraseWrapper el={el} versionId={el.elementInfo.versionId} />*/}
    {/*</div>*/}
    {values.loader
      ? <SkeletonLoader height={"78"} />
      : <div className={cl.root}>
        {vue && <Header />}
        <div className={vue ? "" : cl.disableContainer}>
          <div className={cl.editorWrapper} style={{
            border: vue ? "1px solid black" : ""
          }}>
            <Editor
              onClick={onClick}
              wrapperClassName="wrapperClassName"
              myProp="hello"
              // disable={true}
              keyBindingFn={keyBindingFn}
              handleKeyCommand={handleKeyCommand}
              handleBeforeInput={maxLengthEditor}
              blockRendererFn={mediaBlockRenderer}
              ref={values.ref}
              onChange={memoizedOnChange}
              editorState={values.editorState}
              customStyleMap={values.styleMap}
              placeholder="Enter your phrase here"
            />
            {vue && values.translator && <TranslatorDropBlock
              setDialogAddWord={setDialogAddWord}
              focus={values.editorState.getSelection().getHasFocus()}
            />}
          </div>
        </div>
      </div>}
  </PhraseContext.Provider>);
};

export default PhraseEditor;