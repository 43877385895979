import { createInProgressSnack } from "../../common/snackBar";
import React from "react";
import { FormattedMessage } from "react-intl";
import { handlePluginAction } from "@zert-packages/actions/coreReducers";
import { storeBlockApi } from "../components/API";

export const findDisabled = (values, setCheckboxState) => {

  if (!values.treeItem || values.treeItem && values.treeItem.parentTreeItem) {
    return setCheckboxState(true);
  }
  setCheckboxState(false);
};


export const saveBlock = async (snackbar, dispatch, values, mylocale, saveAllItem) => {
  const item = values.parentItemChild;

  if (item?.elementInfo?.approvedAt) {
    return;
  }

  const generatingSnackbar = createInProgressSnack(
    snackbar,
    <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..." />
  );
  if (saveAllItem) {
    await saveAllItem();
  }


  const createBlockData = {
    ...item,
    name: values.valuesName,
    elementInfo: {
      ...item.elementInfo,
      name: values.valuesName
    }
  };

  await storeBlockApi(item.elementInfo.versionId, mylocale, createBlockData);
  dispatch(handlePluginAction(null));
  snackbar.closeSnackbar(generatingSnackbar);

};