import { makeStyles } from '@mui/styles';

const style = makeStyles((theme) => ({
    root: {
     '& .MuiAccordionDetails-root': {
        display: 'block'
      },
      '& .MuiCollapse-root': {
        position: 'absolute',
        width: '100%',
        height: 'Calc(100% - 64px)',
      },
      overflow: 'auto',
      '& .MuiCollapse-wrapper, .MuiAccordion-region': {
        height: '100%'
      }
    },

    scrollContainerWrapper: {
      // position: 'relative',
      // overflowX: 'hidden',
      // width: '100%',
      // overflow: 'auto'
    },
    scrollContainer: {
      // position: 'absolute',
      // width: '100%'
    },
    ExpandIcon: {
      '& .MuiAccordionSummary-expandIcon':  (props) => ({
        transform: props.expanded.expandedPanelTwo ? 'rotate(180deg)' : 'rotate(270deg)'
      })
    },
    rootAccordion: {
      borderTopLeftRadius: '0px !important',
      borderTopRightRadius: '0px !important',
      height: '100%',
      overflow: 'auto'
    },
    rootWrapper: {
      // background: '#E2F2F6',
      maxWidth: 500,
      paddingLeft: 15,
      paddingRight: 15,
      width: '100%',
      paddingTop: 15,
      paddingBottom: 100,
      '@media (max-width: 900px)': {
        paddingLeft: 5,
        paddingRight: 5
      }
    },
    dropMenuButton: {
      display: 'block',
      textTransform: 'capitalize',
      width: '100%',
      textAlign: 'inherit',
      background: '#ffffff',
      padding: '5px 15px',
      marginBottom: 40,
      '&:hover': {
        background: '#ffffff'
      },
      '& span:nth-child(2)': {
        display: 'none'
      }
    },
    dropMenuButtonFocus: {
      display: 'none'
    },

    dropMenuButtonIcon: {
      display: 'flex',
      width: 45,
      justifyContent: 'center',
      border: '1px solid #D3D3D3',
      borderRadius: 5,
      height: 35,
      alignItems: 'center',
      cursor: 'pointer',
      // marginTop: 40,
      margin: '0px 0px 10px 0px',
      '& svg': {
        width: '0.7em',
        height: '0.8em'
      }
    },
    wrapperInput: {
      borderBottom: '1px solid #D3D3D3',
      marginTop: 15
    },

    borderRoot: {
      background: '#D3D3D3',
      width: '100%',
      height: 1
    }
  }));

export default style;
