import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import CategoryDialog from './CategoryDialog';

const initActionState = {
  isOpen: false,
  isEdit: false,
  isRemove: false,
  selectedElement: -1
};

function CategoriesTab() {
  const [open, setOpen] = useState(false);
  const CLM = useSelector((state) => state.CLM);
  const [actionState, setActionState] = useState(initActionState);

  const handlerOpenDialog = (action) => {
    if (action === 1) {
      setActionState((prevState) => ({
        ...prevState,
        isEdit: true,
        isOpen: true
      }));
    } else if (action === 2) {
      setActionState((prevState) => ({
        ...prevState,
        isRemove: true,
        isOpen: true
      }));
    } else {
      setActionState((prevState) => ({
        ...prevState,
        isOpen: true
      }));
    }
  };

  const handlerCloseDialog = () => {
    setActionState(initActionState);
  };

  const handleListItemClick = (event, index) => {
    setActionState((prevState) => ({
      ...prevState,
      selectedElement: index
    }));
  };

  return (
    <div>
      <div>
        <List component="nav" aria-label="main mailbox folders">
          {CLM.index.categories &&
            CLM.index.categories.map((el, idx) => {
              return (
                <ListItem
                  button
                  selected={actionState.selectedElement === idx}
                  onClick={(event) => handleListItemClick(event, idx)}
                >
                  <Typography variant="h2">{el}</Typography>
                </ListItem>
              );
            })}
        </List>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}
        >
          <IconButton aria-label="add" size="large">
            <AddIcon fontSize="large" onClick={(event) => handlerOpenDialog(0)} />
          </IconButton>
          <IconButton aria-label="edit" size="large">
            <EditIcon fontSize="large" onClick={(event) => handlerOpenDialog(1)} />
          </IconButton>
          <IconButton aria-label="delete" size="large">
            <DeleteIcon fontSize="large" onClick={(event) => handlerOpenDialog(2)} />
          </IconButton>
        </div>
        {actionState.isOpen && <CategoryDialog handleClose={handlerCloseDialog} actionState={actionState} />}
      </div>
    </div>
  );
}

export default CategoriesTab;
