import {
  approveVersionApi,
  copyElementBlockApi, duplicateElementBlockApi,
  pasteElementBlockApi, saveCanvasItemPhrase, saveIndexApi,
  savePhraseApi,
  setTerm,
  storeBlockApi, tableStore
} from "../components/API";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import AlertTextDialog from "../components/dialog/AlertTextDialog";
import BufferAlertDialog from "clm/src/plugins/BlockPlugin/components/dialog/BufferAlertDialog";


export const saveStoreBlock = async (parentItemCreate, values, setValues, setBlock, newItems, items) => {
  //newItems
  const createItemData = {
    name: parentItemCreate.name,
    targetLocale: parentItemCreate.targetLocale,
    format: parentItemCreate.format,
    items
  };

  await storeBlockApi(parentItemCreate.versionId, parentItemCreate.targetLocale.language, createItemData);

  if (setValues) {
    setValues(pre => ({
      ...pre,
      parentItem: parentItemCreate,
      blockClickCreateItemsBlock: false,
      // treeItem: newItems,
      investmentLevel: parentItemCreate.investmentLevel,
      action: {
        ...pre.action,
        saveElement: newItems,
        parentItemCreate: { ...parentItemCreate, items, type: "save" }
      }
    }));
  }
  if (setBlock) {
    return setBlock(pre =>
      ({ ...pre, expandedToolbar: false, expandedToolbarBottom: false, createType: null,  }));
  }
};

export const saveIndex = async (values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, versionId) => {

  const name = block.nameBlock;
  const treeItem = {
    ...values.treeItem,
    elementInfo: { ...values.treeItem.elementInfo, name },
    name
  };
  const newIndex = await saveIndexApi(treeItem.elementInfo.versionId, treeItem);

  if (!newIndex) {
    return console.log("error newPhrase", newIndex);
  }

  if(parentItemCreate){
    const items = parentItemCreate.items.length > 0
      ? parentItemCreate.items.reduce((acu, cur, idx) => {
        if (idx === values?.action?.indexElementCreate) {
          if (parentItemCreate.type === "create") {
            if (block.expandedToolbarBottom) {
              return [...acu,  cur, newIndex,];
            }
            return [...acu, newIndex, cur];
          }
          return [...acu, newIndex];
        }
        return [...acu, cur];
      }, [])
      : [newIndex]

    await saveStoreBlock(parentItemCreate, values, setValues, setBlock, newIndex, items);
  }

}

const findSavePhrase = (values, block) => {
  const  treeItem = values.treeItem
  if(treeItem?.onlyTableSave || treeItem?.isSoftwareNewPhrase ){
    return  treeItem
  }
  return  block.blockItem;
}


export const savePhrase = async (values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, versionId, targetLocale) => {
  // const treeItem = values.treeItem;
  const treeItem = findSavePhrase(values, block)
  const phraseEditor = block.phraseEditor;
  const adobeImg = Object.keys(phraseEditor.contentStateJSON.entityMap).map(key => {
    const item = phraseEditor.contentStateJSON.entityMap[key];
    return {
      ...item,
      data: {
        ...item.data,
        width: item.data.width === "100%" ? 0 : item.data.width,
        height: item.data.height === "100%" ? 0 : item.data.height,
        lengthUnit: item.data.lengthUnit ? item.data.lengthUnit : "PIXELS",
        verticalAlign: item.data.verticalAlign ? item.data.verticalAlign : "MIDDLE",
        verticalAlignDistance: item.data.verticalAlignDistance ? item.data.verticalAlignDistance : 0,
        verticalAlignUnit: item.data.verticalAlignUnit ? item.data.verticalAlignUnit : "PERCENT",
        keepRatio: item.data.keepRatio ? item.data.keepRatio : false
      }
    };
  });

  const data = {
    targetLanguage: targetLocale || parentItemCreate.targetLocale,
    parentVersion: versionId,
    elements: { blocks: phraseEditor.contentStateJSON.blocks, entityMap: { ...adobeImg } },
    ...(phraseEditor.maxLength && { limitOfChars: phraseEditor.maxLength })
  };

  if (!treeItem && !values.createItem) {
    return;
  }

  const id = values?.createItem?.versionId || treeItem?.elementInfo?.versionId;

  console.log('2', {treeItem, id})
  const newPhrase = await savePhraseApi(id, data);


  if (!newPhrase) {
    return console.log("error newPhrase", newPhrase);
  }

  if (treeItem?.isSoftwareNewPhrase) {
    const newPhraseSoftware = await setTerm(treeItem.parentVersionId, id);
    if (newPhraseSoftware) {
      setValues(prev => ({
        ...prev, newPhrase: null,
        refresh: !prev.refresh
      }));
    }
  }

  if(parentItemCreate){
    const items = parentItemCreate.items.length > 0
      ? parentItemCreate.items.reduce((acu, cur, idx) => {
        if (idx === values?.action?.indexElementCreate) {
          if (parentItemCreate.type === "create") {
            if (block.expandedToolbarBottom) {
              return [...acu,  cur, newPhrase,];
            }
            return [...acu, newPhrase, cur];
          }
          return [...acu, newPhrase];
        }
        return [...acu, cur];
      }, [])
      : [newPhrase]
    console.log('111', {parentItemCreate, items, newPhrase, values});
// return
    await saveStoreBlock(parentItemCreate, values, setValues, setBlock, newPhrase, items);
  }
};


export const saveTable = async (values, block, mylocale, parentItemCreate, setValues, setBlock) => {
  const name = block.nameBlock;
  const adobeCreateTable = {
    ...block.table,
    elementInfo: { ...block.table.elementInfo, name },
    name
  };

  const newTableItem = await tableStore(adobeCreateTable.elementInfo.versionId, mylocale, adobeCreateTable);

  if (!newTableItem) {
    return console.log("error newPhrase", newTableItem);
  }

  const items = parentItemCreate.items.length > 0
    ? parentItemCreate.items.reduce((acu, cur, idx) => {
      if (idx === values?.action?.indexElementCreate) {
        if (block.expandedToolbarBottom) {
          return [...acu,  cur, newTableItem,];
        }
        return [...acu, newTableItem, cur];
      }
      return [...acu, cur];
    }, [])
    : [newTableItem];

  await saveStoreBlock(parentItemCreate, values, setValues, setBlock, newTableItem, items);
};



export const saveBlock = async (values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, zertImage) => {

  // if (!values.changes) {
  //   return;
  // }

  const items = {
    items: [],
    newItems: null
  };
  const createItemData = {
    name: parentItemCreate.name,
    targetLocale: parentItemCreate.targetLocale,
    format: parentItemCreate.format
  };


  if (parentItemCreate && parentItemCreate.type === "create") {

    const createBlockData = {
      ...values.treeItem,
      elementInfo: { ...values.treeItem.elementInfo, name: block.nameBlock },
      name: block.nameBlock,
      format: parentItemCreate.format
    };

    const newBlock = await storeBlockApi(values.treeItem.elementInfo.versionId, values.treeItem.targetLocale.language, createBlockData);

    items.newItems = newBlock;
    items.items = parentItemCreate.items.length > 0
      ? parentItemCreate.items.reduce((acu, cur, idx) => {
        if (idx === values?.action?.indexElementCreate) {
          if (block.expandedToolbarBottom) {
            return [...acu,  cur, newBlock,];
          }
          return [...acu, newBlock, cur];
        }
        return [...acu, cur];
      }, [])
      : [newBlock];
  } else {

    items.newItems = block.blockItem;
    items.items = parentItemCreate.items.reduce((acu, cur, idx) => {
      if (idx === values?.action?.indexElementCreate) {
        return [...acu, { ...block.blockItem, elementInfo: { ...cur.elementInfo, name: block.nameBlock } }];
      }
      return [...acu, cur];
    }, []);

    if(values.treeItem.elementInfo.mimeType.split("/").pop() === "zert-block"){
      //save active block
      const createBlockData = {
        ...values.treeItem,
        elementInfo: { ...values.treeItem.elementInfo, name: block.nameBlock },
        name: block.nameBlock,
        format: parentItemCreate.format
      };
     await storeBlockApi(values.treeItem.elementInfo.versionId, mylocale, createBlockData);
    }
    //
  }


  if (zertImage && block.blockItem?.phraseCanvas?.elements?.length > 0) {
    await saveCanvasItemPhrase(
      block.blockItem.elementInfo.versionId,
      parentItemCreate.targetLocale.language,
      block.blockItem.phraseCanvas
    );
  }

  await storeBlockApi(parentItemCreate.versionId, parentItemCreate.targetLocale.language, {
    ...createItemData,
    items: items.items
  });



  if (setBlock) {
    setBlock(pre => ({
      ...pre,
      expandedToolbar: false,
      expandedToolbarBottom: false,
      createType: null
    }));
  }
  if (values.treeItem.typeAction && values.treeItem.typeAction === "only-save") {
    return setValues(pre => ({
      ...pre,
      action: {
        saveElement: items.newItems,
        parentItemCreate: { ...parentItemCreate, items: items.items, type: "save" }
      }
    }));
  }


  setValues(pre => ({
    ...pre,
    parentItem: parentItemCreate,
    blockClickCreateItemsBlock: false,
      // treeItem: items.newItems,
    investmentLevel: parentItemCreate.investmentLevel,
    action: {
      saveElement: items.newItems,
      parentItemCreate: { ...parentItemCreate, items: items.items, type: "save" }
    }
  }));
};

export const cancelBlock = (values, setValues, item) => {
  const res = {};
  if (values.beforeTreeItem) {
    res.beforeTreeItem = values.beforeTreeItem.slice(0, -1);
  }
  setValues(pre => ({
      ...pre,
      closeChild: item?.identifier,
      blockClickCreateItemsBlock: false,
      ...res
      // action: {...pre.action, cancel: true}
    }
  ));
};


export const removeElement = async (generatingSnackbar, parentItemCreate, values, setValues, snackbar) => {
  const createItemData = {};
  if (parentItemCreate) {
    const items = parentItemCreate.items.filter((el, i) => i !== values?.action?.indexElementCreate);
    createItemData.name = parentItemCreate.name;
    createItemData.targetLocale = parentItemCreate.targetLocale;
    createItemData.format = parentItemCreate.format;
    createItemData.items = items;
  }
  const remove = async () => {
    await storeBlockApi(parentItemCreate.versionId, parentItemCreate.targetLocale.language, createItemData);
    if (setValues) {
      setValues(pre => ({
        ...pre,
        treeItem: null,
        closeChild: false,
        dropBlockTable: false,
        investmentLevel: 0,
        action: {
          ...pre.action,
          parentItemCreate: { ...parentItemCreate, items: createItemData.items, type: "remove" }
        }
      }));
    }
    snackbar.closeSnackbar(generatingSnackbar);
  };

  const cancellation = () => {
    snackbar.closeSnackbar(generatingSnackbar);
  };
  renderInExceptionRoot(AlertTextDialog, { "setConfirm": remove, "setClose": cancellation, "title": "Information" });
};

export const approveElement = async (generatingSnackbar, snackbar, item) => {
  const approve = async () => {
    await approveVersionApi(item.companyId, item.versionId);
    snackbar.closeSnackbar(generatingSnackbar);
  };
  const cancellation = () => {
    snackbar.closeSnackbar(generatingSnackbar);
  };
  renderInExceptionRoot(AlertTextDialog, { "setConfirm": approve, "setClose": cancellation, "title": "Approve ?" });
};

export const copyElementBlock = async (values, selectedRange) => {
  await copyElementBlockApi(JSON.stringify(values.treeItem));
  // localStorage.setItem("copyBlockItems", JSON.stringify({
  //   ...values.treeItem,
  //   parentItem: selectedRange.parentSelectedLvl || values.parentItem,
  //   investmentLevelBlock: values.investmentLevel
  // }));
};

export const pastElementBlock = async (setValues) => {

  const res = await pasteElementBlockApi();
  if (!res.elementInfo) {
    return await renderInExceptionRoot(BufferAlertDialog, {});
  }
  setValues(pre => ({ ...pre, pastItem: res }));
};

export const duplicateElementBlock = async (values, setValues) => {
  const item = values.treeItem?.elementInfo;
  await duplicateElementBlockApi(item.versionId, true);
  setValues(pre => ({ ...pre, pastItem: values.treeItem }));
};


export const addAlias = (val) => {

};

export const saveImg = (e) => {

};