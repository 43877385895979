import getParameterAsInt from "@zert-packages/utils/getParameterAsInt";
import React, { useEffect, useState, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVersionInfo } from "@zert-packages/actions/coreReducers";
import get from "lodash/get";
import { fetchLayout } from "./serviceMiddlewares";
import SettingsTab from "./SettingsTab";
import { turnOnCLMLoader } from "../TranslationJobPlugin/tjReducers";
import LayoutWrapper from "./LayoutWrapper";
import makeStyles from "@mui/styles/makeStyles";
import LeftExpanded from "./components/LeftExpanded";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1.5fr 5fr",
    gridColumnGap: 20,
    marginTop: 10,
    minHeight: "Calc(100vh - 125px)"
  },
  title: {
    marginBottom: 5
  }
}));

const layoutData = {
  indexSettings: -1,
}

export const LayoutContext = createContext(null);

function LayoutView(props) {
  const cl = useStyles();
  const CLM = useSelector((state) => state.CLM);
  const isLoading = useSelector((state) => state.CLM.isLoading);
  const {
    match: { params }
  } = props;
  const versionId = getParameterAsInt(params.versionId);
  const dispatch = useDispatch();
  const routeVersionId = get(props, "match.params.versionId", null);
  const [values, setValues] = useState(null);


  useEffect(() => {
    if(!values && CLM.layout){
      setValues({ ...CLM.layout, ...layoutData })
    }
  }, [CLM.layout])

  useEffect(() => {
    if (routeVersionId === null) return;
    dispatch(getVersionInfo(routeVersionId));
    dispatch(turnOnCLMLoader());
    dispatch(fetchLayout(versionId));
  }, [routeVersionId, versionId]);


  return (
    <LayoutContext.Provider value={{ values, setValues }}>
      <LayoutWrapper>
        <div className={cl.root}>
          <LeftExpanded />
          <div>
            {values && <SettingsTab />}
          </div>
        </div>

      </LayoutWrapper>
    </LayoutContext.Provider>
  );
}

export default LayoutView;
