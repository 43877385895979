import React, { Fragment } from 'react';
import './style.css';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import { FormattedMessage } from 'react-intl';
import { updateParticipant } from '@zert-packages/components/TabTask/API';
import UserSuggestBox from '../../shared/UserSuggestBoxNew/UserSuggestBox';

const getParticipantLabel = (label, defaultValue) => {
  if (label === 'performer') {
    return <FormattedMessage id="SafetyInspectionView.performer" defaultMessage="Performer" />;
  } else if (label === 'temporary-performer') {
    return <FormattedMessage id="SafetyInspectionView.temporary-performer" defaultMessage="Temporary performer" />;
  }
  return <>{defaultValue}</>;
};

export function TaskMember(props) {
  const onChangeUser = (user) => {
    if (user && user.name) {
      props.participant.user = user;
      if (props.onChangeUser) {
        props.onChangeUser(user);
      }
    }
  };

  return (
    <>
      <div className="captionLabel">
        {getParticipantLabel(props.participant.workflowParticipant.id, props.participant.workflowParticipant.name)}
      </div>
      <div className="edit" style={{ background: 'none' }}>
        <UserSuggestBox
          disabled={props.disabled}
          onChange={onChangeUser}
          getUsers={getUsersQuery}
          getUserId={(user) => user.username}
          getUserLabel={(user) => user.name}
          initialValue={props.participant && props.participant.user ? props.participant.user.name : null}
          label={<FormattedMessage id="DelegateTasksSettingsDialog.UserLabel" defaultMessage="User" />}
        />
      </div>
    </>
  );
}

function TaskMembersEditor({ participantMappings, versionInfo, disabled=false, onModification = () => {}  }) {
  const onChangeUser = (participant) => async (user) => {
    if (versionInfo) {
      if (onModification) {
        onModification();
      }
      await updateParticipant({
        versionId: versionInfo.info.versionId,
        userName: user.userName,
        participant: participant.workflowParticipant.id
      });
    }
  };

  const members = participantMappings.map((participant) => {
    const newParticipant = { ...participant };
    newParticipant.workflowParticipant = {
      ...participant.workflowParticipant,
      name: getParticipantLabel(participant.workflowParticipant.id, participant.workflowParticipant.name)
    };
    return (
      <TaskMember
        participant={participant}
        key={participant.workflowParticipant.id}
        onChangeUser={onChangeUser(participant)}
        disabled={disabled}
      />
    );
  });
  return <>{members}</>;
}

export default TaskMembersEditor;
