import React, {useEffect, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import RowItem from "./RowItem";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from "@mui/icons-material/AddBox";
import ClearIcon from '@mui/icons-material/Clear';
import {findBackground, findBorderTd} from "../helpers/main";
import AddCellTable from "./AddCellTable";
import {adobeColor, adobeRgba} from "../../../StylesheetPlugin/utils/helpers";
import PhraseEditor from "../../../PhrasePlugin/components/PhraseEditor";


const useStyles = makeStyles((theme) => ({
    root: {},
    row: {
        border: "1px solid grey",
        width: "180px",
        height: "80px",
        margin: "10px",
        backgroundColor: props => props.selected ? "red" : "",
    },
    tr: {
        // borderBottom: '2px solid #388bff'
    },
    tdAdd: {
        width: 24,
        minWidth: 24,
        paddingLeft: 3,
    },
    tdButton: (props) => ({
        width: 11,
        border: '1px solid #c1c7d0f2',
        backgroundColor: props.hover ? "#F7EFFF" : '',
        cursor: 'pointer',
        height: 40,

    }),
    clear: {
        zIndex: "10",
        right: 3,
        position: 'relative',
        height: 16,
        width: 16,
        backgroundColor: '#59C1DB',
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 3,
        fontSize: 15,
        fontWeight: 900,
        animation: 'fade-in 0.5s',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#FF0000',
        }
    },
    wrapperController: {
        // display: 'grid',
        // gridTemplateRows: '25% 50% 25%'
    }
}))

const rowDefault = {
    selected: false,
    hover: false,
    hoverDelete: false,
    hoverAddTop: false,
    hoverAddBottom: false,
}


const TableRow = ({
                      el, updateCellValue, removeRow, selected, setSelected, addRowLocal, index, isLastItem, hoverTable,
                      setHoverTable, onMouseDown, parentRef, values, tableData, setValues, setTableData, vue
                  }) => {

    const [row, setRow] = useState(rowDefault);
    const cl = useStyles({hover: row.hover})
    const blockSelected = tableData && tableData.length === 1

    const onMouseHandle = (val) => {
        if (row.selected || hoverTable.all || blockSelected) {
            return
        }
        setRow(pre => ({...pre, hover: val}))
    }

    const selectedRow = (e) => {
        if (blockSelected) {
            return
        }
        setRow(pre => ({...pre, selected: true}))
        setSelected(el.val)
        setHoverTable(pre => ({...pre, selectedCell: false, selectedAll: false}))
    }

    const hoverDeleteHandle = (val) => {
        setRow(pre => ({...pre, hoverDelete: val}))
    }

    const hoverAdd = (val, type) => {
        setRow(pre => ({...pre, [type]: val}))
    }

    const findRowBackground = () => {
        const color = el.colorRow
        if (!color) {
            return color
        }
        return adobeColor(color)
    }


    useEffect(() => {
        if (selected.length === 0 && row.selected) {
            setRow(pre => ({...pre, selected: false, hover: false,}))
        }
        if (selected.length > 0 && el.val.length > 0 && selected.find(it => it.posX !== el.val[0].posX) && row.selected) {
            setRow(pre => ({...pre, selected: false, hover: false,}))
        }
    }, [selected, row.selected])

    useEffect(() => {
        if (hoverTable.all || hoverTable.selectedAll) {
            setRow(pre => ({...pre, hover: true}))
        } else {
            if (!row.selected) {
                setRow(pre => ({...pre, hover: false}))
            }
        }
    }, [hoverTable.all, hoverTable.selectedAll])

    useEffect(() => {
        if (hoverTable.selectedCell) {
            setRow(pre => ({...pre, selected: false, hover: false,}))
        }
    }, [hoverTable.selectedCell])


    return (
        <tr id={el.id} className={cl.tr} style={{position: 'relative', zIndex: '999'}}>
            {!vue && <>
                <td className={cl.tdAdd} onClick={() => {
                    setRow(rowDefault)
                }}>
                    {el.val[0] && el.val[0].posX === 0 && <AddCellTable
                        type={'hoverAddTop'}
                        hover={row}
                        setHover={hoverAdd}
                        addRowLocal={addRowLocal}
                        index={index}
                    />}
                    {row.selected && <div
                        className={cl.clear}
                        onMouseEnter={() => hoverDeleteHandle(true)}
                        onMouseLeave={() => hoverDeleteHandle(false)}
                        onClick={() => removeRow(el.id)}
                    >
                        <ClearIcon/>
                    </div>}
                    <AddCellTable
                        type={'hoverAddTop'}
                        hover={row}
                        setHover={hoverAdd}
                        addRowLocal={addRowLocal}
                        index={index}
                    />
                    {isLastItem && <AddCellTable
                        isLastItem={isLastItem}
                        type={'hoverAddBottom'}
                        hover={row}
                        setHover={hoverAdd}
                        addRowLocal={addRowLocal}
                        index={index}
                    />}
                </td>
                <td
                    key={el.id}
                    style={{
                        background: findRowBackground(),
                        ...findBorderTd(row.hover, row.hoverDelete, row.hoverAddTop, row.hoverAddBottom, true),
                    }}
                    onClick={(e) => selectedRow(e)}
                    className={cl.tdButton}
                    name={'blockMouseDown'}
                    onMouseEnter={() => onMouseHandle(true)}
                    onMouseLeave={() => onMouseHandle(false)}
                ></td>
            </>}

            {el.val.map((it, index) => (
                <RowItem
                    onMouseDown={onMouseDown}
                    key={it.id + selected}
                    it={it}
                    el={el}
                    selected={selected}
                    row={row}
                    hoverTable={hoverTable}
                    parentRef={parentRef}
                    values={values}
                    setSelected={setSelected}
                    tableData={tableData}
                    setValues={setValues}
                    setTableData={setTableData}
                />
            ))}

        </tr>
    )
}

export default TableRow