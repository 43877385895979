import React, { memo, useContext } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import TreeItem from '@mui/lab/TreeItem';
import cn from 'classnames';
import { TreeWidgetContext } from './TreeWidgetContext';
import SlowTooltip from '../../SlowTooltip';
import { FormattedMessage } from "react-intl";

const StyledTreeItem = withStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:focus > $content': {
      backgroundColor: `var(--tree-view-bg-color, none)`,
      color: 'var(--tree-view-color)'
    }
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: 'inherit'
    },
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: '14px'
  },
  expanded: {
    paddingBottom: '-4px'
  },
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    width: '90%'
  }
}))(TreeItem);

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  selectedLabelRoot: {
    backgroundColor: theme.palette.grey[300]
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontSize: '1.2rem',
    flexGrow: 1,
    overflow: 'hidden',
    fontWeight: 400
  },
  labelTextBold: {
    fontWeight: 600
  },
  tooltip: {
    fontSize: '1rem'
  },
  highlighted: {
    border: '2px dotted rgba(0, 0, 0, 0.1)'
  }
}));

const TreeWidgetNode = memo((props) => {
  const { node, labelIcon, nodePath, createOnSelect, selectedNodeId } = props;
  const { highlightedId } = useContext(TreeWidgetContext);

  return (
    <div key={node.id}>
      <StyledTreeItem
        style={{ transition: 'background-color 0.3s ease-in-out' }}
        label={
          <Label
            highlighted={highlightedId === node.id}
            selected={selectedNodeId === node.id}
            label={node.label}
            onClick={createOnSelect(node.id, node.label)}
            icon={labelIcon}
            strongLabel={node.strongLabel}
          />
        }
        nodeId={`${node.id}`}
        children={
          node.children.length > 0
            ? node.children.map((child, index) => (
                <TreeWidgetNode
                  selectedNodeId={selectedNodeId}
                  createOnSelect={createOnSelect}
                  node={child}
                  nodePath={`${nodePath}!${child.id}!`}
                  key={child.id}
                  index={index}
                  children={child.children}
                />
              ))
            : undefined
        }
      />
    </div>
  );
});

export default TreeWidgetNode;

const propsAreEqual = (prevProps, nextProps) =>
  prevProps.selected === nextProps.selected &&
  prevProps.label === nextProps.label &&
  prevProps.highlighted === nextProps.highlighted;

const Label = memo(({ highlighted, selected, label, onClick, icon: Icon, strongLabel }) => {
  const cl = useStyles();
  return (
    <div>
      <div
        onClick={onClick}
        className={cn(cl.labelRoot, {
          [cl.selectedLabelRoot]: selected,
          [cl.highlighted]: highlighted
        })}
      >
        {Icon && <Icon color="inherit" className={cl.labelIcon} />}
        <SlowTooltip title={label}>
          <Typography
            variant="body2"
            className={cn(cl.labelText, { [cl.labelTextBold]: strongLabel })}
            /* noWrap */
          >
            <span>
              {label ||
                <i>
                  <FormattedMessage
                    id="demandproject.nameless"
                    defaultMessage="Nameless"
                  />
                </i>
              }
            </span>
          </Typography>
        </SlowTooltip>
      </div>
    </div>
  );
}, propsAreEqual);
