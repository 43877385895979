import React, { lazy } from 'react';
import {
  loadRescentElements as loadRecentRiskAssessments,
  searchElements as searchRiskAssessments
} from '@zert-packages/actions/riskassesmentActions';
import {
  loadRescentElements as loadRecentChecklists,
  searchElements as searchChecklists,
  loadRescentSafetyInspections as loadRecentSafetyInspections,
  searchSafetyElements as searchSafetyInspections
} from '@zert-packages/actions/checklistActions';
import { FormattedMessage } from 'react-intl';
import {
  TableView,
  CalculateOutlined,
  FormatListBulletedSharp,
  Settings,
  FactCheck,
  AssignmentRounded,
  AccountTreeTwoTone,
  Calculate,
  ReportProblem,
  CalendarToday,
  StackedLineChart, ManageAccounts
} from "@mui/icons-material";
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { steps } from '@zert-packages/translations';
import { ROUTE_TYPES } from '@zert-packages/react/ROUTE_TYPES';
import { getPlugins } from '@zert-packages/actions/api';
import ElementOverview from '@zert-packages/components/ElementOverview/ElementOverview';
import ProjectTile, { expandProject as expandPROJECT, ProjectContentOverviewTile } from './ProjectPlugin/ProjectTile';
import {
  loadRescentElements as loadRecentProjects,
  searchElements as searchProjects
} from './ProjectPlugin/projectActions';
import {
  loadRescentElements as loadRecentDemandProjects,
  searchElements as searchDemandProjects
} from './RMPPlugin/redux/servicesMiddlewares';
import CatalogView from './CatalogPlugin';
import ChecklistNewPage from './ChecklistPlugin/ChecklistNewPage';
import NewProjectPage from './ProjectPlugin/NewProjectPage';
import NewSafetyInspectionPage from './SafetyInspectionPlugin/NewSafetyInspectionPage';
import NewDeviationPage from './DeviationPlugin/NewDeviationPage';
import SafetyInspectionStart from './SafetyInspectionPlugin/SafetyInspectionView';
import NewRiskAssessmentPage from './RiskAssesmentPlugin/NewRiskAssessmentPage';
import NewRMPPage from './RMPPlugin/NewRMPPage';
import CreateRiskAssesmentDialog from './RiskAssesmentPlugin/CreateRiskAssesmentDialog';
import CreateChecklistDialog from './ChecklistPlugin/CreateChecklistDialog';
import CreateNewSafetyInspectionDialog from './SafetyInspectionPlugin/CreateNewSafetyInspectionDialog';
import CreateNewSubmitDialog from './DeviationPlugin/CreateNewSubmitDialog';
import CatalogExplorerRoutes from './CatalogPlugin/CatalogExplorerRoutes';
import CreateNewPEDDialog from './PEDOBjectPlugin/CreateNewPEDDialog';
import PEDView from './PEDOBjectPlugin/PEDView';
import useProjectReports from './ProjectPlugin/useProjectReports';

const API_ROOT_WAR = process.env.REACT_APP_API_ROOT_WAR;

export const matrixPlugin = {
  icon: TableView,
  mimeType: 'matrix',
  trkey: 'environment.title.zertMatrix',
  defaultMessage: 'Matrix',
  name: <FormattedMessage id="environment.title.zertMatrix" defaultMessage="Matrix" />,
  isTask: false,
  newpermit: 'zert-matrix',
  readpermit: 'zert-matrix',
  editpermit: 'zert-matrix',
  openpath: `${API_ROOT_WAR}/settings/matrix-edit/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/settings/matrix-edit/:versionId',
      component: lazy(() => import('./AdministrationPlugin/components/MatrixManagement'))
    }
  ]
};

export const deviationTemplatePlugin = {
  icon: Settings,
  mimeType: 'deviation-template',
  trkey: 'environment.title.deviationTemplate',
  defaultMessage: 'Deviation template',
  name: <FormattedMessage id="environment.title.deviationTemplate" defaultMessage="Deviation template" />,
  isTask: false,
  newpermit: 'zert-deviation-template',
  readpermit: 'zert-deviation-template',
  editpermit: 'zert-deviation-template',
  openpath: `${API_ROOT_WAR}/settings/deviation-template/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/settings/deviation-template/:versionId',
      component: lazy(() => import('./AdministrationPlugin/components/DeviationTemplate'))
    }
  ]
};

export const pedPlugin = {
  icon: Settings,
  mimeType: 'ped-object',
  isCreateNew: true,
  trkey: 'environment.title.ped',
  defaultMessage: 'PED',
  createNewDialog: CreateNewPEDDialog,
  summaryLabel: <FormattedMessage id="ped.summary.caption" defaultMessage="AFS 2017:3" />,
  name: <FormattedMessage id="environment.title.ped" defaultMessage="PED" />,
  newpath: '/ped_new',
  newpermit: 'zert-ped-object/create-ped-object',
  readpermit: 'zert-ped-object/edit-ped-object',
  editpermit: 'zert-ped-object/read-object',
  isTask: false,
  openpath: `${API_ROOT_WAR}/ped/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/ped/:versionId',
      component: PEDView
    },

    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/ped_new',
      title: <FormattedMessage id="headbar.title.createnewPED" defaultMessage="Create newPED" />,
      component: CreateNewPEDDialog
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/explorer/ped_new',
      title: <FormattedMessage id="headbar.title.pedobjecttitle" defaultMessage="PED OBJECT" />
      // component: (props) => <NewDeviationPage {...props} storeInExplorer/>,
    }
  ]
};

export const measureTemplatePlugin = {
  icon: Settings,
  mimeType: 'measure-template',
  trkey: 'environment.title.measureTemplate',
  defaultMessage: 'Measure template',
  name: <FormattedMessage id="environment.title.measureTemplate" defaultMessage="Measure template" />,
  isTask: false,
  newpermit: 'zert-measure-template',
  readpermit: 'zert-measure-template',
  editpermit: 'zert-measure-template',
  openpath: `${API_ROOT_WAR}/settings/measure-template/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/settings/measure-template/:versionId',
      component: lazy(() => import('./AdministrationPlugin/components/MeasureTemplate'))
    }
  ]
};

export const riskRowTemplatePlugin = {
  icon: Settings,
  openpath: `${API_ROOT_WAR}/settings/risk-row-template-view/:versionId`,
  trkey: 'environment.title.riskRowTemplate',
  defaultMessage: 'Risk row template',
  mimeType: 'risk-row-template',
  newpermit: 'zert-risk-row-template',
  readpermit: 'zert-risk-row-template',
  editpermit: 'zert-risk-row-template',
  name: <FormattedMessage id="environment.title.risk-row-template" defaultMessage="Risk row template" />,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      title: <FormattedMessage id="headbar.title.riskRowTemplates" defaultMessage="Risk row templates" />,
      path: '/settings/risk-row-templates-overview',
      component: lazy(() => import('./AdministrationPlugin/SettingsView'))
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      title: <FormattedMessage id="headbar.title.riskRowTemplate" defaultMessage="Risk row template" />,
      path: '/settings/risk-row-template-view/:versionId',
      component: lazy(() => import('./AdministrationPlugin/components/RiskRowTemplates/RiskRowTemplateView'))
    }
  ]
};

export const catalogPlugin = {
  icon: AccountTreeTwoTone,
  mimeType: 'catalog',
  trkey: 'environment.title.catalog',
  defaultMessage: 'Catalog',
  openpath: `${API_ROOT_WAR}/catalog/:versionId`,
  newpermit: 'zert-catalog/create-catalog',
  readpermit: 'zert-catalog/read-catalog',
  editpermit: 'zert-catalog/edit-catalog',
  // isSecured: true,
  isCreateNew: true,
  name: <FormattedMessage id="environment.title.catalog" defaultMessage="Catalog" />,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      title: <FormattedMessage id="headbar.title.catalogSettins" defaultMessage="Catalog settings" />,
      path: '/settings/catalogSettings',
      component: lazy(() => import('./AdministrationPlugin/SettingsView'))
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      title: <FormattedMessage id="headbar.title.catalog" defaultMessage="Catalog" />,
      path: '/catalog/:versionId',
      component: CatalogView
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      title: <FormattedMessage id="headbar.title.catalog" defaultMessage="Catalog" />,
      path: 'catalog/:companyId/:versionId',
      component: CatalogView
    }
  ]
};

export const checkListPlugin = {
  icon: FormatListBulletedSharp,
  mimeType: 'checklist',
  trkey: 'environment.title.checklist',
  defaultMessage: 'Checklist',
  summaryLabel: <FormattedMessage id="checklists.summary.caption" defaultMessage="CHEKLISTS" />,
  order: 2,
  createNewDialog: CreateChecklistDialog,
  isCreateNew: true,
  name: <FormattedMessage id="environment.title.checklist" defaultMessage="Checklist" />,
  namePlural: <FormattedMessage id="environment.title.checklists" defaultMessage="Checklists" />,
  openpath: `${API_ROOT_WAR}/checklist/:versionId/0/0`,
  newpath: '/checklist_new',
  showExpand: true,
  newpermit: 'zert-questionnaire/create-checklist',
  readpermit: 'zert-questionnaire/read-checklist',
  editpermit: 'zert-questionnaire/edit-checklist',
  showBubles: true,
  approvepath: `${API_ROOT_WAR}/checklist_summary/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/checklist_new',
      isCreateNew: true,
      component: ChecklistNewPage,
      title: <FormattedMessage id="headbar.title.createnewchecklist" defaultMessage="Create checklist" />
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/checklist_thanks/:versionid',
      component: lazy(() => import('./ChecklistPlugin/ChecklistView'))
    },
    {
      type: ROUTE_TYPES.WITH_BACK_AND_LINK_NEW,
      path: '/checklistoverview',
      component: (props) => <ElementOverview {...props} pluginMimeType="checklist" plugins={getRMPlugins()} />,
      recentAction: loadRecentChecklists,
      searchAction: searchChecklists,
      isOverview: true,
      title: <FormattedMessage id="headbar.title.checklist" defaultMessage="Checklists list" />,
      onNewLabel: <FormattedMessage id="headbar.checklist.create" defaultMessage="New" />,
      onNewLink: '/checklist_new'
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/checklist_summary/:versionid',
      component: lazy(() => import('./ChecklistPlugin/ChecklistView')),
      methodInfoTitle: <FormattedMessage id="headbar.title.checklist.method" defaultMessage="Checklist:" />
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/basis/checklist_summary/:versionid',
      component: lazy(() => import('./ChecklistPlugin/ChecklistView')),
      methodInfoTitle: <FormattedMessage id="headbar.title.checklist.method" defaultMessage="Checklist:" />
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/basis/checklist/:versionid/:categoryid/:questionid',
      component: lazy(() => import('./ChecklistPlugin/ChecklistView'))
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/checklist/:versionid/:categoryid/:questionid',
      component: lazy(() => import('./ChecklistPlugin/ChecklistView')),
      methodInfoTitle: <FormattedMessage id="headbar.title.checklist.method" defaultMessage="Checklist:" />
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/explorer/checklist_new',
      isCreateNewExplorer: true,
      component: (props) => <ChecklistNewPage {...props} storeInExplorer />,
      title: <FormattedMessage id="headbar.title.createnewchecklist" defaultMessage="Create checklist" />
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/basis/checklist_new/:versionId/:activityid',
      component: ChecklistNewPage,
      title: <FormattedMessage id="headbar.title.createnewchecklist" defaultMessage="Create checklist" />
    }
  ]
};

export const safetyInspectionPlugin = {
  icon: FactCheck,
  mimeType: 'safety-inspection',
  trkey: 'environment.title.safetyinspection',
  defaultMessage: 'Safety inspection',
  order: 3,
  createNewDialog: CreateNewSafetyInspectionDialog,
  isCreateNew: true,
  name: <FormattedMessage id="environment.title.safetyinspection" defaultMessage="Safety inspection" />,
  namePlural: <FormattedMessage id="environment.title.safetyinspections" defaultMessage="Safety inspections" />,
  summaryLabel: <FormattedMessage id="safetyinspections.summary.caption" defaultMessage="SAFETY INSPECTIONS" />,
  isTask: true,
  showExpand: true,
  newpath: '/periodic-checklist_new',
  newpermit: 'zert-questionnaire/create-safety-inspection',
  readpermit: 'zert-questionnaire/read-safety-inspection',
  editpermit: 'zert-questionnaire/edit-safety-inspection',
  openpath: `${API_ROOT_WAR}/safetyinspection/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.WITH_BACK_AND_LINK_NEW,
      path: '/periodic-checklist_overview',
      component: (props) => <ElementOverview {...props} pluginMimeType="safety-inspection" plugins={getRMPlugins()} />,
      recentAction: loadRecentSafetyInspections,
      searchAction: searchSafetyInspections,
      isOverview: true,
      title: <FormattedMessage id="headbar.title.safetyinspection" defaultMessage="Safety inspections" />,
      onNewLabel: <FormattedMessage id="headbar.safetyinspection.create" defaultMessage="New" />,
      onNewLink: '/periodic-checklist_new'
    },
    {
      type: ROUTE_TYPES.SIMPLE,

      path: '/basis/periodic-checklist_new/:versionId/:activityid',
      title: (
        <FormattedMessage id="headbar.title.createnewsafetyinspection" defaultMessage="Create new safety inspeaction" />
      ),
      component: NewSafetyInspectionPage
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/explorer/periodic-checklist_new',
      isCreateNewExplorer: true,
      title: (
        <FormattedMessage id="headbar.title.createnewsafetyinspection" defaultMessage="Create new safety inspeaction" />
      ),
      component: (props) => <NewSafetyInspectionPage {...props} storeInExplorer />
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/periodic-checklist_new',
      isCreateNew: true,
      title: (
        <FormattedMessage id="headbar.title.createnewsafetyinspection" defaultMessage="Create new safety inspeaction" />
      ),
      component: NewSafetyInspectionPage
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/safetyinspection/:versionid',
      component: SafetyInspectionStart
    }
  ]
};

export const deviationPlugin = {
  icon: ReportProblem,
  mimeType: 'deviation',
  isCreateNew: true,
  createNewDialog: CreateNewSubmitDialog,
  trkey: 'environment.title.deviation',
  defaultMessage: 'Deviation',
  summaryLabel: <FormattedMessage id="deviations.summary.caption" defaultMessage="DEVIATIONS" />,
  name: <FormattedMessage id="environment.title.deviation" defaultMessage="Deviation" />,
  newpath: '/deviation_new',
  newpermit: 'zert-deviation/create-deviation',
  readpermit: 'zert-deviation/edit-deviation',
  editpermit: 'zert-deviation/read-deviation',
  isTask: true,
  openpath: `${API_ROOT_WAR}/deviation/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/deviation/:versionId',
      component: lazy(() => import('./DeviationPlugin/DeviationView'))
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/basis/deviation_new/:versionId/:activityid',
      title: <FormattedMessage id="headbar.title.createnewdeviation" defaultMessage="Report deviation" />,
      component: NewDeviationPage
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      isCreateNew: true,
      path: '/deviation_new',
      title: <FormattedMessage id="headbar.title.createnewdeviation" defaultMessage="Report deviation" />,
      component: NewDeviationPage
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      isCreateNewExplorer: true,
      path: '/explorer/deviation_new',
      title: <FormattedMessage id="headbar.title.createnewdeviation" defaultMessage="Report deviation" />,
      component: (props) => <NewDeviationPage {...props} storeInExplorer />
    }
  ]
};

export const projectPlugin = {
  icon: CalculateOutlined,
  mimeType: 'project',
  isCreateNew: true,
  order: 4,
  name: <FormattedMessage id="environment.title.project" defaultMessage="Project" />,
  namePlural: <FormattedMessage id="environment.title.projects" defaultMessage="Projects" />,
  newpath: '/project_new',
  trkey: 'environment.title.project',
  summaryLabel: <FormattedMessage id="projects.summary.caption" defaultMessage="PROJECTS" />,
  defaultMessage: 'Project',
  isTask: true,
  showExpand: true,
  showBubles: true,
  newpermit: 'zert-project/create-project',
  readpermit: 'zert-project/read-project',
  editpermit: 'zert-project/edit-project',
  openpath: `${API_ROOT_WAR}/project/:versionId`,
  reportHook: useProjectReports,
  overviewTile: ProjectContentOverviewTile,
  tile: ProjectTile,
  expandFunction: expandPROJECT,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      isCreateNew: true,
      title: <FormattedMessage id="headbar.title.createnewproject" defaultMessage="Create project" />,
      path: '/project_new',
      component: lazy(() => import('./ProjectPlugin/NewProjectPage'))
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      title: <FormattedMessage id="headbar.title.projecttemplates" defaultMessage="Project templates" />,
      path: '/settings/projectTemplates',
      component: lazy(() => import('./AdministrationPlugin/SettingsView'))
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      title: <FormattedMessage id="headbar.title.jaspersettings" defaultMessage="Jasper  templates" />,
      path: '/settings/jasperreportstemplates',
      component: lazy(() => import('./AdministrationPlugin/SettingsView'))
    },

    {
      type: ROUTE_TYPES.SIMPLE,
      title: <FormattedMessage id="headbar.title.createnewproject" defaultMessage="Create project" />,
      path: '/basis/project_new/:versionId/:activityid',
      component: lazy(() => import('./ProjectPlugin/ProjectView'))
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/explorer/project_new',
      isCreateNewExplorer: true,
      title: <FormattedMessage id="headbar.title.createnewproject" defaultMessage="Create project" />,
      component: (props) => <NewProjectPage {...props} storeInExplorer />
    },
    {
      type: ROUTE_TYPES.WITH_BACK_AND_LINK_NEW,
      path: '/project',
      isOverview: true,
      title: <FormattedMessage id="headbar.title.project" defaultMessage="Project list" />,
      component: (props) => <ElementOverview {...props} pluginMimeType="project" plugins={getRMPlugins()} />,
      recentAction: loadRecentProjects,
      searchAction: searchProjects,
      onNewLabel: <FormattedMessage id="headbar.project.create" defaultMessage="New" />,
      onNewLink: '/project_new'
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/project/:versionId',
      component: lazy(() => import('./ProjectPlugin/ProjectView'))
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/project/:versionId/:activityId',
      component: lazy(() => import('./ProjectPlugin/ProjectView'))
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/basis/project/:versionId',
      component: lazy(() => import('./ProjectPlugin/ProjectView'))
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/basis/project/:versionId/:activityId',
      component: lazy(() => import('./ProjectPlugin/ProjectView'))
    }
  ]
};

export const questionaireTemplatePlugin = {
  icon: FormatListBulletedSharp,
  isCreateNew: true,
  mimeType: 'questionnaire-template',
  trkey: 'environment.title.questionairetemplate',
  defaultMessage: 'Questionaire template',
  name: <FormattedMessage id="environment.title.questionnairetemplate" defaultMessage="Questionnaire template" />,
  isTask: false,
  // isSecured: true,

  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/questionnaireTemplate/:versionId',
      component: lazy(() => import('./QuestionaireTemplatePlugin/QuestionnaireTemplateView')),
      title: <FormattedMessage id="headbar.title.questionaireTemplate" defaultMessage="Questionnaire template" />
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      title: <FormattedMessage id="headbar.title.questionaireTemplate" defaultMessage="Questionnaire template" />,
      path: '/questionnaireTemplate/:companyId/:versionId',
      component: lazy(() => import('./QuestionaireTemplatePlugin/QuestionnaireTemplateView'))
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      title: <FormattedMessage id="headbar.title.questionnaireTemplates" defaultMessage="Questionnaire templates" />,
      path: '/settings/questionTemplatesSettings',
      component: lazy(() => import('./AdministrationPlugin/SettingsView'))
    }
  ],

  openpath: `${API_ROOT_WAR}/questionnaireTemplate/:versionId`,
  editpermit: 'zert-questionnaire/edit-questionnaire-template',
  newpermit: 'zert-questionnaire/create-questionnaire-template',
  readpermit: 'zert-questionnaire/read-questionnaire-template'
};

export const rmpPlugin = {
  icon: Settings,
  mimeType: 'projectplanning',
  trkey: 'environment.title.rmp',
  defaultMessage: 'Demands project',
  name: <FormattedMessage id="environment.title.rmp" defaultMessage="Demand project" />,
  namePlural: <FormattedMessage id="environment.title.rmps" defaultMessage="Demand projects" />,
  isCreateNew: true,
  order: 5,
  openpath: `${API_ROOT_WAR}/rmp/:versionId`,
  editpermit: 'zert-projectplanning/edit-projectplanning',
  newpermit: 'zert-projectplanning/create-projectplanning',
  readpermit: 'zert-projectplanning/read-projectplanning',
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/rmp/:versionId',
      component: lazy(() => import('./RMPPlugin/RMPView')),
      title: <FormattedMessage id="headbar.title.DemandsProject" defaultMessage="Demand project" />
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/rmp-template-overview',
      title: <FormattedMessage id="headbar.title.Demand.Template.Settings" defaultMessage="Demands template" />,
      component: lazy(() => import('./AdministrationPlugin/SettingsView'))
    },
    {
      type: ROUTE_TYPES.WITH_BACK_AND_LINK_NEW,
      path: '/rmp-overview',
      component: (props) => <ElementOverview {...props} pluginMimeType="projectplanning" plugins={getRMPlugins()} />,
      recentAction: loadRecentDemandProjects,
      searchAction: searchDemandProjects,
      title: <FormattedMessage id="headbar.title.demandProject" defaultMessage="Demand project" />,
      isOverview: true,
      onNewLabel: <FormattedMessage id="headbar.deamdnProject.create" defaultMessage="New" />,
      onNewLink: '/rmp_new'
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      isCreateNew: true,
      title: <FormattedMessage id="headbar.title.newRMP" defaultMessage="New RMP" />,
      path: '/rmp_new',
      component: NewRMPPage
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      // isCreateNew: true,
      title: <FormattedMessage id="headbar.title.rmp" defaultMessage="New demand project" />,
      isCreateNewExplorer: true,
      path: '/explorer/rmp_new',
      component: (props) => <NewRMPPage {...props} storeInExplorer />
    }
  ]
};

export const maintainPlugin = {
  icon: Settings,
  trkey: 'environment.title.maintain',
  // mimeType: 'Maintain',
  defaultMessage: 'Maintain',
  name: <FormattedMessage id="environment.title.maintain" defaultMessage="Maintain" />,
  routes: [
    {
      type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
      path: '/settings/deviationTemplatesSettings',
      component: lazy(() => import('./AdministrationPlugin/SettingsView')),
      title: <FormattedMessage id="headbar.title.DeviationSettings" defaultMessage="Deviation settings View" />
    },
    /* {
      type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
      path: '/settings/catalogSettings',
      component: lazy(() => import('./AdministrationPlugin/SettingsView')),
      title: <FormattedMessage
          id="headbar.title.CatalogSettings" defaultMessage="Catalog Settings"
      />,
    }, */

    {
      type: ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR,
      path: '/settings/news',
      component: lazy(() => import('./AdministrationPlugin/SettingsView')),
      title: <FormattedMessage id="headbar.title.News" defaultMessage="News view" />
    },

    {
      type: ROUTE_TYPES.CATALOG,

      listOfRoutes: CatalogExplorerRoutes
    }
  ]
};

export const measurePlugin = {
  icon: CalendarToday,
  mimeType: 'measure',
  trkey: 'environment.title.measure',
  defaultMessage: 'Measure',
  name: <FormattedMessage id="environment.title.measure" defaultMessage="Measure" />,
  isTask: true,
  openpath: `${API_ROOT_WAR}/measure/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/measure/:versionId',
      component: lazy(() => import('./RiskAssesmentPlugin/MeasureView'))
    }
  ]
};

export const projectDocument = {
  icon: Calculate,
  mimeType: 'project-document',
  trkey: 'environment.title.project-document',
  defaultMessage: 'Project document',
  name: <FormattedMessage id="environment.title.project-document" defaultMessage="Project document" />,
  isTask: true,
  openpath: `${API_ROOT_WAR}/project-document/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      title: <FormattedMessage id="HeadBar.title.DocumentProject" defaultMessage="Document project" />,
      path: '/project-document/:versionId',
      component: lazy(() => import('./RMPPlugin/components/DocumentProjectView'))
    }
  ]
};

export const riskRowPlugin = {
  icon: StackedLineChart,
  mimeType: 'risk-row',
  summaryLabel: <FormattedMessage id="riskrows.summary.caption" defaultMessage="RISK ROWS" />,
  trkey: 'environment.title.riskrow',
  defaultMessage: 'Risk Row',
  openpath: `${API_ROOT_WAR}/riskrow/:versionId`,
  newpermit: 'zert-risk-row/create-risk-row',
  readpermit: 'zert-risk-row/read-risk-row',
  editpermit: 'zert-risk-row/edit-risk-row',
  name: <FormattedMessage id="environment.title.riskrow" defaultMessage="Risk Row" />,
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/riskrow/:versionId',
      component: lazy(() => import('./RiskAssesmentPlugin/MeasureView')),
      methodInfoTitle: <FormattedMessage id="headbar.title.riskassesment.method" defaultMessage="Method:" />
    }
  ]
};

export const riskAnal = {
  icon: AssignmentRounded,
  order: 1,
  trkey: 'environment.title.riskanalysis',
  createNewDialog: CreateRiskAssesmentDialog,
  defaultMessage: 'Risk Assesment',
  mimeType: 'risk-analysis',
  showExpand: true,
  isCreateNew: true,
  helpId: 'riskassesment',
  newpath: '/risk-assesment_new',
  newpermit: 'zert-riskanalysis/create-risk-analysis',
  readpermit: 'zert-riskanalysis/read-risk-analysis',
  editpermit: 'zert-riskanalysis/edit-risk-analysis',
  name: <FormattedMessage id="environment.title.riskanalysis" defaultMessage="Risk Assessment" />,
  namePlural: <FormattedMessage id="environment.title.riskAssessments" defaultMessage="Risk Assessments" />,
  summaryLabel: <FormattedMessage id="riskassessments.summary.caption" defaultMessage="Risk Assessments" />,
  openpath: `${API_ROOT_WAR}/risk-assesment/:versionId`,
  routes: [
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/risk-assesment/:riskId',
      component: lazy(() => import('./RiskAssesmentPlugin/NewRiskAssessmentView')),
      methodInfoTitle: <FormattedMessage id="headbar.title.riskassesment.method" defaultMessage="Method:" />
    },
    {
      type: ROUTE_TYPES.ELEMENT,
      path: '/basis/risk-assesment/:riskId',
      component: lazy(() => import('./RiskAssesmentPlugin/NewRiskAssessmentView'))
    },
    {
      type: ROUTE_TYPES.WITH_BACK_AND_LINK_NEW,
      path: '/risk-assesment-overview',
      isOverview: true,
      component: (props) => <ElementOverview {...props} pluginMimeType="risk-analysis" plugins={getRMPlugins()} />,
      recentAction: loadRecentRiskAssessments,
      searchAction: searchRiskAssessments,
      title: <FormattedMessage id="headbar.title.riskassesment" defaultMessage="Risk assesments list" />,
      onNewLabel: <FormattedMessage id="headbar.riskassessment.create" defaultMessage="New" />,
      onNewLink: '/risk-assesment_new'
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      isCreateNew: true,
      title: <FormattedMessage id="headbar.title.newriskassesment" defaultMessage="New risk assesment" />,
      path: '/risk-assesment_new',
      component: NewRiskAssessmentPage
    },
    {
      type: ROUTE_TYPES.SIMPLE,

      title: <FormattedMessage id="headbar.title.newriskassesment" defaultMessage="New risk assesment" />,
      isCreateNewExplorer: true,
      path: '/explorer/risk-assesment_new',
      component: (props) => <NewRiskAssessmentPage {...props} storeInExplorer />
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      title: <FormattedMessage id="headbar.title.newriskassesment" defaultMessage="New risk assesment" />,
      path: '/basis/risk-assesment_new/:versionId/:activityid',
      component: NewRiskAssessmentPage
    }
  ]
};

export const administrationPlugin = {
  icon: Settings,
  trkey: 'environment.title.administration',
  //  mimeType: 'administration',
  defaultMessage: 'Administration ',
  name: <FormattedMessage id="environment.title.administration" defaultMessage="Administration" />,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/companylist',
      component: lazy(() => import('./AdministrationPlugin/SettingsView')),
      title: <FormattedMessage id="headbar.title.companylist" defaultMessage="Company list" />
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/statistics',
      component: lazy(() => import('./AdministrationPlugin/SettingsView')),
      title: <FormattedMessage id="headbar.title.settings.statistics" defaultMessage="Statistics" />
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/personal',
      component: lazy(() => import('./AdministrationPlugin/SettingsView')),
      title: <FormattedMessage id="headbar.title.settings.personal" defaultMessage="Personal" />
    },
    {
      type: ROUTE_TYPES.USERS_IN_COMPANY,
      path: '/settings/userlist/:companyid',
      component: lazy(() => import('./AdministrationPlugin/SettingsView'))
    },
    {
      type: ROUTE_TYPES.GROUPS_IN_COMPANY,
      path: '/settings/grouplist/:companyid',
      component: lazy(() => import('./AdministrationPlugin/SettingsView'))
    },
    {
      type: ROUTE_TYPES.USER_EDIT_COMPANY,
      path: '/settings/usereditor/:companyid/:userid',
      component: lazy(() => import('./AdministrationPlugin/SettingsView'))
    },
    {
      type: ROUTE_TYPES.COMPANY,
      path: '/settings/companyeditor/:companyid',
      component: lazy(() => import('./AdministrationPlugin/SettingsView'))
    },
  {
      type: ROUTE_TYPES.PROVISIONING,
      path: '/settings/provisioningTokens/:companyid',
      component: lazy(() => import('./AdministrationPlugin/SettingsView'))
  },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/maillist',
      component: lazy(() => import('./AdministrationPlugin/SettingsView')),
      title: <FormattedMessage id="headbar.title.maillist" defaultMessage="Mail list" />
    },
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/task-management',
      component: lazy(() => import('./AdministrationPlugin/SettingsView')),
      title: <FormattedMessage id="headbar.title.taskManagement" defaultMessage="Task Management" />
    }
  ]
};
export const shortcutsPlugin = {
  icon: Settings,
  trkey: 'headbar.title.shortcuts',
  defaultMessage: 'Shortcuts',
  name: <FormattedMessage id="headbar.title.shortcuts" defaultMessage="Shortcuts" />,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/shortcuts',
      component: lazy(() => import('./AdministrationPlugin/SettingsView')),
      title: <FormattedMessage id="headbar.title.shortcuts" defaultMessage="Shortcuts" />
    }
  ]
};

export const provisioningPlugin = {
  icon: ManageAccounts,
  trkey: 'headbar.title.provisioning',
  defaultMessage: 'Provisioning authentication tokens',
  name: <FormattedMessage
    id="headbar.title.provisioning"
    defaultMessage="Provisioning authentication tokens"
  />,
  routes: [
    {
      type: ROUTE_TYPES.SIMPLE,
      path: '/settings/provisioningTokens',
      component: lazy(() => import('./AdministrationPlugin/SettingsView')),
      title: <FormattedMessage
        id="headbar.title.provisioning"
        defaultMessage="Provisioning authentication tokens"
      />
    }
  ]
};

export const plugins = [
  rmpPlugin,
  pedPlugin,
  riskRowTemplatePlugin,
  catalogPlugin,
  riskAnal,
  checkListPlugin,
  safetyInspectionPlugin,
  deviationPlugin,
  measureTemplatePlugin,
  matrixPlugin,
  questionaireTemplatePlugin,
  measurePlugin,
  riskRowPlugin,
  deviationTemplatePlugin,
  projectDocument,
  projectPlugin,
  administrationPlugin,
  maintainPlugin,
  shortcutsPlugin,
  provisioningPlugin
];

export default function getRMPlugins() {
  return plugins;
}

export function getAllTaskPluginsAndWildCard() {
  const taskplugins = plugins.filter((plugin) => plugin.isTask);
  const taskDefinitions = taskplugins.map((plugin, index) => {
    return {
      id: index + 1,
      title: {
        text: getPluginByMimeType(plugin.mimeType).name
      },
      mimeType: plugin.mimeType
    };
  });
  taskDefinitions.push({
    id: 0,
    title: {
      text: <FormattedMessage id="environment.title.allmimetypes" defaultMessage="All" />
    },
    mimeType: '*'
  });
  return taskDefinitions;
}
export function getCreateNewPlugins() {
  return getPlugins().filter((plugin) => plugin.isCreateNew);
}

export function getPluginTranslatableName(intl, plugin) {
  const resultstep = getPlugins().find((step) => {
    return plugin.trkey === step.trkey;
  });

  if (resultstep && resultstep.trkey) {
    return intl.formatMessage({
      id: resultstep.trkey,
      defaultMessage: resultstep.defaultMessage
    });
  }

  return '';
}

export function getSteps(intl) {
  const resultsteps = steps.map((step) => {
    const step1 = {
      id: step.id,
      message: step.message,
      tr_message: intl.formatMessage({
        id: step.trkey,
        defaultMessage: step.defaultMessage
      }),
      description: step.description
    };
    return step1;
  });

  return resultsteps;
}
