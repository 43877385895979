import React, { createContext, useCallback, useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LeftExpanded from "../LeftExpanded";
import RightExpanded from "../RightExpanded";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import AbstractWrapper from "../components/AbstractWrapper";
import BorderTypeColor from "../helpers/BorderTypeColor";
import TextFieldItem from "../../TextFieldItem";
import handleValueState from "@zert-packages/utils/handleValueState";
import useVersionManager from "@zert-packages/utils/useVersionManager";
import { dataContext } from "../data/dataContext";
import { setHeaderScroll } from "@zert-packages/actions/commonReducer";
import { saveBlock } from "../helpers/main";
import { useSnackbar } from "notistack";
import infoLabel from "../../shared/InfoLabel/InfoLabel";
import { useParams } from "react-router-dom";


export const PanelContext = createContext(null);

let prevVal = "";
let render = true;

function InfoElementWrapper({ children, nameShow=true }) {
  const cl = useStyles();
  const { versionId } = useParams();
  const [expander, setExpander] = useState({
    left: false,
    right: false
  });
  const [values, setValues] = useState(dataContext);
  const [versionManager, versionInfo] = useVersionManager();
  const selectedParent = values.treeItem && values.treeItem.parentTreeItem;
  const type = versionInfo?.info?.mimeType.split("/").pop();
  const [scroll, setScroll] = useState(0);
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const { actionHeader, myLanguage, scrollHeader } = useSelector((state) => state);



  const saveAllItem = () => {
    if (values.treeItem) {
      return handleValueState(null, null, setValues, {
        closeChild: false,
        dropBlockTable: false,
        closeAllBlockItems: true,
        parentBlock: versionInfo,
        investmentLevel: 0,
        addPublishingFilters: null,
      });
    }
    setValues(pre => ({
      ...pre,
      parentBlock: versionInfo,
      closeAllBlockItems: true,
      addPublishingFilters: null,
    }));
  };


  const selectedInfoElement = (e) => {
    if (e.target.getAttribute("name") === "allowСlick" && !values.blockClickCreateItemsBlock) {
       saveAllItem();
    }
  };

  const handleExpander = (block) => {
    setExpander({ ...expander, [block]: !expander[block] });
  };

  const findWidthBlock = () => {
    if (expander.left && expander.right) {
      return "1fr";
    }
    if (expander.left) {
      return "4.2fr 1.2fr";
    }
    if (expander.right) {
      return "1.2fr 4.2fr";
    }
    return "1.2fr 4fr 1.2fr";
  };

  useEffect(() => {
    if (versionInfo?.info?.name) {
      handleValueState(null, null, setValues, {
        "valuesName": versionInfo.info.name,
        parentBlock: versionInfo
      });
    }
  }, [versionInfo]);


  const handleParentName = (val) => {
    setValues(pre => ({ ...pre, "valuesName": val }));
  };

  const refContent = useCallback(node => {
    if (node && values.scrollTop) {
      setValues(pre => ({ ...pre, scrollTop: false }));
      node.scrollTop = 0;
      // node.style.transform = 'translateY(0px)';
    }
  }, [values.scrollTop]);


  const handleScroll = (e) => {
    const currentScrollTop = e.target.scrollTop;
    const scrollDirection = currentScrollTop > scroll ? "down" : "up";
    setScroll(currentScrollTop);
    if (scrollDirection !== prevVal) {
      prevVal = scrollDirection;
      dispatch(setHeaderScroll(scrollDirection === "down"));
    }
  };

  const selectedHandleParent = () => {
    handleValueState(null, null, setValues, {
      closeChild: false,
      dropBlockTable: false,
      closeAllBlockItems: true,
      parentBlock: versionInfo,
      investmentLevel: 0,
      treeItem: values.parentItemChild
    });
  };

  useEffect(() => {
    if (actionHeader && myLanguage) {
      saveAllItem()
      // saveBlock(snackbar, dispatch, values, myLanguage.value, saveAllItem);
    }
  }, [actionHeader]);


  // console.log('values', values.changeBlock, values); lockSidePanel

  return (
    <PanelContext.Provider value={{ values, setValues }}>
      <div className={`${cl.root}`}
           style={{
             transition: "1s",
             minHeight: scroll !== 0 ? "100vh" : "Calc(100vh - 49px)",
             // minHeight: scroll !== 0 ? '100vh' : `Calc(100vh - 49px)`,
             marginTop: scroll !== 0 ? 0 : 49,
             gridTemplateColumns: findWidthBlock()
           }}>
        <LeftExpanded
          name={"allowСlick"}
          onClick={(e) => {
            selectedInfoElement(e);
          }}
          style={{ display: expander.left ? "none" : "flex" }}
        />

        <div className={cl.wrapperEditor}>
          {expander.left ? (
            <div onClick={(e) => handleExpander("left")} className={cl.wrapperArrowLeftExpanded}>
              <ArrowDropDownIcon className={cl.arrowLeft}  />
              <div className={cl.wrapperArrowText}>Structure</div>
            </div>
          ) : (
            <div className={cl.wrapperArrowLeft}>
              <ArrowDropDownIcon onClick={(e) => handleExpander("left")} className={cl.arrowLeft} name="left" />
            </div>
          )}
          <div
            className={cl.wrapperMiddleBlock}
            name={"allowСlick"}
            onClick={(e) => {
              selectedInfoElement(e);
            }}
            style={{
              borderLeft: selectedParent ? "1px solid #01A1C7" : "",
              borderRight: selectedParent ? "1px solid #01A1C7" : ""
            }}
          >
            <BorderTypeColor name={"allowСlick"} el={versionInfo?.info}   />
            <div className={cl.wrapperContent} ref={refContent}
                 onScroll={handleScroll}
            >
              <div>
                <AbstractWrapper
                  id={versionInfo?.info.currentVersionId}
                  info={versionInfo?.info}
                  setValues={setValues}
                  closeOnclick={true}
                  treeItem={values.treeItem}
                  el={versionInfo}
                  // parentItemCreate={values.parentChild}
                />
                {nameShow && type !== "zert-phrase" && <div className={cl.wrapperValuesName} onClick={selectedHandleParent}>
                  <TextFieldItem
                    values={values.valuesName}
                    name={"name"}
                    setValues={handleParentName}
                    onBlur={()  =>  saveBlock(snackbar, dispatch, values, myLanguage.value)}
                  />
                </div>}
              </div>
              <div className={cl.wrapperScroll} name={"allowСlick"}>
                {children}
              </div>
            </div>
          </div>
          {expander.right ? (
            <div onClick={(e) => handleExpander("right")} className={cl.wrapperArrowLeftExpanded}>
              <ArrowDropDownIcon className={cl.arrowRight} />
              <div className={cl.wrapperArrowTextRight}>Properties and tools</div>
            </div>
          ) : (
            <div onClick={(e) => handleExpander("right")} className={cl.wrapperArrowLeft}>
              <ArrowDropDownIcon className={cl.arrowRight} />
            </div>
          )}
        </div>
        <RightExpanded
          name={"allowСlick"}
          onClick={(e) => {
            selectedInfoElement(e);
          }}
          style={{ display: expander.right ? "none" : "flex" }}
        />
      </div>

    </PanelContext.Provider>
  );
}

export default InfoElementWrapper;
