import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import handleValueState from "@zert-packages/utils/handleValueState";
import { ButtonCLM } from "@zert-packages/components";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import ImportTextDialog from "./components/dialog/ImportTextDialog";
import RemoveWorkingCopyDialog from "./components/dialog/RemoveWorkingCopyDialog";


const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
    marginTop: 30,
    marginBottom: 10,
    maxWidth: 'fit-content',
  }
}));

const getButtons = (values) => {
  return [
    {
      label: <FormattedMessage id="ElementTile.Actions.Copy" defaultMessage="Copy" />,
      type: "Copy",
      show: values.parentBlock,
    },
    {
      label: <FormattedMessage id="ElementTile.Actions.Paste" defaultMessage="Paste" />,
      type: "Paste",
      show: values.parentBlock,
    },
    {
      label: <FormattedMessage id="ElementTile.Actions.RemoveWorkingCopy" defaultMessage="Remove working copy" />,
      type: "RemoveWorkingCopy",
      show: values.parentBlock,
    },
    {
      label: <FormattedMessage id="ElementTile.Actions.ImportPhrases" defaultMessage="Import phrases" />,
      type: "ImportPhrases",
      show: true,
    },
    {
      label: <FormattedMessage id="ElementTile.Actions.ImportTable" defaultMessage="Import table" />,
      type: "ImportTable",
      show: true,
    }
  ].filter(el => el.show);
};

const FooterSideBar = () => {
  const cl = useStyles();
  const { values, setValues } = useContext(PanelContext);


  //renderInExceptionRoot

  const handleButton = (type) => {
    if(type === "RemoveWorkingCopy"){
      renderInExceptionRoot(RemoveWorkingCopyDialog, {values})
    }
    if(type === "ImportPhrases" || type === "ImportTable"){
      renderInExceptionRoot(ImportTextDialog, {type})
    }
    if(type ===  "Paste"){
      handleValueState({  }, 'pasteMainParent', setValues)
    }
    if(type === "Copy"){
      handleValueState({actionType: 'copy'}, 'copyMainParent', setValues)
    }

  }

  return (<div className={cl.wrapper}>
    {getButtons(values).map((el, i) =>
      <ButtonCLM
        key={i}
        style={{height: 25, minWidth: 59}}
        label={el.label}
        type={el.type}
        handle={handleButton}
      />
    )}
  </div>);

};

export default FooterSideBar;