import React, { useEffect, useState } from "react";
import { Menu } from '@mui/material';
import Tree from '@zert-packages/components/InfoElement/Structure/Tree';
import { getApiTree, getApiTreeChild } from '@zert-packages/components/InfoElement/Structure/API';
import BlockIcon from '@zert-packages/muiadopticons/BlockIcon';
import { getPluginByMimeTypeIcon } from '@zert-packages/utils/getPluginByMimeType';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import LabelIcon from '@mui/icons-material/Label';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoIcon from '@mui/icons-material/Info';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FlagIcon from '@mui/icons-material/Flag';
import useVersionManager from '@zert-packages/utils/useVersionManager';
import getTranslation from '@zert-packages/utils/getTranslation.old';
import { FormattedMessage } from 'react-intl';
import ToolBarEditor from './ToolBarEditor';
import FormatBlock from './FormatBlock';
import AvailableDialog from './dialog/AvailableDialog';
import SkeletonLoader from '../../SkeletonLoader';
import useStyles from './style/styleAccordionWrapper';
import OpenNewWindows from './OpenNewWindows';
import TableFormatDialog from './dialog/TableFormatDialog';
import { findSelectedBlock } from '../helpers/findSelectedBlock';
import { getParentElementsCount } from "clm/src/plugins/BlockPlugin/API";

function AbstractWrapper({
  info,
  values,
  el,
  id,
  child,
  context,
  setValues,
  setBlock,
  blockClick,
  showSideBar,
  block,
  closeOnclick,
  treeItem,
  parentItemCreate,
  selectedRange,
  findBorder,
  blockItems,
  createBlock,
  saveOnlyItem,
}) {
  // const select = context ? context : (el && values && values?.treeItem && (values?.treeItem?.identifier === el?.identifier));
  const select = saveOnlyItem || findSelectedBlock(context, values, el);
  const cl = useStyles({ blockClick });
  const [openTree, setOpenTree] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    part: false,
    parentCount: 'missing',
  });
  const [versionInfo] = useVersionManager();

  const type = info && info.mimeType.split('/').pop();

  const getTree = async () => {
    if (!id) {
      return;
    }
    if (child) {
      const result = await getApiTreeChild(id);
      if (result) {
        return Object.values(result)
          .flat()
          .map((el) => ({
            ...el,
            elementInfo: el,
            parentsTree: true,
            identifier: el.id
          }));
      }
    }
    const result = await getApiTree(id);
    if (result) {
      return Object.values(result).flat();
    }
    return [];
  };

  const handleOpenPopUp = (e) => {
    e.stopPropagation();
    if (blockClick) {
      return;
    }
    setOpenTree(e.currentTarget);
    setSelectedItem((pre) => ({ ...pre, part: true }));
  };
  const handleClose = () => {
    setOpenTree(null);
    setSelectedItem((pre) => ({ ...pre, part: false }));
  };

  useEffect(() => {
    if(id){
      getParentCount()
    }
  }, [id])

  if (!info) {
    return (
      <div style={{ marginBottom: -5 }}>
        <SkeletonLoader height={38} />
      </div>
    );
  }

  const closeSelectedBlock = (e) => {
    if (!setValues) {
      return;
    }
    if (select && setBlock && type === 'zert-block') {
      e.stopPropagation();
      setBlock((pre) => ({ ...pre, colapse: !pre.colapse }));
    }
    if (closeOnclick) {
      e.stopPropagation();
      if (setBlock) {
        setBlock((pre) => ({
          ...pre,
          child: null,
          dropBlockTable: false,
          addImgRedactor: null,
          expandedToolbar: false,
          expandedChild: false,
          selectedItemBlock: []
        }));
      }
      setValues((pre) => ({
        ...pre,
        investmentLevel: 0,
        closeAllBlockItems: true,
        treeItem: { versionInfo, elementInfo: versionInfo?.info },
        closeChild: false,
        dropBlockTable: false,
        severalSelectedTreeItem: null,
        selectedItemBlock: e.ctrlKey
          ? [...pre?.selectedItemBlock, pre?.treeItem, el].reduce((acu, cur) => {
              if (!cur?.identifier) {
                return acu;
              }
              if (!acu.find((item) => item.identifier === cur.identifier)) {
                return [...acu, cur];
              }
              return acu;
            }, [])
          : pre.selectedItemBlock
      }));
    }
  };

  const handleAvailable = (e) => {
    if (select) {
      e.stopPropagation();
    }
    renderInExceptionRoot(AvailableDialog, { block, setBlock, parentItemCreate });
  };

  const handlePlaceholder = (e) => {
    if (select) {
      e.stopPropagation();
    }
    renderInExceptionRoot(TableFormatDialog, { block, setBlock, parentItemCreate });
  };

  const getParentCount = async () => {
   const res = await getParentElementsCount(id)
    if(res && typeof(res) === 'number'){
     setSelectedItem(pre => ({ ...pre, parentCount: res }))
    }

  }



  return (
    <div
      style={{
        background: select ? 'rgb(244, 248, 250)' : '',
        cursor: treeItem && closeOnclick ? 'pointer' : ''
      }}
      onClick={closeSelectedBlock}
    >
      <div className={select ? cl.rootAbstractSelect : cl.rootAbstract} style={{ padding: select ? 5 : 0 }}>
        <div className={cl.wrapperInfo}>
          <div className={cl.wrapperAbstract}>
            <BlockIcon svg={getPluginByMimeTypeIcon(info.mimeType, true)} />
            {el?.localeFilter && el.localeFilter.length > 0 && <FlagIcon />}
            <div>{info.label}</div>
            {el?.languageSeparated && <InfoIcon style={{ color: '#01A1C7' }} />}
            {el?.publishingFilters?.length > 0 && <FilterAltIcon style={{ fontSize: '1.25rem' }} />}
            {(findBorder || el?.format?.label) && (
              <div className={cl.titleParagraph} onClick={handleAvailable}>
                {el?.format?.label ? (
                  getTranslation(el.format.label)
                ) : (
                  <FormattedMessage id="no.paragraph.format" defaultMessage="No paragraph format" />
                )}
              </div>
            )}
            <FormatBlock
              type={type}
              select={select}
              blockClick={blockClick}
              el={el}
              setBlock={setBlock}
              parentItemCreate={parentItemCreate}
            />
            {select && (
              <div className={cl.titleParagraph} onClick={handlePlaceholder}>
                {el?.elementInfo?.properties['zert:Format']}
              </div>
            )}
            <div style={{ display: 'flex', gap: 5 }}>
              <div className={cl.partText} onClick={(e) => handleOpenPopUp(e)}>
                <span style={{ color: selectedItem.part ? '#01A1C7' : '' }}>
                  <FormattedMessage id="part.of" defaultMessage="Part of: {value}"
                    values={{ value: selectedItem.parentCount }}
                  />
                </span>
              </div>
              <Menu
                anchorEl={openTree}
                open={Boolean(openTree)}
                onClose={() => handleClose()}
                classes={{
                  paper: cl.TreeRootPopup
                }}
              >
                <Tree getRootNode={() => getTree()} context={context} />
              </Menu>
              <div style={{ height: 15, display: 'flex' }}>
                {el?.label && <LabelIcon className={cl.labelHeaderIcon} />}
                {info.checkedOut && <FileDownloadIcon style={{ color: '#01A1C7' }} />}
              </div>
            </div>
          </div>
        </div>
        {!select && blockItems && block.isHoveredBlock && (
          <div className={cl.wrapperOpenIcon}>
            <OpenNewWindows el={el} />
          </div>
        )}
        <div style={{ display: select || showSideBar ? 'block' : 'none' }}>
          <ToolBarEditor
            createBlock={createBlock}
            blockItems={blockItems}
            selectedRange={selectedRange}
            setBlock={setBlock}
            parentItemCreate={parentItemCreate}
            block={block}
            context={context}
            el={el}
            saveOnlyItem={saveOnlyItem}
          />
        </div>
      </div>
    </div>
  );
}

export default AbstractWrapper;
