import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { format as FNSFormat } from 'date-fns';
import { stateToHTML } from 'draft-js-export-html';
import getTranslation from '@zert-packages/utils/getTranslation.new';
import { loadRiskAssesmentTemplates } from '@zert-packages/actions/riskassesmentActions';
import ModalCenter from '@zert-packages/components/common/ModalCenter';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import RichTextEditor from '@zert-packages/components/shared/RichTextEdit/RichTextEditor';
import { initRiskAssesmentAPI } from './API';

const formattedMessages = {
  dialogTitle: (
    <FormattedMessage
      id="RMP.DetailView.CreateRiskAssementDialog.DialogTitle"
      defaultMessage="Creating your risk assesment"
    />
  ),
  confirm: <FormattedMessage id="RMP.DetailView.CreateRiskAssementDialog.Confirm" defaultMessage="Create" />,
  cancel: <FormattedMessage id="RMP.DetailView.CreateRiskAssementDialog.Cancel" defaultMessage="Cancel" />,
  riskAssesmentTitle: <FormattedMessage id="RMP.CreateRiskAssementDialog.RiskAssesmentTitle" defaultMessage="Title" />,
  riskAssesmentTemplate: (
    <FormattedMessage id="RMP.CreateRiskAssementDialog.RiskRowTemplateLabel" defaultMessage="RiskRow Template" />
  ),
  locationOfElement: <FormattedMessage id="RMP.CreateRiskAssementDialog.LocationLabel" defaultMessage="Location" />,
  placementLabel: <FormattedMessage id="RMP.RiskRowTemplate.PlacementLabel" defaultMessage="Placement" />,
  descriptionLabel: <FormattedMessage id="RMP.CreateChecklistDialog.DescriptionLabel" defaultMessage="Description" />
};

const useStyles = makeStyles({
  DialogContent: {
    display: 'grid',
    gridTemplateRows: '50px 58px 38px 370px',
    alignItems: 'flex-start',
    gridGap: 20,
    padding: 20,
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  },
  fieldContainerWithLabel: {
    padding: 4,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
  }
});
function CreateRiskAssesmentDialog({ handleClose, onCreate, location }) {
  const cl = useStyles();
  const dispatch = useDispatch();
  const riskRowTemplates = useSelector((state) => state.rrtemplates);
  const [creating, setCreating] = useState(false);
  const catalog = useSelector((state) => state.catalog);
  const [state, setState] = useState({
    idtemplate: -1,
    name: '',
    description: ''
  });

  const updateStateByField = (field, value) =>
    setState((prev) => ({
      ...prev,
      [field]: value
    }));

  const onChangeRiskAssesmentTemplate = (e) => {
    const { value } = e.target;
    const todayDate = FNSFormat(new Date(), 'yyyy-MM-dd');
    const template = riskRowTemplates.find((template) => value === template.versionId);
    setState((prev) => ({
      ...prev,
      idtemplate: e.target.value,
      name: `${template.name} ${todayDate}`,
      description: template && template.description ? getTranslation(template.description) : ''
    }));
  };

  const onChangeRichEdit = (editorState) => {
    updateStateByField('description', stateToHTML(editorState.getCurrentContent()));
  };
  const handleOnCreate = () => {
    setCreating(true);
    const data = {
      name: state.name,
      description: state.description
    };
    initRiskAssesmentAPI(state.idtemplate, data)
      .then((res) => {
        onCreate(res);
        setCreating(false);
        handleClose();
      })
      .catch((e) => {
        setCreating(false);
        handleClose();
      });
  };

  useEffect(() => {
    dispatch(loadRiskAssesmentTemplates());
  }, []);

  if (creating) {
    return (
      <ModalCenter open>
        <Loader loaderAction={LoaderAction.Creating} />
      </ModalCenter>
    );
  }
  return (
    <Dialog fullScreen open onClose={handleClose}>
      <DialogTitle>{formattedMessages.dialogTitle}</DialogTitle>
      <DialogContent className={cl.DialogContent}>
        {riskRowTemplates === null || catalog === null || creating ? (
          <Loader />
        ) : (
          <>
            <TextField
              variant="standard"
              value={state.name}
              onChange={(e) => updateStateByField('name', e.target.value)}
              label={formattedMessages.riskAssesmentTitle}
            />

            {location && <TextField variant="standard" value={location} label={formattedMessages.locationOfElement} />}

            <FormControl variant="standard">
              <InputLabel>{formattedMessages.riskAssesmentTemplate}</InputLabel>

              <Select variant="standard" value={state.idtemplate} onChange={onChangeRiskAssesmentTemplate}>
                {riskRowTemplates.map((template, index) => (
                  <MenuItem key={index} value={template.versionId}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className={cl.fieldContainerWithLabel}>
              <Typography color="textSecondary" variant="subtitle1">
                {formattedMessages.descriptionLabel}
              </Typography>
              <RichTextEditor key={`${state.idtemplate}`} text={state.description} onChange={onChangeRichEdit} />
            </div>
            {/*     <RichTextEditWithLanguageSwitch
            state={state.description}
            onChange={(_state) => updateStateByField('description', _state)}
          /> */}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={state.idtemplate === -1} onClick={handleOnCreate} color="primary" variant="outlined">
          {formattedMessages.confirm}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {formattedMessages.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateRiskAssesmentDialog;
