import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { loadRiskAssessmentOverviewById } from '@zert-packages/actions/riskassesmentActions';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import { getStepNameByIndetifiers } from '@zert-packages/plugins/Environment';
import { TileIcon } from './AbstractTile';
import TileMatrixOverview from '../shared/TileMatrixOverview';
import withTile from '../shared/withTile';
import LabelRM from '../../shared/Label';

export const expandRiskAssessment = (element, dispatch) => {
  dispatch(loadRiskAssessmentOverviewById(element.versionId));
};

export function RiskAssessmentOverviewTile(props) {
  const raoverview = useSelector((state) => state.raoverview);

  const [RAOverview, setRAOverview] = useState(null);
  const [rrtemplateid, setRRTemplateId] = useState(null);

  useEffect(() => {
    if (!RAOverview && raoverview != null && raoverview.info.versionId == props.element.versionId) {
      setRAOverview(raoverview);
      setRRTemplateId(raoverview.templateVersionId);
    }
  }, [raoverview]);

  if (RAOverview == null) {
    return (
      <div className="togglable-info-header" style={{ display: 'flex' }}>
        <Loader />
      </div>
    );
  }

  return (
    <div
      id="right_side"
      style={{
        flex: '1',
        height: '100%',
        width: '100%',
        transition: 'flex 0s linear, height 0s step-start',
        transitionTimingFunction: 'linear, step-end'
      }}
    >
      <div>
        <div
          style={{
            color: '#84A6AF'
          }}
        >
          {RAOverview && (
            <FormattedMessage
              id="elememnttile.overview.identifiedrisk"
              defaultMessage="IDENTIFIED RISKS {countRA}"
              values={{ countRA: <span style={{ color: '#000000' }}>{RAOverview.totalRiskRowsCount}</span> }}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex'
          }}
        >
          <TileMatrixOverview element={props.element} rrtemplateid={rrtemplateid} />
        </div>
      </div>
    </div>
  );
}

function RiskAssessmentContentOverviewTile(props) {
  return (
    <LabelRM
      text={getStepNameByIndetifiers(props.element.properties['zert:CurrentStepIdentifier'])}
      styleName="warning"
    />
  );
}

export default withTile(TileIcon, RiskAssessmentOverviewTile, RiskAssessmentContentOverviewTile, expandRiskAssessment);
