import React from "react";
import { FormattedMessage } from "react-intl";
import SaveIcon from "@mui/icons-material/Save";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import ReportIcon from "@mui/icons-material/Report";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import GpsNotFixedIcon from "@mui/icons-material/GpsNotFixed";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import CachedIcon from "@mui/icons-material/Cached";
import { createInProgressSnack } from "../../common/snackBar";
import { makeStyles } from "@mui/styles";
import { handlePluginAction } from "@zert-packages/actions/coreReducers";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


const useStyles = makeStyles((theme) => ({
  label: {
    display: "flex",
    gap: 3,
    alignItems: "center",
    // border: '1px solid red',
    cursor: "pointer",
    fontSize: "1.15rem",
    "@media  (max-width: 1600px)": {
      fontSize: "1.05rem"
    }
  }

}));


export const ButtonList = (dispatch) => {
  const cl = useStyles();
  const handleBlock = (type) => {
    if (type === "save") {
      if (dispatch) {
        dispatch(handlePluginAction(type));
      }
      // const generatingSnackbar = createInProgressSnack(
      //   snackbar,
      //   <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..." />
      // );
      // snackbar.closeSnackbar(generatingSnackbar);
    }
  };


  return [
    {
      disabled: false,
      icon: <SaveIcon />,
      pluginTypes: 'table, all',
      type: "Save",
      label: <div onClick={() => handleBlock("save")} className={cl.label}>
        <SaveIcon />
        <span><FormattedMessage id="ElementTile.Actions.Save" defaultMessage="Save" /></span>
      </div>
    },
    {
      disabled: false,
      icon: <CancelScheduleSendIcon />,
      type: "Cancel",
      pluginTypes: 'table, all',
      label: <div onClick={handleBlock} className={cl.label}>
        <CancelScheduleSendIcon />
        <span> <FormattedMessage id="ElementTile.Actions.Cancel" defaultMessage="Cancel" /></span>
      </div>
    },
    // {
    //   disabled: false,
    //   icon: <DeleteOutlineIcon />,
    //   pluginTypes: 'table',
    //   type: "Remove",
    //   label: <div className={cl.label}>
    //     <DeleteOutlineIcon />
    //     <span><FormattedMessage id="ElementTile.Actions.Remove" defaultMessage="Remove" /></span>
    //   </div>
    // },
    {
      disabled: false,
      icon: <ReportIcon />,
      type: "Report",
      pluginTypes: 'table, all',
      label: <div onClick={handleBlock} className={cl.label}>
        <ReportIcon />
        <span><FormattedMessage id="ElementTile.Actions.Report" defaultMessage="Report" /></span>
      </div>
    },
    {
      disabled: false,
      icon: <GpsNotFixedIcon />,
      type: "Find",
      pluginTypes: 'table, all',
      label: (
        <div onClick={handleBlock} className={cl.label}>
          <GpsNotFixedIcon />
          <span><FormattedMessage id="ElementTile.Actions.Find" defaultMessage="Find" /></span>
        </div>
      )
    },
    {
      disabled: false,
      icon: <UndoIcon />,
      type: "Undo",
      pluginTypes: 'table, all',
      label: (
        <div onClick={handleBlock} className={cl.label}>
          <UndoIcon />
          <span><FormattedMessage id="ElementTile.Actions.Undo" defaultMessage="Undo" /></span>
        </div>
      )
    },
    {
      disabled: false,
      icon: <RedoIcon />,
      type: "Redo",
      pluginTypes: 'table, all',
      label: (
        <div onClick={handleBlock} className={cl.label}>
          <RedoIcon />
          <span><FormattedMessage id="ElementTile.Actions.Redo" defaultMessage="Redo" /></span>
        </div>
      )
    },
    {
      disabled: false,
      icon: <TrendingUpIcon />,
      type: "MoveUp",
      pluginTypes: ' all',
      label: (
        <div onClick={handleBlock} className={cl.label}>
          <TrendingUpIcon />
          <span><FormattedMessage id="ElementTile.Actions.MoveUp" defaultMessage="Move up" /></span>
        </div>
      )
    },
    {
      disabled: false,
      icon: <SouthEastIcon />,
      type: "MoveDown",
      pluginTypes: 'all',
      label: (
        <div onClick={handleBlock} className={cl.label}>
          <SouthEastIcon />
          <span><FormattedMessage id="ElementTile.Actions.MoveDown" defaultMessage="Move down" /></span>
        </div>
      )
    },
    {
      disabled: false,
      icon: <CachedIcon />,
      pluginTypes: 'all',
      type: "UpdateReferences",
      label: (
        <div onClick={handleBlock} className={cl.label}>
          <CachedIcon />
          <span><FormattedMessage id="ElementTile.Actions.UpdateReferences" defaultMessage="Update references" /></span>
        </div>
      )
    },



  ];
};


