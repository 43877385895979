import React, {  useEffect,  useState } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog } from "@mui/material";
import DropMenu from "../../../DropMenu";
import { handleValue } from "clm/src/plugins/BlockPlugin/utils/main";
import { storeBlockApi } from "../API";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 262,
    padding: 5,
    paddingBottom: 10,
    background: "#E2F2F6",
    minHeight: 168,
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "flex-end",
    "& svg": {
      cursor: "pointer"
    }
  },
  wrapper: {

  },
  wrapperList: {
    marginTop: 5,
    border: '1px solid lightgray',
    overflow: 'auto',
    minHeight: 100,
    maxHeight: 135,
    padding: 5,
  },
  togetherTitle: {
    fontWeight: 500,
    margin: '5px 0px 5px 2px',
  },
  listItem: {
    cursor: "pointer",
    '&:hover':{
      backgroundColor: '#CECECE',
    }
  },
}));

const GroupDialog = ({format,  el, parentItemCreate, setBlock}) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);
  const [group, setGroup] = useState({
    indexSelectedGroup: 0,
    togetherSelected: 'No',
    togetherDropMenu: ['Yes', 'No'],
    selectedGroup: null
  })
  const references = format?.formats


  const handleClose = async () => {
    setOpen(false);
    const value = group.togetherSelected === 'Yes' ? true : false
    if(el.translatable !== value){
      if(parentItemCreate){
        setBlock(pre => ({...pre, blockItem: {...pre.blockItem, translatable: value}}))
        const items = parentItemCreate.items.reduce((acu, cur) => {
          if(cur.identifier === el.identifier){
            return [...acu, { ...el, translatable: value }]
          }
          return [...acu, cur]
        }, [])
        const createItemData = {
          name: parentItemCreate.name,
          targetLocale: parentItemCreate.targetLocale,
          format: parentItemCreate.format,
          items
        };
        await storeBlockApi(parentItemCreate.versionId, parentItemCreate.targetLocale.language, createItemData);
      }
    }
  };

  const handleDropMenu = (val, name, group) => {
    handleValue(val, name, setGroup, group)
  }

  const handleSelectedGroup = (val, idx) => {
    if(group.indexSelectedGroup === idx){
      return
    }
    handleDropMenu(false, false, {
      selectedGroup: val,
      indexSelectedGroup: idx,
    })
  }

  //translatable

  useEffect(() => {
    if( el.translatable){
      handleValue('Yes', 'togetherSelected', setGroup)
    }
  }, [])




  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapper}>
      {/*<div className={cl.wrapperTitle}>*/}
      {/*  <CloseIcon onClick={handleClose} />*/}
      {/*</div>*/}
      <div className={cl.wrapperList}>
        {references && references.map((el, i) => <div className={cl.listItem}
                                                      name={i}
         key={i}
         style={{
          backgroundColor: group.indexSelectedGroup === i ? '#CECECE': ''
        }}
           onClick={() => handleSelectedGroup(el, i)}>
          {el}</div>)}
      </div>
        <p className={cl.togetherTitle}>Keep together:</p>
        <DropMenu
          selected={group.togetherSelected}
          dropItem={group.togetherDropMenu}
          name={'togetherSelected'}
          setDropItem={handleDropMenu}
        />
    </div>
  </Dialog>);
};

export default GroupDialog;