import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import TextFieldItem from "@zert-packages/components/TextFieldItem";
import { BlockItemsContext } from "../BlockItems";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";


const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
    marginTop: 7
  },
  noteActiveInputWrapper: {
    "& input": {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 15
    },
    "& textarea": {
      overflow: "hidden"
    },
    "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
      paddingTop: 5,
      paddingBottom: 5
    }
  },
  notesInput: {
    margin: 0,
    width: "100%"
  }
}));

const Title = ({ el }) => {
  const cl = useStyles();
  const { block, setBlock } = useContext(BlockItemsContext);
  const { values, setValues } = useContext(PanelContext);
  const error = block.nameBlock.length === 0;


  const handleName = (val, name) => {
    setBlock(pre => ({ ...pre, [name]: val }));
    const blockClickCreateItemsBlock = !(val.length > 0);
    setValues(pre => ({ ...pre, blockClickCreateItemsBlock, changeBlock: true }));

  };

  useEffect(() => {
    if (el?.elementInfo?.name) {
      setBlock(pre => ({ ...pre, "nameBlock": el?.elementInfo?.name }));
    }
  }, [el?.elementInfo?.name]);


  return (<div
    className={cl.root}
    style={{ "WebkitBoxShadow": error ? "0 0 10px red" : "" }}
    onClick={(e) => e.stopPropagation()}
  >
    <TextFieldItem
      error={error}
      values={block.nameBlock}
      name={"nameBlock"}
      setValues={handleName}
    />
  </div>);
};

export default Title;