import React, {useCallback, useContext, useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import _ from "lodash";
import {preSaveItems} from "../../../../../BlockPlugin/utils/createItem";
import {createBlockItem, createIndex, createPhrase, createTableItem, handleActionToolBar} from "./utils";
import {BlockItemsContext} from "./AddToolbarDialog";
import PhraseEditor from "../../../../../PhrasePlugin/components/PhraseEditor";
import SkeletonLoader from "@zert-packages/components/SkeletonLoader";
import TextFieldItem from "@zert-packages/components/TextFieldItem";
import AddIcon from "@mui/icons-material/Add";
import TableEditorWrapper from "../../../../../PhrasePlugin/components/table/TableEditorWrapper";
import BlockWrapper from "../../../../../BlockPlugin/BlockWrapper";
import {createPhraseTable} from "@zert-packages/components/InfoElement/components/API";
import AbstractWrapper from "@zert-packages/components/InfoElement/components/AbstractWrapper";

const useStyles = makeStyles((theme) => ({
    wrapperBlockItems: {
        minHeight: 42,
        paddingRight: 5,
        marginBottom: 10,
        cursor: "default"
    },
    container: {
        padding: 5
    },
    wrapperAddToolbar: {
        display: "grid",
        gridTemplateColumns: "25px 1fr",
        marginTop: 8
    },
    wrapperTextField: {
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5
    },
    addImgButton: (props) => ({
        height: 16,
        width: 16,
        paddingTop: 3,
        backgroundColor: "#59C1DB",
        color: "#ffffff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 3,
        fontSize: 15,
        fontWeight: 900,
        paddingBottom: 4,
        cursor: props.blockClick ? "not-allowed" : "pointer"
    })
}));

const findSelectedItems = (block, type) => {
    if (block.item.length === 0) {
        return false
    }
    return block.item.find(el => el.elementInfo.mimeType.split('/')[1] === type)
//     block.item
}

const CreateItems = ({blockCreate,setBlockCreate, values, it, tableData, setValues, setTableData}) => {
    const cl = useStyles();
    const createType = blockCreate.createType
    const type = 'zert-' + createType
    const {myLanguage, activeLocales} = useSelector((state) => state);
    const {block, setBlock} = useContext(BlockItemsContext)
    const snackbar = useSnackbar();
    const [name, setName] = useState("");
    const [createBlock, setCreateBlock] = useState({
        loadingInit: false
    });
    const selectedItems = findSelectedItems(block, type)
    const error = name.length === 0;
    const parentId = values.tableDefault.elementInfo.versionId


    const handleName = (val) => {
        setName(val);
    };



    const createInitBlock = async () => {
        const myLocale = myLanguage.value

        if (type === "zert-phrase" && !selectedItems) {
            await createPhrase(snackbar, myLocale, parentId, setBlock, values, it, tableData)
        }


        // setCreateBlock(pre => ({...pre, loadingInit: true}));
        // if (type === "zert-block") {
        //     await createBlockItem(snackbar, mylocale, parentId, setBlock)
        // }
        // if (type === "zert-table") {
        //     await  createTableItem(snackbar, mylocale, parentId, setBlock)
        // }
        // if (type === "zert-index") {
        //   await createIndex(snackbar, mylocale, parentId, setBlock)
        // }
        handleName("");
        setCreateBlock(pre => ({...pre, loadingInit: false}));
    }

    const handleElement = (typeSave, type, item, snackbar) => {
       handleActionToolBar(typeSave,
           type,
           item,
           snackbar,
           blockCreate, setBlockCreate , activeLocales, myLanguage, selectedItems, values, it, setValues, setTableData)
    }

    const handlePhrase = (valueEditor) => {
        setBlockCreate(pre=> ({...pre, phraseEditor: valueEditor}))
    }

    useEffect(() => {
        createInitBlock();
    }, [type]);









    return (<div className={cl.wrapperBlockItems}>
        <div className={cl.container}>
            {selectedItems && <>
                {type === "zert-phrase" && <div style={{marginTop: 5}}>
                    <div style={{marginBottom: 5}}>
                        <AbstractWrapper
                            id={selectedItems?.elementInfo?.versionId}
                            info={selectedItems?.elementInfo}
                            el={selectedItems}
                            context={BlockItemsContext}
                            saveOnlyItem={handleElement}
                        />
                    </div>
                    <PhraseEditor
                        el={selectedItems}
                        vue={true}
                        setPhrase={handlePhrase}
                        context={BlockItemsContext}
                    />
                </div>}
            </>}
            {createBlock.loadingInit
                ? <SkeletonLoader height={38}/>
                : (type === "zert-table" || type === "zert-block" || type === "zert-index") &&
                <div className={cl.wrapperTextField}>
                    <div style={{"WebkitBoxShadow": error ? "0 0 10px red" : ""}}>
                        <TextFieldItem
                            // values={block.nameBlock}
                            values={name}
                            name={"nameBlock"}
                            setValues={handleName}
                            error={error}
                            autoFocus={true}
                        />
                    </div>
                </div>}
            {type === "zert-table" && selectedItems && <TableEditorWrapper
                init={selectedItems}
                vue={false}/>}
            {type === "zert-block" && selectedItems && <>
                <BlockWrapper
                    table={true}
                    block={selectedItems}
                />
            </>}
        </div>
    </div>);
}

export default CreateItems