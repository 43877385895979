import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { createVersion } from "./API";
import { PanelContext } from "../InfoElementWrapper/InfoElementWrapper";
import { getSideBarMenu } from "../helpers/getSideBarMenu";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { createInProgressSnack } from "../../common/snackBar";
import { FormattedMessage } from "react-intl";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogAddAlias from "clm/src/plugins/PhrasePlugin/components/dialog/DialogHandleImg/DialogAddAlias";
import {
  addAlias,
  approveElement, cancelBlock,
  copyElementBlock, duplicateElementBlock, pastElementBlock,
  removeElement,
  saveBlock,
  saveImg, saveIndex,
  savePhrase, saveTable
} from "../helpers/ToolBarAction";
import OpenNewWindows from "./OpenNewWindows";
import { handleCheckin } from "clm/src/plugins/BlockPlugin/utils/handleCheckin";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexWrap: "wrap"
  },
  button: {
    color: "#178FC7",
    fontWeight: "bold",
    fontSize: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    minHeight: 21,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    "&:hover": {
      color: "#ffffff"
    }
  },
  wrapperSaveImg: {
    "& input": {
      display: "none"
    },
    "& label": {
      marginBottom: 0
    }
  }
}));

const ToolBarBlockEditor = ({
                              el,
                              style = {},
                              context,
                              block,
                              setBlock = () => {},
                              parentItemCreate,
                              selectedRange,
                              blockItems,
                              saveOnlyItem
                            }) => {
  const cl = useStyles();
  const { myLanguage, activeLocales, } = useSelector((state) => state);
  const { versionId } = useParams();
  const [hover, setHover] = useState(false);
  const { values, setValues } = useContext(context ? context : PanelContext);
  const snackbar = useSnackbar();
  const [toolBar, setToolBar] = useState({
    loading: false
  });
  const closeAll = values.closeAllBlockItems;
  const idSaveElement =  values.action?.preSaveElement?.elementInfo.currentVersionId
  const idTreeItem = values?.treeItem?.elementInfo?.currentVersionId
  const actionElement = el


  const handleIcon = async (e, el, values, novLoading, blockProps=block) => {
   // return
    const block = blockProps
    if (e) {
      e.stopPropagation();
    }
    const item = values.treeItem?.elementInfo;
    const mylocale = myLanguage.value

    if(saveOnlyItem){
      const type = actionElement?.elementInfo?.mimeType.split("/").pop();
      return  saveOnlyItem(el.type, type, actionElement.elementInfo, snackbar)
    }

    if (!item || values.blockClickCreateItemsBlock || values.loadingSave) {
      return;
    }



    setValues(pre => ({ ...pre, loadingSave: true }));
    const type = item.mimeType.split("/").pop();

    if (el.type === "New version") {
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="ToolBarPhraseEditor.SnackBar.CreatingNewVersion"
                          defaultMessage="Creating a new version..." />
      );
      await createVersion(item.companyId, item.versionId);
      snackbar.closeSnackbar(generatingSnackbar);
    }
    if (el.type === "Copy") {
      await copyElementBlock(values, selectedRange);
    }
    if (el.type === "Preview") {

    }
    if(item.approvedAt){
      return
    }


    if (el.type === "Save") {
      const generatingSnackbar = !novLoading && createInProgressSnack(
        snackbar,
        <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..." />
      );
      // try {
        if (type === "zert-phrase") {
          await savePhrase(values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, versionId);
        }
        if (type === "zert-block" || type === "zert-image") {
          await saveBlock(values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, type === "zert-image");
        }
        if (type === "zert-table") {
          await saveTable(values, block, mylocale, parentItemCreate, setValues, setBlock);
        }
        if(type === "zert-index"){
          await saveIndex(values, activeLocales, mylocale, block, parentItemCreate, setValues, setBlock, versionId)
        }

      // } catch (e) {
      //   console.log('error ====================', e.message);
      // }
      !novLoading && snackbar.closeSnackbar(generatingSnackbar);
    }
    if (el.type === "Cancel") {
      setBlock(pre => ({ ...pre,
        createType: null,
        expandedToolbar: false,
        beforeTreeItem: [], }));
      cancelBlock(values, setValues, block?.blockItem);
    }
    if (el.type === "Approve") {
      const generatingSnackbar = createInProgressSnack(snackbar,
        <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Approving" defaultMessage="Approving..." />
      );
      await approveElement(generatingSnackbar, snackbar, item);
    }

    if (el.type === "Remove") {
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Removing" defaultMessage="Removing..." />
      );
      await removeElement(generatingSnackbar, parentItemCreate, values, setValues, snackbar, setBlock);
    }
    if (el.type === "Add label") {
      renderInExceptionRoot(DialogAddAlias, { addAlias, values });
    }

    if (el.type === "Paste") {
      await pastElementBlock(setValues);
    }
    if (el.type === "Duplicate") {
      await duplicateElementBlock(values, setValues);
    }

    setValues(pre => ({ ...pre,
      loadingSave: false,
      changeBlock: false,
      preSaveElement: null,
    }));
  };


  const saveItems = async () => {
    const item = {
      ...values,
      treeItem:  el
    };
    await handleIcon(false, { type: "Save" }, item, );
    const res = {};
    if (closeAll) {
      res.treeItem = null;
    }
    if (values.beforeTreeItem[1]) {
      res.beforeTreeItem = [values.beforeTreeItem[1]];
    }
    setValues(pre => ({ ...pre, beforeTreeItem: res.beforeTreeItem,   }));
  };


  useEffect(() => {
    if(idSaveElement && idTreeItem && el && (idSaveElement!== idTreeItem)
      && (el?.elementInfo?.currentVersionId === idSaveElement)){
        setValues(pre => ({
          ...pre,
          action: { ...pre.action, preSaveElement: null }
        }));
      const item = {
        ...values,
        treeItem:  el
      };
      handleIcon(false, { type: "Save" }, item, )
    }
  }, [idSaveElement, idTreeItem, values.treeItem, el,])

  useEffect(() => {
    if (values.beforeTreeItem?.length > 1 && values.changeBlock) {
      if (values.beforeTreeItem && el?.identifier && values.beforeTreeItem[0] && (el.identifier === values.beforeTreeItem[0]?.identifier)) {
        saveItems();
      }
    }
  }, [values.beforeTreeItem]);


  useEffect(() => {
    if(block?.saveCreateElement && values.treeItem.elementInfo.versionId  === el.elementInfo.versionId ){
      setBlock(pre => ({...pre, saveCreateElement: false}))
      const item = {
        ...values,
        treeItem:  el
      };
       handleIcon(false, { type: "Save" }, item, )
    }
  }, [block?.saveCreateElement])


  useEffect(() => {
    if (closeAll && values.treeItem?.identifier === el?.identifier && values.treeItem) {
      setValues(pre => ({...pre, closeAllBlockItems: false}))
      saveItems();
    }
  }, [closeAll]);


  return (
    <div className={cl.root} style={style}>
      {el && getSideBarMenu(el).map((el, i) => <div
        onMouseEnter={() => {
          setHover(i);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        style={{ backgroundColor: i === hover ? el.color : "" }}
        className={cl.button}
        key={i}>
        {el.type === "img"
          ? <div className={cl.wrapperSaveImg}>
            <label>
              {el.name}
              <input
                accept=".svg, .png, .bmp, .gif, .jpg, .jpeg"
                type="file"
                id="file1"
                onChange={saveImg}
              />
            </label>
          </div>
          : <div onClick={(e) => handleIcon(e, el, values)}
                 style={{
                   opacity: !values.treeItem ? "0.5" : "",
                   display: "flex",
                   alignItems: "center"
                 }}>
            {el.child && <MenuIcon style={{ height: 19 }} />}
            {el.name}
          </div>
        }
      </div>)}
      {blockItems && <OpenNewWindows
        style={{ marginLeft: "auto" }}
        el={el}
      />}
    </div>);
};

export default ToolBarBlockEditor;