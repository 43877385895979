import React, {useContext, useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Tab, Tabs,} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import translateable from './image/translateable-icon-colored.png';
import SelectedCell from './SelectedCell';
import SelectedLanguagePopup from '../SelectedLanguagePopup';
import {PanelContext} from '../InfoElementWrapper/InfoElementWrapper';
import {storeLocalesFilters} from './API';
import {getApiLocalesFilters, getApiTree} from '../Structure/API';
import { findDisabled } from "../helpers/main";
import PublishingFilters from "./PublicationFilters";


const useStyles = makeStyles(() => ({
  languageFiltersRoot: {
    flexGrow: 1,
    minHeight: 250,
    '& .MuiCollapse-entered': {
      height: '100% !important'
    },
    '& .MuiCollapse-wrapper': {
      height: '100%'
    },
    '& #panel1a-content': {
      height: '100%'
    }
  },
  ExpandIcon: {
    '& .MuiAccordionSummary-expandIcon':  (props) => ({
      transform: props.expanded.expandedPanelTwo ? 'rotate(180deg)' : 'rotate(270deg)'
    })
  },
  rootAccordion: {
    borderRadius: '0px !important',
    borderBottom: '1px solid #E2F2F6',
    height: '100%'
  },
  AccordionDetailsRoot: {
    display: 'block',
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    },
    height: 'Calc(100% - 67px)',
    position: 'relative',
    overflowX: 'hidden'
    // maxHeight: heightLanguage ? `${heightLanguage}px` : '100%',
    // overflow: 'auto',
  },
  wrapperBlock: {
    position: 'absolute',
    width: 'Calc(100% - 11px)'
  },
  titleFilter: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: 5
  },
  checkboxRoot: {},
  checkboxLabel: {
    fontSize: 15
  },
  wrapperContainerDisable: {
    position: 'relative',
    opacity: '0.57',
    '&:before': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      content: "' '",
      zIndex: 2
    }
  },
  wrapperTabs: {
    '& .MuiTab-textColorPrimary': {
      background: '#EBEBEB',
      padding: '5px 2.5px',
      minWidth: 'fit-content',
      minHeight: 26,
      height: 26,
      outline: 'none',
      '&:hover': {
        background: '#4db1d3 ',
        color: '#ffffff'
      }
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      background: '#4db1d3 ',
      color: '#ffffff'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left'
    },
    '& .MuiTabs-root': {
      minHeight: 26,
      marginBottom: 10
    },
    '& .PrivateTabIndicator-colorSecondary': {
      display: 'none'
    }
  },
  selectedCellWrapper: {}
}));

function ExpanderLanguageFilters({ expanded, setExpanded }) {
  const [checkboxState, setCheckboxState] = useState(false);
  const [selectedTab, setSelectTab] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const cl = useStyles({expanded});
  const [selected, setSelected] = useState(null);
  const { values, setValues } = useContext(PanelContext);
  const { versionId } = useParams();
  const { mylocale } = useSelector((state) => state);
  const id = values?.parentItem?.elementInfo?.versionId
  const parentVersionId = (id && values.investmentLevel > 0) ? id  : versionId
  const [disabled, setDisabled] = useState(true)



  const handleChange = () => {
    setExpanded({ ...expanded, expandedPanelTwo: !expanded.expandedPanelTwo });
  };
  const handleChangeCheckbox = (e) => {
    const val = e.target.checked
  };

  const handleChangeSelectedTab = (event, newValue) => {
    setSelectTab(newValue);
  };

  const handleSetSelectedLanguage = async (el) => {
    const result = selectedLanguage.filter((item) => item !== el);
    await handleLanguage(false, result);
  };


  const getTree = async () => {
    return getApiTree(versionId, mylocale);
  };


  const handleLanguage = async (lng, remove) => {
    if (values.treeItem) {
      let resultFilters;
      let selectedTabLng;
      if (selectedTab === 1) {
        selectedTabLng = 'INCLUDE';
      }
      if (selectedTab === 2) {
        selectedTabLng = 'EXCLUDE';
      }

      if (values && values.localesFilters && values.localesFilters.right) {
        resultFilters = [lng, ...values.localesFilters.right];
      } else {
        resultFilters = [lng];
      }
      if (remove) {
        resultFilters = remove;
      }
      if (selectedTab === 0) {
        selectedTabLng = 'NONE';
        resultFilters = [];
      }
      const  identifier =  values.treeItem.identifier.replace(/['[']/g, '(').replace(/['\]']/g, ')').split(' ')[0].split(')')[0] + ')'
      await storeLocalesFilters(
        parentVersionId,
        values.treeItem.elementInfo.versionId,
        identifier,
        selectedTabLng,
        resultFilters
      );
      await getLocalesFilters()
      const resultGetTree = await getTree();
      setValues((prev) => ({ ...prev, treeChildren: resultGetTree }));
    }
  };

  const getLocalesFilters = async () => {
    const  identifier =  values.treeItem.identifier.replace(/['[']/g, '(').replace(/['\]']/g, ')').split(' ')[0].split(')')[0] + ')'
    const resLocalesFilters = await getApiLocalesFilters(
      parentVersionId,
      values.treeItem.elementInfo.versionId,
      identifier
    );

    setValues((prev) => ({ ...prev, localesFilters: resLocalesFilters }));
  }



  useEffect(() => {
    if(values.treeItem && values.treeItem.identifier && values.treeItem?.elementInfo?.versionId
      && !values.treeItem.parentTreeItem ){
      try {
        getLocalesFilters()
      } catch (e) {
        console.log('Error getLocalesFilters!', e.messages);
      }

    }
  } , [values.treeItem])


  useEffect(() => {
    if (values.localesFilters) {
      if (values.localesFilters.left) {
        if (values.localesFilters.left === 'include') {
          setSelectTab(1);
        }
      }
      if (values.localesFilters.right && values.localesFilters.right.length > 0) {
        setSelectedLanguage(values.localesFilters.right);
      } else {
        setSelectedLanguage(null);
      }
      if (!values.localesFilters.left) {
        if (selectedTab > 0) {
          setSelectTab(0);
        }
        if (selectedLanguage) {
          setSelectedLanguage(null);
        }
      }
    }

  }, [values.localesFilters, values.treeItem]);

  useEffect(() => {
    if(values.treeItem){
      setCheckboxState(values.treeItem.translatable)
    }
    findDisabled(values, setDisabled)
  }, [values.treeItem]);



  return (
    <div className={expanded.expandedPanelTwo ? cl.languageFiltersRoot : ''}>
      <Accordion
        expanded={expanded.expandedPanelTwo}
        onChange={() => handleChange('panel1')}
        className={cl.rootAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={cl.ExpandIcon}
        >
          Language and Filters
        </AccordionSummary>
        <AccordionDetails className={cl.AccordionDetailsRoot}>
          <div className={cl.wrapperBlock}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  onChange={handleChangeCheckbox}
                  checked={checkboxState}
                  className={cl.checkboxRoot}
                />
              }
              label={
                <span className={cl.checkboxLabel}>
                  <img src={translateable} alt="translateable" /> Translatable
                </span>
              }
            />
            <p className={cl.titleFilter}> Language Filter</p>

            <div
               className={ disabled ?  cl.wrapperContainerDisable : ''}
            >
              <div className={cl.wrapperTabs}>
                <Tabs
                  value={selectedTab}
                  onChange={handleChangeSelectedTab}
                  indicatorColor=""
                  textColor="primary"
                  variant="standard"
                  scrollButtons="auto"
                  centered
                >
                  <Tab label="None" />
                  <Tab label="Include for" />
                  <Tab label="Exclude for" />
                </Tabs>
              </div>
              <SelectedLanguagePopup handleLanguage={handleLanguage} />
              <div className={cl.selectedCellWrapper}>
                {selectedLanguage &&
                  selectedLanguage.length > 0 &&
                  selectedLanguage.map((el, idx, arr) => (
                    <SelectedCell
                      selected={selected}
                      setSelected={setSelected}
                      handleSetSelectedLanguage={handleSetSelectedLanguage}
                      item={el}
                      key={idx}
                      idx={idx}
                      lastCell={idx === arr.length - 1}
                    />
                  ))}
              </div>
              {/*<CategoryRoot />*/}
              <PublishingFilters/>
              {/* {selectedTab === 0 &&   <div>None</div>} */}
              {/* {selectedTab === 1 &&   <div>Include for</div>} */}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default ExpanderLanguageFilters;
