import React, { useEffect, useState, useContext } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { PanelContext } from "./InfoElementWrapper/InfoElementWrapper";


const useStyles = makeStyles((theme) => ({
  rootExpanderElementProperties: {
    flexGrow: 1,
    '& .MuiCollapse-root': {
      position: 'absolute',
      width: '100%',
    },
  },
  ExpandIcon: {
    "& .MuiAccordionSummary-expandIcon": (props) => ({
      transform: props.expanded.expandedPanelTwo ? "rotate(180deg)" : "rotate(270deg)"
    })
  },
  rootAccordion: {
    borderRadius: "0px !important",
    borderBottom: "1px solid #E2F2F6",
    height: "100%"
  },
  AccordionDetailsRoot: {
    display: "block",
    paddingBottom: "30px !important"
  },
  wrapperText: {
    display: "grid",
    gridTemplateColumns: "61px 64px 15px 1fr",
    fontSize: 10,
    gap: 1,
    justifyContent: "space-between",
    "&  p": {
      marginBottom: 1
    }
  },
  AccordionDetailsTitle: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  textDate: {
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "500"
  }
}));

const defaultState = {
  name: "",
  elementId: -1,
  versionId: -1,
  created: {
    value: "",
    name: ""
  },
  lastChange: {
    value: "",
    name: ""
  },
  approved: {
    value: "",
    name: ""
  }
}

function ExpanderElementProperties({ expanded, setExpanded, context }) {
  const { values, setValues } = useContext(context ? context : PanelContext);
  const { versionInfo } = useSelector((state) => state);
  const [properties, setProperties] = useState(defaultState);
  const cl = useStyles({ expanded });

  const handleChange = () => {
    setExpanded({ ...expanded, expandedPanelOne: !expanded.expandedPanelOne });
  };



  useEffect(() => {
    if (versionInfo?.info) {
      const info = versionInfo?.info;
      setProperties(pre => ({
        ...pre,
        name: info.name,
        id: info.id,
        versionId: info.currentVersionId,
        created: {
          value: info.createdAt,
          name: info.createdBy
        },
        lastChange: {
          value: info.changedAt,
          name: info.changedBy
        },
        approved: {
          value: info.approvedAt,
          name: info.approvedBy
        }
      }));
    }
  }, [versionInfo?.info]);



  useEffect(() => {
    if (values.treeItem) {
      if(values.treeItem.type === 'default'){
        return  setProperties(defaultState)
      }
      const info = context ? values.treeItem
                            : values.treeItem.elementInfo;
      setProperties(pre => ({
        ...pre,
        name: info.name,
        id: info.id,
        versionId: info.currentVersionId,
        created: {
          value: info.createdAt,
          name: info.createdBy
        },
        lastChange: {
          value: info.changedAt,
          name: info.changedBy
        },
        approved: {
          value: info.approvedAt,
          name: info.approvedBy
        }
      }));
    }
  }, [values.treeItem]);



  return (
    <div className={expanded.expandedPanelOne ? cl.rootExpanderElementProperties : ""}>
      <Accordion
        expanded={expanded.expandedPanelOne}
        onChange={() => handleChange("panel1")}
        className={cl.rootAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={cl.ExpandIcon}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Element Properties
        </AccordionSummary>
        <AccordionDetails className={cl.AccordionDetailsRoot}>
          <div className={cl.wrapperText}>
            <p className={cl.AccordionDetailsTitle}>Name:</p>
            <p>{properties.name}</p>
          </div>
          <div className={cl.wrapperText}>
            <p className={cl.AccordionDetailsTitle}>Element Id:</p>
            <p>{properties.id}</p>
          </div>
          <div className={cl.wrapperText}>
            <p className={cl.AccordionDetailsTitle}>Version Id:</p>
            <p>{properties.versionId}</p>
          </div>
          <div className={cl.wrapperText}>
            <p className={cl.AccordionDetailsTitle}>Created:</p>
            <p>{properties.created.value ? properties.created.value : "-"}</p>
            <span className={cl.textDate}>by </span>
            <p>
              {properties.created.name ? properties.created.name : "-"}
            </p>
          </div>
          <div className={cl.wrapperText}>
            <p className={cl.AccordionDetailsTitle}>Last change:</p>
            <p>{properties.lastChange.value ? properties.lastChange.value : "-"}</p>
            <span className={cl.textDate}>by </span>
            <p>
              {properties.lastChange.name ? properties.lastChange.name : "-"}
            </p>
          </div>
          <div className={cl.wrapperText}>
            <p className={cl.AccordionDetailsTitle}>Approved</p>
            <p>{properties.approved.value ? properties.approved.value : "-"}</p>
            <span className={cl.textDate}>by </span>
            <p>
              {properties.approved.name ? properties.approved.name : "-"}
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ExpanderElementProperties;
