import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Structure from "./Structure";
import MettaData from "@zert-packages/components/InfoElement/mettaData";
import { LayoutContext } from "../LayoutView";
import PartOf from "./PartOf";


const useStyles = makeStyles((theme) => ({
  rootLeftExpanded: {
    background: "#FFFFFD",
    marginBottom: 15,
    borderRadius: 5,
    boxShadow: "5px 5px 5px rgb(0 0 0 / 10%)",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-elevation1": {
      boxShadow: "none"
    }
  }
}));

const LeftExpanded = () => {
  const cl = useStyles();
  const [expandedPanel, setExpandedPanel] = useState({
    expandedPanelOne: true,
    expandedPanelTwo: true,
    expandedPanelThree: true
  });
  return (
    <div className={cl.rootLeftExpanded}>
      <Structure
        expanded={expandedPanel}
        setExpanded={setExpandedPanel}
      />
      <PartOf
        expanded={expandedPanel}
        setExpanded={setExpandedPanel}
      />
      <MettaData
        expanded={expandedPanel}
        setExpanded={setExpandedPanel}
        context={LayoutContext}
      />
    </div>

  );
};
export default LeftExpanded;
