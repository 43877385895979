import React, { useState, useContext, Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import MettaData from "./mettaData";
import ExpanderElementProperties from "./ExpanderElementProperties";
import ExpanderLanguageFilters from "./LanguageFilters/ExpanderLanguageFilters";
import { PanelContext } from "./InfoElementWrapper/InfoElementWrapper";
import MultipleItems from "./MultipleItems";

const useStyles = makeStyles((theme) => ({
  rootRightExpanded: {
    // maxHeight: 'Calc(100vh - 97px)',
    background: "#FFFFFD",
    marginBottom: 15,
    borderRadius: 5,
    marginTop: 20,
    boxShadow: "5px 5px 5px rgb(0 0 0 / 10%)",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    "& .MuiPaper-elevation1": {
      boxShadow: "none"
    }
  }
}));

function RightExpanded({ context, style={} }) {
  const cl = useStyles();
  const { values } = useContext(context ? context : PanelContext);


  const [expandedPane, setExpandedPane] = useState({
    expandedPanelOne: false,
    expandedPanelTwo: true,
    expandedPanelThree: false
  });


  return (
    <div className={cl.rootRightExpanded} style={style}>
      {values.severalSelectedTreeItem?.length > 1
        ? <MultipleItems />
        : <>
          <ExpanderElementProperties
            context={context}
            expanded={expandedPane}
            setExpanded={setExpandedPane}
          />
          <ExpanderLanguageFilters
            expanded={expandedPane}
            setExpanded={setExpandedPane}
          />
          <MettaData
            expanded={expandedPane}
            setExpanded={setExpandedPane}
          />
        </>}
    </div>
  );

}

export default RightExpanded;



