import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, NavLink, Route, useHistory } from 'react-router-dom';
import { loadCompaniesList, STORE_COMPANY } from '@zert-packages/actions/coreReducers';
import { getUser } from '@zert-packages/actions/actionplan';

import SvgIcon from '@zert-packages/icons/SvgIcon/SvgIcon';
import { ICONS } from '@zert-packages/icons';
import UserList from '@zert-packages/plugins/AdministrationPlugin/components/User/UserList';
import CompanyList from '@zert-packages/plugins/AdministrationPlugin/components/Company/CompanyList';
import { API_ROOT_WAR } from '@zert-packages/actions/api';
import CompanyEditor from '@zert-packages/plugins/AdministrationPlugin/components/Company/CompanyView';
import UserEditor from '@zert-packages/plugins/AdministrationPlugin/components/User/UserEditor';
import MailSettingsPage from '@zert-packages/plugins/MailSettingsPlugin/MailSettingsPage';
import Loader from '@zert-packages/components/shared/Loader/Loader';
import PersonalSettingsView from '@zert-packages/plugins/AdministrationPlugin/components/PersonalSettingsView';
import './settings.css';
import './settingsList.css';
import { FormattedMessage, injectIntl } from 'react-intl';

function SettingsNavigationItem({ children, nameOfPath, icon }) {
  const content = React.Children.toArray(children);
  return (
    <NavLink exact activeClassName="current" to={nameOfPath} className="outer-group settingtype">
      <li>
        <div className="item-group">
          <div className="icon-box">
            <SvgIcon icon={icon} />
          </div>
          {content[0]}
        </div>
      </li>
    </NavLink>
  );
}

function SettingsView({ intl, match: { params } }) {
  const { permits, myuser: myUser } = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();

  const [isToggleOn, setIsToggleOn] = useState(true);
  const [isShowOn, setIsShowOn] = useState(false);
  const [searchSetting, setSearchSettings] = useState('');

  useEffect(() => {
    dispatch(getUser(false));
    if (myUser && myUser.role === 'administrator') {
      //  dispatch(getSettings());
    }
    dispatch(loadCompaniesList(true));
  }, []);

  const toggleState = () => setIsToggleOn((prev) => !prev);
  const toggleShow = () => setIsShowOn((prev) => !prev);

  const companyId = getParameterAsInt(params.companyid, -100);
  let userId = -1;
  if (params.userid) {
    userId = getParameterAsInt(params.userid, -100);
  }
  if (!myUser) {
    return <Loader />;
  }

  return (
    <div className="centerCage">
      <div className={isToggleOn ? 'side-bar expand side-bar-settings' : 'side-bar side-bar-settings'}>
        <div className="outer-cage2">
          <div className="head-part sidebar-item">
            {isToggleOn && <FormattedMessage tagName="h4" id="settings.zert.panel" defaultMessage="Category" />}
            <button onClick={toggleState} className="toggle-btn">
              <SvgIcon icon={ICONS.CHEVRON_CIRCLE_V} fill="#AFBDC8" />
            </button>
          </div>
          <div className="bottom-part">
            <ul className="list">
              <SettingsNavigationItem icon={ICONS.SETTING} nameOfPath={`${API_ROOT_WAR}/settings/personal`}>
                <FormattedMessage tagName="span" id="settings.menu.personal" defaultMessage="Personal" />
              </SettingsNavigationItem>
              {(myUser.role === 'administrator' ||
                (permits['zert-administration/configure'] && permits['zert-administration/configure'] === 'true')) && (
                <SettingsNavigationItem icon={ICONS.USER} nameOfPath={`${API_ROOT_WAR}/settings/userlist/${companyId}`}>
                  <FormattedMessage tagName="span" id="settings.menu.user" defaultMessage="User list" />
                </SettingsNavigationItem>
              )}

              {myUser.role === 'administrator' && (
                <SettingsNavigationItem icon={ICONS.WRENCH} nameOfPath={`${API_ROOT_WAR}/settings/companylist`}>
                  <FormattedMessage tagName="span" id="settings.menu.companyList" defaultMessage="Companies" />
                </SettingsNavigationItem>
              )}

              <SettingsNavigationItem icon={ICONS.EMAIL} nameOfPath={`${API_ROOT_WAR}/settings/maillist`}>
                <FormattedMessage tagName="span" id="settings.menu.mail.settings" defaultMessage="Mail settings" />
              </SettingsNavigationItem>
            </ul>
          </div>
        </div>
      </div>

      <div onClick={toggleShow} className={isShowOn ? 'block-content-right' : 'block-content'} />

      <div className="tableCage">
        <Switch>
          <Route exact path={`${API_ROOT_WAR}/settings/personal`} component={PersonalSettingsView} />

          {myUser.role === 'administrator' && (
            <Route exact path={`${API_ROOT_WAR}/settings/companyeditor/:companyid`}>
              <CompanyEditor
                companyId={companyId}
                intl={intl}
                searchTerm={searchSetting}
                confirmCompany={(e) => {
                  dispatch(STORE_COMPANY());
                  dispatch(loadCompaniesList(true));
                  history.push(`${API_ROOT_WAR}/settings/companylist`);
                }}
              />
            </Route>
          )}

          {(myUser.role === 'administrator' ||
            myUser.role === 'task-administrator' ||
            (permits['zert-administration/configure'] && permits['zert-administration/configure'] === 'true')) && (
            <Route exact path={`${API_ROOT_WAR}/settings/usereditor/:companyid/:userid`}>
              <UserEditor
                companyid={companyId}
                userid={userId}
                searchTerm={searchSetting}
                confirmUser={() => history.push(`${API_ROOT_WAR}/settings/userlist/${companyId}`)}
              />
            </Route>
          )}

          <Route exact path={`${API_ROOT_WAR}/settings/maillist`}>
            <MailSettingsPage user={myUser} />
          </Route>

          {(myUser.role === 'administrator' ||
            (permits['zert-administration/configure'] && permits['zert-administration/configure'] === 'true')) && (
            <Route exact path={`${API_ROOT_WAR}/settings/userlist/:companyid`}>
              <UserList companyid={companyId} searchTerm={searchSetting} />
            </Route>
          )}
          {myUser.role === 'administrator' && (
            <Route exact path={`${API_ROOT_WAR}/settings/companylist`}>
              <CompanyList searchTerm={searchSetting} />
            </Route>
          )}
        </Switch>
      </div>
    </div>
  );
}
export default injectIntl(SettingsView);
