import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog} from "@mui/material";
import {addNewTranslations, getActiveLocalesWithSort, getTranslations} from "../../BlockPlugin/API";
import Autocomplete from "@mui/material/Autocomplete";
import {DeleteSharp} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {FormattedMessage} from "react-intl";
import AddButtonComp from "./AddButtonComp";
import AddIcon from "@mui/icons-material/Add";
import {createInProgressSnack} from "@zert-packages/components/common/snackBar";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 262,
    padding: 5,
    paddingBottom: 10,
    background: "#E2F2F6",
    minHeight: 168,
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "flex-end",
    "& svg": {
      cursor: "pointer"
    }
  },
  wrapper: {

  },
  wrapperList: {
    marginTop: 5,
    border: '1px solid lightgray',
    minHeight: 100,
    padding: 5,
  },
  togetherTitle: {
    fontWeight: 500,
    margin: '5px 0px 5px 2px',
  },
  listItem: {
    cursor: "pointer",
    '&:hover':{
      backgroundColor: '#CECECE',
    }
  },
}));

const AddLanguageDialog = ({langList, versionId, handlePhrase, languages}) => {
  const cl = useStyles();
  const [open, setOpen] = useState(true);

  const [lang, setLang] = React.useState(null);
  const[filteredList, setFilteredList] = useState(null);
  const snackbar = useSnackbar();

  const handleChange = (val) => {
    setLang(val)
  };


  useEffect(() =>{
    getActiveLocalesWithSort().then(res =>{
      if(res && langList){
        const temp  = res.reduce((ac,cur) =>{
          if(langList.some(el => el.language === cur.language)){
            return ac
          }else{
            return [...ac, cur]
          }
        },[])

        setFilteredList(temp)
      }
    })
  },[])

  const handleClose = () => {
    setOpen(false);
  };


  const handleCreateNewTranslation = async () =>{
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.Loading" defaultMessage="Loading..." />
    );
    if(versionId && lang) {
      const res = await addNewTranslations( versionId, `${lang.language}_${lang.country}`)
      if(res){
        if(languages) handlePhrase([...languages, lang], 'languages');
        else handlePhrase(lang);
        handleClose()
      }
    }
    snackbar.closeSnackbar(generatingSnackbar)
  }

  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapper}>
      <div className={cl.wrapperTitle}>
        <CloseIcon onClick={handleClose} />
      </div>
      <div className={cl.wrapperList}>
        <div className={cl.wrapperTitle}>
          <FormattedMessage id="CLM.AddLanguageDialog.AddTranslation" defaultMessage="Add new translation"/>
        </div>
        {filteredList?.length>0 ?
        <Autocomplete
            // key={filteredPunkts}
            className={cl.autoComplete}
            id="size-small-standard-multi_1"
            size="small"
            value={lang}
            clearIcon={<DeleteSharp/>}
            options={filteredList}
            getOptionLabel={(option) => option.displayName}
            onChange={(event, value) => handleChange(value)}
            renderInput={(params) =>
                <TextField {...params}
                           label={<span className={cl.label}>Languages</span>}
                           variant="outlined"
                           size='small'/>}
        /> :
            <div>
              <FormattedMessage id="CLM.AddLanguageDialog.languagesUsed" defaultMessage="All languages used"/>
            </div>
        }
      </div>

      <div>
        {filteredList?.length > 0 &&
        <div style={{margin: "15px 0 0"}}>
          <AddButtonComp Icon={AddIcon} handle={handleCreateNewTranslation}
                         label={<FormattedMessage id="CLM.AddLanguageDialog.AddTranslation"
                                                  defaultMessage="Add translation"/>}/>
        </div>
        }
      </div>

    </div>
  </Dialog>);
};

export default AddLanguageDialog;