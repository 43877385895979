import {handlePostResultAsString, handlePostToApi, handleQuery} from '@zert-packages/actions/api';

export function getTermAPI(versionId, locale = 'en') {
  return handleQuery(`/softwareText/getAllTerms/${locale}/${versionId}`);
}
export function addTerm(termVersionId, termName, locale = 'en') {
  const data = {
    termName,
    locale
  };

  return handlePostToApi(`/termdb/addTerm/${termVersionId}`, data);
}
export function createExport(termVersionId) {
  return handlePostToApi(`/termdb/createExport/${termVersionId}`);
}
export function loadCurrentTerm(versionId, elementId, locale = 'en') {
  return handleQuery(`/softwareText/loadCurrentTerm/${locale}/${versionId}/${elementId}/-1`);
}
export function addTermSpec(versionId, ownerId, identifier, termName, locale = 'en') {
  return handlePostToApi(`/termdb/addTermSpec/${versionId}`, { identifier, ownerId, termName, locale });
}


export const initTermDb = (data={}) => {
  return handlePostToApi(`/termdb/initTermDb`, data)
}

//
// export const  addTermSpec =  async(versionId, data={}) => {
//   const res = await handlePostToApi(`/termdb/addTermSpec/${versionId}`, data)
//   return res
// }


export const storeTerm = (versionId, data={}) => {
  return handlePostToApi(`/termdb/storeTerm/${versionId}`, data)
}




export function addTermImage(versionId, ownerId, identifier, imageElementId, locale = 'en') {
  return handlePostToApi(`/termdb/addTermImage/${versionId}`, {
    identifier,
    ownerId,
    versionId: imageElementId,
    locale
  });
}

export function removeTermSpec(versionId, ownerId, elementId, locale = 'en') {
  return handlePostToApi(`/termdb/removeTermSpec/${versionId}`, { elementId, ownerId, locale });
}
export function loadTerms(versionId, phrasesListId) {
  return handlePostToApi(`/termdb/loadTerms/${versionId}`, { phrasesListId });
}
export function removeTerm(versionId, ownerId) {
  return handlePostToApi(`/termdb/removeTerm/${versionId}`, { ownerId });
}
export function exportTermDB(versionId, localesList, reportUUID, format, availiableMetas = {}) {
  return handlePostToApi(`/termdb/export/${versionId}/${reportUUID}`, { localesList, availiableMetas, format });
}
export function getReferences() {
  return handlePostToApi(`/termdb/getReferences`);
}

export function getForcedMetaData(versionId) {
  return handleQuery(`/softwareText/getForcedMetaData/${versionId}`);
}

export function getOptionalMetaData(versionId) {
  return handleQuery(`/softwareText/getOptionalMetaData/${versionId}`);
}

export function exportSoftwareText(versionId, locale, fd ) {
  return handlePostToApi(`/softwareText/exportSoftwareText/${versionId}?sourceLocale=${locale}`, fd);
}



export function loadSoftwareText(versionId=632941, locale='en') {
  return handleQuery(`/softwareText/load/${versionId}/${locale}`);
}

export function deleteSoftWareText(id, parentId) {
  return handleQuery(`/softwareText/removeTerm/${id}/${parentId}`);
}

export function removeSoftWareTerm(versionId, ownerId) {
  return handlePostToApi(`/softwareText/removeTerm/${versionId}`, { ownerId });
}

// export const createPhraseApi = (data) => {
//   return handlePostResultAsString(`/phrase/init`, data)
// }
