import React, { useState, Fragment, useRef, useCallback, memo } from 'react';
import { TextField, FormControl, Popper, Input, Grid, Avatar, Fade, CircularProgress, Paper } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import cn from 'classnames';
import { getUsersQuery } from '@zert-packages/actions/actionplan';

function Label() {
  return <FormattedMessage id="measure.responsible" defaultMessage="Responsible" />;
}
const getUserId = (user) => user.userName;
const getUserLabel = (user) => {
  if (!user) {
    return null;
  }

  return user.name;
};
const getUsersName = (user) => (user != null ? user.name : '');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridGap: 5,
    left: '0px',
    fontSize: '0.7rem'
  },
  fieldsContainer: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',
    gridTemplateColumns: ' 1fr',
    alignItems: 'flex-end'
  },
  fieldsContainerAutocompleteOnly: {
    ridTemplateRows: '1fr'
  },
  color: {
    color: '#ABC1C7'
  },
  UserOptionLabel: {
    height: '100%',
    fontSize: '1rem',
    color: '#666',
    display: 'flex',
    alignItems: 'center',
    transition: 'color .5s ease'
  },
  Through: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.7rem',
    justifyContent: 'left',
    color: '#ABC1C7',
    letterSpacing: '1.5px'
  },
  Avatar: {
    width: '20px',
    height: '20px',
    fontSize: '1rem'
  },
  avatar: {
    width: '25px',
    height: '25px'
  },
  option: {
    cursor: 'pointer',
    padding: ' 0 10px',
    marginTop: '2px',
    zIndex: '2000',
    transition: 'color .5 ease',
    '& :hover': {
      color: theme.palette.primary.main
    }
  },
  optionGrid: {
    wordBreak: 'break-word',
    flexWrap: 'nowrap'
  },
  selectedOption: {
    color: theme.palette.primary.main
  },
  inputLabel: {
    order: '-1',
    paddingTop: '5px',
    transition: 'all 0.1s ease-in',
    transform: 'translateY(20px)',
    pointerEvents: 'none'
  },
  inputFocused: {
    transform: 'translateY(-2px) scale(0.8)'
  },
  inputFilled: {
    transform: 'translateY(-2px) scale(0.8)'
  }
}));

let amountOfUsers = 0;
let searchValue = null;

function MeasureResponsibleInput({
  readonly,
  responsible: initialResponsible = null,
  responsibleText: _initialResponsibleText = null,
  symbolsToStart = 3,
  onChange
}) {
  const initialResponsibleText = useRef(_initialResponsibleText);

  const showingOnlyAutocomplete = (initialResponsibleText.current || '').trim().length === 0;
  const cl = useStyles();
  const inputRef = useRef(null);
  const [value, setValue] = useState(initialResponsible != null ? initialResponsible.userName : null);
  const [users, setUsers] = useState([]);
  const [selectedUserIndex, setSelectedUserIndex] = useState(-1);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [state, setState] = useState({
    responsible: initialResponsible,
    responsibleText: _initialResponsibleText
  });

  const changeState = ({ responsibleText, responsible }) =>
    setState((prev) => {
      if (responsibleText && _.get(prev, 'responsible.userName') == responsibleText) {
        return prev;
      }
      const newState = {
        responsibleText:
          typeof responsibleText !== 'undefined'
            ? responsible != null && showingOnlyAutocomplete
              ? null
              : responsibleText
            : prev.responsibleText,
        responsible: typeof responsible !== 'undefined' ? responsible : prev.responsible
      };
      return newState;
    });

  const handleOpenOptions = (event) => {
    setAnchorEl(inputRef.current);
    setIsOpen(true);
  };

  const handleCloseOptions = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const [isLoading, setIsLoading] = useState(false);
  const setTextUser = useCallback(
    _.debounce((currentValue) => {
      if (!isLoading && !isOpen && state.responsible == null) {
        changeState({ responsible: null, responsibleText: currentValue });
      }
    }, 1000),
    []
  );

  const fetchUsers = useCallback(
    _.debounce((currentValue) => {
      searchValue = currentValue;
      if (currentValue.length >= symbolsToStart) {
        setIsLoading(true);

        getUsersQuery(currentValue).then(setUsersList(currentValue));
      } else if (users.length) {
        setUsers([]);
      }
    }, 500),
    []
  );

  const setUsersList = (currentValue) => (newUsers) => {
    if (currentValue == searchValue) {
      setUsers(newUsers);
      amountOfUsers = newUsers.length;
      if (amountOfUsers > 0) {
        handleOpenOptions();
        setSelectedUserIndex(0);
      }
      setIsLoading(false);
    }
  };

  const handleChange = async (event) => {
    const currentValue = event.target.value;
    if (currentValue.length === 0) {
      onChange({ responsibleText: state.responsibleText, responsible: null });
      setValue(currentValue);
      return;
    }
    fetchUsers(currentValue);
    setValue(currentValue);
    if (showingOnlyAutocomplete) {
      setTextUser(currentValue);
      const newState = {
        responsibleText: currentValue,
        responsible: state.responsible
      };
      onChange(newState);
    }
  };

  const onSelect = (user) => {
    setUsers([]);
    amountOfUsers = 0;
    setSelectedUserIndex(-1);
    setValue(getUserLabel(user));
    const newState = {
      responsibleText: showingOnlyAutocomplete ? null : state.responsibleText,
      responsible: user
    };
    onChange(newState);
    changeState({ responsibleText: state.responsibleText, responsible: user });
    handleCloseOptions();
  };
  const onKeyDown = (e) => {
    const keyUp = 38;
    const keyDown = 40;
    const backSpace = 8;
    if (e.key === 'Escape') return handleCloseOptions();
    if (e.key === 'Enter' && selectedUserIndex !== -1) return onSelect(users[selectedUserIndex]);
    if (e.keyCode === keyUp && selectedUserIndex > 0) {
      return setSelectedUserIndex((prev) => prev - 1);
    }
    if (e.keyCode === keyDown && selectedUserIndex < amountOfUsers - 1) {
      return setSelectedUserIndex((prev) => prev + 1);
    }
    if (e.keyCode === backSpace && selectedUserIndex === -1 && amountOfUsers === 0) {
      if (state.responsible != null) {
        onSelect(null);
      }
    }
  };
  const handleOnBlur = () => setTimeout(() => handleCloseOptions(), 150);

  const onChangeResponsibleText = (value) => {
    const newState = {
      responsibleText: value,
      responsible: state.responsible
    };
    onChange(newState);
    changeState({
      responsibleText: value
    });
  };
  return (
    <div className={cl.root}>
      <div className={cl.color}>
        <Label />
      </div>
      <div
        className={cn({
          [cl.fieldsContainer]: true,
          [cl.fieldsContainerAutocompleteOnly]: showingOnlyAutocomplete
        })}
      >
        {showingOnlyAutocomplete || (
          <>
            <TextField
              size="small"
              fullWidth
              variant="standard"
              onChange={(e) => onChangeResponsibleText(e.target.value)}
              value={state.responsibleText || ''}
              disabled={readonly}
            />
            <Through />
          </>
        )}
        <FormControl disabled={readonly} fullWidth onBlur={handleOnBlur}>
          {state.responsible && state.responsible.guid ? (
            <Avatar
              className={cl.avatar}
              src={`/zert/admin/getUserPhoto/${state.responsible.tenantId}/${state.responsible.guid}`}
            >
              {getUserLabel(state.responsible)[0]}
            </Avatar>
          ) : (
            <></>
          )}
          <Input
            autoFocus={false}
            onFocus={handleOpenOptions}
            ref={inputRef}
            size="small"
            value={value || ''}
            onKeyDown={onKeyDown}
            onChange={handleChange}
            endAdornment={
              isLoading ? <CircularProgress style={{ marginRight: '0.5rem' }} color="inherit" size={20} /> : null
            }
          />
          <OptionsContainer canShow={isOpen && !isLoading && !!users.length} anchorEl={anchorEl}>
            {users.map((user, index) => (
              <Option
                key={getUserId(user)}
                user={user}
                selected={selectedUserIndex === index}
                onSelect={onSelect}
                cl={cl}
                getUserLabel={getUserLabel}
              />
            ))}
          </OptionsContainer>
        </FormControl>
      </div>
    </div>
  );
}

export default MeasureResponsibleInput;

function OptionsContainer({ anchorEl, children, canShow }) {
  return (
    <Popper open={canShow} anchorEl={anchorEl} style={{ zIndex: 2000 }}>
      <Fade in={canShow}>
        <Paper
          style={{
            width: anchorEl ? anchorEl.clientWidth : undefined,
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '300px',
            padding: '10px',
            zIndex: 2000
          }}
        >
          {children}
        </Paper>
      </Fade>
    </Popper>
  );
}

function Option({ user, getUserLabel, onSelect, cl, selected }) {
  return (
    <div className={cl.option}>
      <Grid className={cl.optionGrid} container spacing={1} onClick={() => onSelect(user)}>
        <Grid item>
          {user.guid ? (
            <Avatar className={cl.avatar} sizes="20px" src={`/zert/admin/getUserPhoto/${user.tenantId}/${user.guid}`}>
              {getUserLabel(user)[0]}
            </Avatar>
          ) : (
            <Avatar className={cl.avatar} sizes="20px">
              {getUserLabel(user)[0]}
            </Avatar>
          )}
        </Grid>
        <Grid className={cn({ [cl.selectedOption]: selected })} item xs>
          {getUserLabel(user)}
        </Grid>
      </Grid>
    </div>
  );
}

const UserOption = memo(({ userId, userLabel, usersName, onChange }) => {
  return (
    <Grid
      container
      spacing={1}
      key={userId}
      onClick={() => {
        onChange(userId);
      }}
    >
      <Grid item>
        <Avatar className={useStyles().Avatar}>{userLabel[0]}</Avatar>
      </Grid>
      <Grid item xs alignItems="center">
        <UserOptionLabel text={usersName} />
      </Grid>
    </Grid>
  );
});

const Through = memo(() => (
  <div className={useStyles().Through}>
    <FormattedMessage id="through" defaultMessage="through" />
  </div>
));
const UserOptionLabel = memo(({ text }) => <div className={useStyles().UserOptionLabel}>{text}</div>);
