import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import LoaderModal from '@zert-packages/components/shared/LoaderModal';
import React, {createContext, useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TabsWithVersionController from '@zert-packages/components/common/TabsWithVersionController';
import useVersionManager from '@zert-packages/utils/useVersionManager';
import {getVersionInfo} from '@zert-packages/actions/coreReducers';
import get from 'lodash/get';
import {fetchSTReferences, fetchTerms} from './servicesMiddlewares';
import {resetTermsAction, turnOffCLMLoader, turnOnCLMLoader} from '../TranslationJobPlugin/tjReducers';
import SoftwareTextsViewList from './SoftwareTextsViewList';
import {addTerm} from './API';
import {generatePreviewReport} from '../PreviewPlugin/servicesMiddlewares';
import {generateSoftwareText} from './serviceMiddleware';
import RightMenu from "./rightMenu/RightMenu";
import {useParams} from "react-router-dom";
import {getBlockApi, getTranslations} from "../BlockPlugin/API";
import BlockItems from "../BlockPlugin/BlockItems";
import SkeletonLoader from "@zert-packages/components/SkeletonLoader";
import LanguagesWrapper from "./components/LanguagesWrapper";
import AddButtonComp from "./components/AddButtonComp";
import AddIcon from '@mui/icons-material/Add';
import AddLanguageDialog from "./components/AddLanguageDialog";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import AddTempleteDialog from "./components/AddTempleteDialog";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {setHeaderScroll} from "@zert-packages/actions/commonReducer";


export const formStyle = (theme) =>
  createStyles({
    mainContainer: {
      width: '100%',
      height: '100%'
    },
    rootBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '5px'
    },
    bodyContainer: {
      padding: '8px',
      height: 'calc(100% - 48px)',
      width: '100%',
      minHeight: '300px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: 7,
      '@media (max-width: 900px)': {
        flexDirection: 'column'
      }
    },
    wrapperRightMenu: {
      width: '25%'
    },
    formControl: {
      margin: theme.spacing(1),
      width: '100%'
    }
  });
const useStyles = makeStyles(formStyle);


export const PhaseContext  = createContext([]);

let prevVal = '';
function SoftwareTextsView(props) {
  const cl = useStyles();
  const CLM = useSelector((state) => state.CLM);
  const isLoading = useSelector((state) => state.CLM.isLoading);
  const formatTemplate = useSelector((state) => state.formatTemplate);
  const {
    match: { params }
  } = props;
  const versionId = getParameterAsInt(params.versionId);
  const { mylocale} = useSelector((state) => state);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [terms, setTerms] = useState(null);
  const [addNew, setAddNew] = useState(null);
  const [versionManager, versionInfo] = useVersionManager();
  const routeVersionId = get(props, 'match.params.versionId', null);
  const [scroll, setScroll] = useState(0)
  const [selectedRange, setSelectedRange] = useState({
    selectedRange: [],
    lastSelectedIndex: null,
  })
  const [phase, setPhase] = useState({
    activeBlock: null,
    selectedPhases: null,
    activePhrase: null,
    treeItem: null,
    localesFilters: null,
    publishingFilters: null,
    searchValue: '',
    filteredStates: null,
    phraseContent: '',
    newPhrase: null,
    languages:null,
    refresh: false,
  })


  const handleAddNewTerm = async (e, value, id) => {
    e.stopPropagation();
    e.preventDefault();

    if (value && value.length > 0) {
      dispatch(turnOnCLMLoader());
      try {
        const term = await addTerm(versionId, value);
        setAddNew(term.right);
        setTerms((previos) => {
          return [...previos, term.right].sort((a, b) => a.name.localeCompare(b.name));
        });
        //  dispatch(turnOffCLMLoader());
      } catch (e) {}
    } else if (id) {
      try {
        setTerms((previos) => {
          return previos.filter((item) => item.id != id).sort((a, b) => a.name.localeCompare(b.name));
        });
        dispatch(turnOffCLMLoader());
      } catch (e) {}
    }
  };

  const handlePhrase = (val, name, values) => {


    if(values){
      return  setPhase(pre => ({...pre, ...values}))
    }
    setPhase(pre => ({...pre, [name]: val}))
  }

  const handleScrollToItem = (e, item) => {
    if (e != null) {
      e.stopPropagation();
      e.preventDefault();
    }


    handlePhrase(null, 'languages');
    handlePhrase(item, 'treeItem', { 'treeItem': item, newPhrase: null,})
  };

  const getBlock = async (versionId) => {


    if(versionId) {

      const res = await getBlockApi(versionId, mylocale).catch(e => console.log(e));

      if (res) {
        handlePhrase(res, 'selectedPhases');
        handlePhrase(res, 'activeBlock');

        const source = res?.items.find(el =>  el.format.identifier === "TermDBSource")

       const langs =  await getTranslations(source?.elementInfo?.versionId)

        if(langs ){
          handlePhrase(langs, 'languages')
        }
      }
    }
  }


  useEffect(() => {
    if(terms){
      handlePhrase(terms, 'filteredStates')
    }
  }, [terms])

  useEffect(() => {

    if (routeVersionId === null) return;
    dispatch(resetTermsAction());
    setTerms(null);

    dispatch(getVersionInfo(routeVersionId));

    dispatch(turnOnCLMLoader());

    dispatch(fetchTerms(versionId));
    dispatch(fetchSTReferences(versionId));
  }, [routeVersionId, versionId, phase.refresh]);

  useEffect(() => {
    if (CLM && CLM.termDB && !terms) {
      const arr = Object.keys(CLM.termDB)
        .map((item) => CLM.termDB[item])
        .sort((a, b) => a.name.localeCompare(b.name));

      setTerms(arr);
      if (arr && arr.length > 0) {
        handlePhrase(arr[0], 'treeItem')
      }
    } else if (CLM && CLM.termDB === null && terms) {
      setTerms(null);
    }
  }, [terms, CLM]);

  useEffect(() => {
    if (addNew) {
      handlePhrase(addNew, 'treeItem')
      setAddNew(null);
      dispatch(turnOffCLMLoader());
    }
  }, [addNew]);

  useEffect(() => {
    if(phase.treeItem ){

      getBlock(phase.treeItem.versionId)
    }
  }, [phase.treeItem])



  const handleAddTranslation = async() =>{

    const source = phase?.selectedPhases?.items.find(el =>  el.format.identifier === "TermDBSource")


    const lang = await getTranslations(source?.elementInfo?.versionId)
    renderInExceptionRoot(AddLanguageDialog, {
      versionId:source?.elementInfo?.versionId,
      langList: lang,
      languages: phase.languages,
      handlePhrase: handlePhrase
    })
  }

  const handleAddTemplate = async() =>{
    const list = formatTemplate?.blockFormats?.find(el => el.identifier === 'TermDBBlock')


    const filteredList = formatTemplate?.paragraphFormats?.reduce((ac,cur) =>{
      if(list?.references?.some(el => el.identifier === cur.identifier )){
        return [...ac, cur]
      }else{
        return ac
      }
    },[])

    renderInExceptionRoot(AddTempleteDialog, {
      versionId:phase?.selectedPhases?.elementInfo?.id,
      termList: filteredList.filter(el => el.identifier !== "TermDBSource"),
      versionInfo: versionInfo,
      refresh: phase.refresh,
      rootVersionId: phase?.selectedPhases?.elementInfo?.versionId,
      handlePhrase: getBlock
    })
  }

  const handleGroupArr = (arr) =>{

    return arr.reduce((ac,cur) =>{
      if(ac.some(el => el?.title === cur.format?.identifier)){

        const tempAc2 = ac.map(it => ({...it,elements:  it?.title === cur.format?.identifier ?
              [...it.elements, cur] : [...it.elements] }))
        return [...tempAc2]
      }else{
        const newItem = {title:cur.format?.identifier, label: cur.format?.label?.defaultLabel,  elements: [cur] }
        return [...ac, newItem]
      }
    },[]);
  }


  const handleScroll = (e) => {
    // const currentScrollTop = e.target.scrollTop
    //
    // const scrollDirection = currentScrollTop > scroll ? 'down' : 'up';
    //
    // setScroll(currentScrollTop);
    //
    // if(scrollDirection !== prevVal){
    //   prevVal = scrollDirection
    //   dispatch(setHeaderScroll(scrollDirection === "down"))
    // }
  }




  return (
    <PhaseContext.Provider value={{ values: phase, setValues: setPhase }}>
      <div className={cl.mainContainer}
           style={{
             // transition: scrollHeader ? 0 :  'opacity 0.5s ease 0.5s',
             transition: "1s",
             minHeight: "100vh",
             // minHeight: scroll !== 0 ? '100vh' : `Calc(100vh - 49px)`,
             marginTop: scroll !== 0 ? 0 : 49,
           }}>

        {/*<TabsWithVersionController*/}
        {/*  canCreateVersion={versionManager.canCreateNewVersion}*/}
        {/*  canSaveVersion={false} // versionManager.canSaveVersion}*/}
        {/*  canApproveVersion={versionManager.canApproveVersion}*/}
        {/*  onCreateNewVersion={versionManager.createNewVersion}*/}
        {/*  onApproveVersion={versionManager.approveVersion}*/}
        {/*  onSaveVersion={() => false}*/}
        {/*  canPreview*/}
        {/*  onPreview={() => {*/}
        {/*    dispatch(generatePreviewReport(versionInfo.info, -1));*/}
        {/*  }}*/}
        {/*  canExport*/}
        {/*  onExport={() => {*/}
        {/*     // dispatch(generateTermDBExport(versionId));*/}
        {/*      dispatch(generateSoftwareText(versionId));*/}
        {/*  }}*/}
        {/*  tabs={[]}*/}
        {/*  setPhase={setPhase}*/}
        {/*/>*/}

        <div className={cl.bodyContainer}>
          <SoftwareTextsViewList
            termsList={phase.filteredStates}
            terms={terms}
            setTerms={setTerms}
            handlePhrase={handlePhrase}
            handleAddNewTerm={handleAddNewTerm}
            handleScrollToItem={handleScrollToItem}
            versionId={versionId}
            versionManager={versionManager}
            scrollToItem={phase.treeItem?.id}
            setPhase={setPhase}
            phase={phase}
            params={params}
          />

          <div style={{width: '50%',paddingBottom: "50px", overflowY: "auto"}}
               onScroll={handleScroll}
          >
            <div style={{fontSize: "20px"}}>
              <FormattedMessage id="CLM.SoftwareTextsView.ActivePhrase" defaultMessage="Source phrase" />
            </div>
            {phase.newPhrase &&
            <BlockItems
                context={PhaseContext}
                parent={phase.newPhrase}
                // el={phase.newPhrase}
                el={{...phase.newPhrase,softWareText:true}}
                dropBlockTable={false}
                investmentLevel={0}
                selected={true}
                newFraze={true}
                setSelectedRange={setSelectedRange}
            />
            }


            {!phase?.selectedPhases?.items.length > 0  && !phase.newPhrase  && <SkeletonLoader height={38} />}
            {phase?.selectedPhases?.items.length > 0 && phase.selectedPhases.items.filter(el => el?.format?.identifier === "TermDBSource")
                .map(it=>({...it,softWareText:true})).map((el, i) =>
              <div key={i} >
                <BlockItems
                  context={PhaseContext}
                  index={i}
                  parent={el}
                  el={el}
                  dropBlockTable={false}
                  investmentLevel={0}
                  selected={phase?.treeItem?.identifier === el?.identifier}
                  selectedRange={selectedRange}
                  setSelectedRange={setSelectedRange}
                  handlePhrase={handlePhrase}
                />
              </div>
            )}

            { phase?.languages?.length>1 &&
            <div style={{padding: "10px 20px"}}>
              <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                >
                  <FormattedMessage id="CLM.SoftwareTextsView.ActivePhrase" defaultMessage="Translations"/>
                </AccordionSummary>
                <AccordionDetails>


                  <div>
                    <LanguagesWrapper context={PhaseContext} el={phase?.selectedPhases?.items?.[0]}
                                      setSelectedRange={setSelectedRange} selectedRange={selectedRange}/>
                  </div>

                </AccordionDetails>
              </Accordion>
            </div>
            }

            {phase?.languages && !versionManager.elementDisabled &&
            <div style={{margin: "15px 0 0"}}>
              <AddButtonComp Icon={AddIcon} handle={handleAddTranslation}
                             label={<FormattedMessage id="CLM.SoftwareTextsView.AddTranslation"
                                                      defaultMessage="Add translation"/>}/>
            </div>
            }

            {!versionManager.elementDisabled &&
            <div style={{margin: "15px 0 0"}}>
              <AddButtonComp Icon={AddIcon} handle={handleAddTemplate}
                             label={<FormattedMessage id="CLM.SoftwareTextsView.AddTemplate"
                                                      defaultMessage="Add template"/>}/>
            </div>
            }

            { phase?.selectedPhases?.items.filter(el => el?.format?.identifier !== "TermDBSource").length > 0 &&
            <div style={{padding: "10px 20px"}}>
              <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                >
                  <FormattedMessage id="CLM.SoftwareTextsView.Definitions" defaultMessage="Definitions" />
                </AccordionSummary>
                <AccordionDetails>


                  <div>
                    {phase?.selectedPhases?.items.length > 0 &&
                    handleGroupArr(phase.selectedPhases.items.filter(el => el?.format?.identifier !== "TermDBSource"))
                        .map((el) =>
                            <div key={el.title} >
                              <div style={{margin: "10px 0 0"}}>
                                {el.label}
                              </div>
                              {el?.elements.map(it=>({...it,softWareText:true})).map((item, i) =>(
                                  <div key={i}>
                                    <BlockItems
                                        context={PhaseContext}
                                        index={i}
                                        parent={item}
                                        el={item}
                                        dropBlockTable={false}
                                        investmentLevel={0}
                                        selected={phase?.treeItem?.identifier === item?.identifier}
                                        selectedRange={selectedRange}
                                        setSelectedRange={setSelectedRange}
                                        handlePhrase={handlePhrase}
                                    />
                                  </div>
                              ))}
                            </div>
                        )}
                  </div>

                </AccordionDetails>
              </Accordion>
            </div>
            }
          </div>
          <div className={cl.wrapperRightMenu}>
            <RightMenu context={PhaseContext} />
          </div>
        </div>
        <LoaderModal open={isLoading}>
          <FormattedMessage id="CLM.TranslationJOBView.Loading" defaultMessage="Loading..." />
        </LoaderModal>
      </div>
    </PhaseContext.Provider>
  );
}

export default injectIntl(SoftwareTextsView);

