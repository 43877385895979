import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    transition: "1s",
    // paddingTop: 49,
    // 1s width
    gridColumnGap: "20px",
    background: "#E2F2F6",
    // minHeight: '100vh',
    padding: "0px 10px",
    wordBreak: "break-word",
    "@media  (max-width: 900px)": {
      gridTemplateColumns: "1fr"
    },
    '& .MuiAccordionDetails-root': {
      padding: '7px 5px',
    },
    '& .MuiAccordionSummary-contentGutters': {
      margin: '15px 0px'
    }
  },
  wrapperContent: {
    // overflowX: "auto",
    //  height: 920,
    // paddingBottom: 289,
    // position: 'absolute',
    position: "relative",
    width: '100%',
    paddingBottom: 20,
    transition: "500ms",
    scrollBehavior: "smooth",
    overflowY: 'auto',
    zIndex: 10,
  },
  wrapperScroll: {
    position: 'absolute',
    width: '100%',
    paddingRight: 5,
    paddingBottom: 30,
  },
  wrapperIcon: {
    paddingTop: 5,
  },
  wrapperEditor: {
    background: "#FFFFFD",
    boxShadow: "5px 5px 5px rgb(0 0 0 / 10%)",
    display: "flex",
    transition: ".5s",
    marginTop: 20,
    borderRadius: '5px 5px 0px 0px',
    padding: 3,
  },
  containerEditor: {
    // border: '1px solid rgba(145, 145, 145, 0.25)',
    paddingTop: 3,
    borderRadius: 2,
    borderLeft: "6px solid rgba(119, 173, 72, 0.5)",
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    width: "100%",
    marginLeft: 7
  },
  //p.blue::selection {
  //   background: #a8d1ff;
  // }
  // p.blue::-moz-selection {
  //   background: #a8d1ff;
  // }
  wrapperMiddleBlock: {
    width: "100%",
    height: "100%",
    display: "grid",
    position: 'relative',
    gridTemplateColumns: "7px  1fr",
    // '& ::selection,div::selection': {
    //   background: '#ffffff !important',
    //   outline: 'none !important',
    //    border: 'none !important',
    // },
    // '& ::-moz-selection,div::-moz-selection,': {
    //   background: '#ffffff !importan',
    //   outline: 'none',
    //    border: 'none !important',
    // }
  },
  wrapperPopupTree: {
    color: "#919191",
    padding: "10px 5px",
    fontSize: 10,
    backgroundColor: "rgba(248, 248, 248, 1)",
    lineHeight: "11px",
    cursor: "pointer"
  },
  revert: {
    transform: 'rotate(90deg)',
    display: 'grid',
    marginTop: 50,
    gridTemplateColumns: '20px 20px 57px 100px',
  },
  iconTitle: {
    position: "relative",
    marginRight: 2,
    top: 2,
    color: "rgba(0, 0, 0, 0.87)"
  },
  containerEditorInfo: {
    marginLeft: 3
  },
  containerEditorText: {
    fontSize: 10,
    color: "#919191",
    lineHeight: "10px"
  },
  containerEditorInfoTitle: {
    display: "flex",
    alignItems: "end"
  },
  containerEditorNumber: {
    fontSize: 10,
    color: "#919191",
    lineHeight: "10px",
    margin: "0px 2px"
  },
  arrowLeft: {
    position: "relative",
    top: 15,
    fontSize: 24,
    transform: "rotate(90deg)",
    cursor: "pointer"
  },
  arrowRight: {
    position: "relative",
    top: 15,
    fontSize: 24,
    transform: "rotate(270deg)",
    cursor: "pointer"
  },
  wrapperArrowLeftExpanded: {
    position: "relative",
    width: 25,
    "& $arrowLeft": {
      transform: "rotate(270deg)"
    },
    "& $arrowRight": {
      transform: "rotate(90deg)"
    }
  },
  wrapperArrowText: {
    transform: "rotate(270deg)",
    position: "absolute",
    top: 62,
    right: -18
  },
  wrapperArrowTextRight: {
    transform: "rotate(90deg)",
    position: "absolute",
    top: 132,
    right: -88,
    width: 200
  },
  wrapperArrowLeft: {
    width: 15,
    "& $arrowLeft": {
      right: 4
    },
    "& $arrowRight": {
      right: 4
    }
  },
  dropTitle: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  TreeRootPopup: {
    minWidth: 317,
    minHeight: 100,
    marginTop: 7,
    padding: 5,
    border: "1px solid lightgray",
    boxShadow: "none",
    fontSize: 12,
    lineHeight: "12px",
    maxHeight: 400
  },
  wrapperFrazeImg: {
    height: 12
  },
  wrapperValuesName: {
    marginTop: 5,
    marginRight: 5,
  },
}));


export default useStyles