import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Route, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MediaQuery from 'react-responsive';
import CatalogInlineEditor from '@zert-packages/components/shared/CatalogPlacement/CatalogInlineEditor';
import VersionBox from '@zert-packages/components/shared/VersionBox';

import hasPermit from '@zert-packages/utils/hasPermit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import _ from 'lodash';
import { API_ROOT_WAR } from '@zert-packages/actions/api';
import { ROUTE_TYPES } from '@zert-packages/react/ROUTE_TYPES';
import { containsMyMeasure } from '@zert-packages/utils/containsMyMeasure';
import ElementSearcher from '../common/ElementSearcher/ElementSearcher';
import { useCTRL_F } from '../common/ElementSearcher/utils/hooks';
import useShowElementSearcher from '../common/ElementSearcher/utils/useShowElementSearcher';
import SelectField from '../shared/FormElements/SelectField';
import WebEditorHeader from "./components/WebEditorHeader";


const useStyles = makeStyles({
  backButton: {
    marginRight: 10,
    '@media only screen and (max-width: 1024px)': {
      display: 'none'
    }
  },
  inputLabel: {
    color: '#FFFFFF !important'
  },
  select: {
    color: '#FFFFFF',
    '& > svg': {
      fill: '#FFFFFF'
    }
  }
});

export function getRouteByType(props) {
  switch (props.route.type) {
    case ROUTE_TYPES.SIMPLE:
      return <RSimple key={props.index} {...props} />;
    case ROUTE_TYPES.WITH_BACK_AND_LINK_NEW:
      return <RWithBackAndLinkNew key={props.index} {...props} />;
    case ROUTE_TYPES.SOFTTEXT:
      return <WebEditorHeader key={props.index} {...props} />;
    case ROUTE_TYPES.ELEMENT:
      return <RElement key={props.index} {...props} />;
    case ROUTE_TYPES.NEWS:
      return <RNews key={props.index} {...props} />;
    case ROUTE_TYPES.NOTIFICATION:
      return <RNotification key={props.index} {...props} />;
    case ROUTE_TYPES.USERS_IN_COMPANY:
      return <RUsersInCompany key={props.index} {...props} />;
    case ROUTE_TYPES.USER_EDIT_COMPANY:
      return <RUserEditCompany key={props.index} {...props} />;
    case ROUTE_TYPES.COMPANY:
      return <RCompany key={props.index} {...props} />;
    case ROUTE_TYPES.WITH_BACK_AND_NEW_SIDE_BAR:
      return <RWithBackAndNew key={props.index} {...props} />;
    case ROUTE_TYPES.CATALOG:
      return <RCatalog key={props.index} {...props} />;
  }
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
  /* getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  } */
};
export function RElement({ route, plugin }) {
  const { versionInfo, permits, catalog, myuser, measure_riskrow, activeLocales } = useSelector((state) => state);
  const [openSearch, setOpenSearch] = useState(false);
  const isAllowedShowElementSearcher = useShowElementSearcher();
  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const classes = useStyles();

  const isSoftWare =  window.location.pathname.includes("softwaretexts")

  useEffect(() => {
    if (versionInfo != null && selectedLanguage) {
      setSelectedLanguage(versionInfo.info.locale);
    }
  }, [versionInfo]);
  const onChangeCriteria = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const options = event.target.value;
    setSelectedCriteria(options);
  };
  const dispatch = useDispatch();
  let itemContainerStyle = {};
  if (!plugin.mimeType == 'risk-row' && !plugin.mimeType == 'measure') {
    if (!hasPermit(plugin, permits)) {
      itemContainerStyle = {
        background: 'linear-gradient(to bottom, #a2a2a2 0%, #888888 100%)',
        backgroundColor: '#a2a2a2'
      };
    }
  } else if (measure_riskrow && !containsMyMeasure(myuser, measure_riskrow)) {
    itemContainerStyle = {
      background: 'linear-gradient(to bottom, #a2a2a2 0%, #888888 100%)',
      backgroundColor: '#a2a2a2'
    };
  }
  if (versionInfo && versionInfo.info.approvedAt) {
    itemContainerStyle = {
      background: 'linear-gradient(to bottom, #80b15b 0%, #78AE4F 100%)',
      backgroundColor: '#78AE4F'
    };
  }
  if (myuser && versionInfo && versionInfo.checkedOutBy && myuser.userName !== versionInfo.checkedOutBy) {
    itemContainerStyle = {
      background: 'linear-gradient(to bottom, #fb4444 0%, #e83535 100%)', // fb4444 - e83535 = 130F0F
      backgroundColor: '#E83535'
    };
  }
  if (versionInfo && versionInfo.info.archivedAt) {
    itemContainerStyle = {
      background: 'linear-gradient(to bottom, #C3BF1B 0%, #b0b00c 100%)',
      backgroundColor: '#b0b00c'
    };
  }

  const localeChanged = (state) => {
    setSelectedLanguage(state.value);
  };

  useCTRL_F(() => {
    if (!isAllowedShowElementSearcher) return;
    setOpenSearch(true);
  });
  function MethodInfo() {
    return versionInfo && versionInfo.templateName ? (
      <div className="HeadBar__MethodInfoContainer">
        <span>{route.methodInfoTitle}</span>
        <span>{versionInfo.templateName}</span>
      </div>
    ) : null;
  }

  return (
    <Route exact path={API_ROOT_WAR + route.path}>
      <div className="HeadBar__ItemContainer" style={itemContainerStyle}>
        {isAllowedShowElementSearcher && (
          <ElementSearcher openSearch={openSearch} setOpenSearch={setOpenSearch} backgroundStyle={itemContainerStyle}>
            <MethodInfo />
          </ElementSearcher>
        )}
        <h1 className="HeadBar__PageTitle" onClick={() => setOpenSearch(true)}>
          {getNameFromVersionInfo(versionInfo)}
        </h1>
        <MediaQuery query="(min-width: 769px)">
          {(match) =>
            match && versionInfo ? (
              <div className="HeadBar__CatalogInlineEditor">
                <CatalogInlineEditor catalog={catalog} versionId={versionInfo.info.versionId} styleName="light" />
              </div>
            ) : (
              <div className="breadcrumb-vaadin light" />
            )
          }
        </MediaQuery>

        {!openSearch && <MethodInfo />}
        {activeLocales && window.ISCLM && !isSoftWare &&(
          <SelectField
            values={activeLocales}
            onStateChanged={localeChanged}
            value={selectedLanguage}
            caption={<FormattedMessage id="HeadBar.Select.Locale" defaultMessage="Locale" />}
            valLabel="extendedDisplayName"
            classes={classes}
            fieldId="value"
            label="extendedDisplayName"
          />
        )}
        {versionInfo && <VersionBox key={versionInfo.info.versionId} />}
        <CloseButton />
      </div>
    </Route>
  );
}

export function RWithBackAndLinkNew({ route, plugin }) {
  const permits = useSelector((state) => state.permits);
  return (
    <Route exact path={API_ROOT_WAR + route.path}>
      <div className="HeadBar__ItemContainer">
        <h1 style={{ maxWidth: '100%' }} className="HeadBar__PageTitle">
          {route.title}
        </h1>
        {permits[plugin.newpermit] && permits[plugin.newpermit] === true && (
          <Button
            data-intro-id="HeadBar.NewButton"
            exact
            to={API_ROOT_WAR + route.onNewLink}
            style={{ color: '#FFFFFF' }}
            component={NavLink}
            startIcon={<MenuIcon color="inherit" />}
            size="large"
          >
            {route.onNewLabel}
          </Button>
        )}
      </div>
    </Route>
  );
}

export function RCatalog() {
  const catalog = useSelector((state) => state.catalogList);
  if (!catalog) return null;
  return catalog.map(
    (_catalog, index) =>
      _catalog && (
        <RWithBackAndNew
          key={index}
          route={{
            path: `/catalog${index}`,
            title: _catalog.name
          }}
        />
      )
  );
}

export function RWithBackAndNew({ route }) {
  return (
    <Route exact path={API_ROOT_WAR + route.path}>
      <div className="HeadBar__ItemContainer">
        <h1 className="HeadBar__PageTitle">{route.title}</h1>
        <div
          data-intro-id="HeadBar.NewButton"
          style={{
            width: '50px',
            height: '100%'
          }}
        />
      </div>
    </Route>
  );
}

export function RUsersInCompany({ route }) {
  const company = useSelector((state) => state.company);
  return (
    <RSimple
      route={{
        ...route,
        title: (
          <FormattedMessage
            id="headbar.title.userlist"
            defaultMessage="Users in company {company}"
            values={{ company: company ? company.name : '' }}
          />
        )
      }}
    />
  );
}

export function RUserEditCompany({ route }) {
  const company = useSelector((state) => state.company);
  return (
    <RSimple
      route={{
        ...route,
        title: (
          <FormattedMessage
            id="headbar.title.usereditor"
            defaultMessage="User to edit {company}"
            values={{ company: company ? company.name : '' }}
          />
        )
      }}
    />
  );
}

export function RCompany({ route }) {
  const company = useSelector((state) => state.company);
  return (
    <RSimple
      route={{
        ...route,
        title: (
          <FormattedMessage
            id="headbar.title.companyeditor"
            defaultMessage="Edit company {company}"
            values={{ company: company ? company.name : '' }}
          />
        )
      }}
    />
  );
}

export function RSimple({ route }) {
  return (
    <Route exact path={API_ROOT_WAR + route.path}>
      <div className="HeadBar__ItemContainer">
        <h1 className="HeadBar__PageTitle">{route.title}</h1>
      </div>
    </Route>
  );
}

export function RNews({ route }) {
  const news = useSelector((state) => state.news);
  return (
    <Route exact path={API_ROOT_WAR + route.path}>
      <div className="HeadBar__ItemContainer">
        <h1 className="HeadBar__PageTitle">{news && news.heading}</h1>
        <div className="breadcrumb-vaadin light" />
        <CloseButton />
      </div>
    </Route>
  );
}

export function RNotification({ route }) {
  const notification = useSelector((state) => state.notification);
  return (
    <Route exact path={API_ROOT_WAR + route.path}>
      <div className="HeadBar__ItemContainer">
        <h1 className="HeadBar__PageTitle">{notification && notification.title}</h1>
        <div className="breadcrumb-vaadin light" />
        <CloseButton />
      </div>
    </Route>
  );
}

export function CloseButton() {
  const history = useHistory();
  const handleClose = () => history.push(`${API_ROOT_WAR}/`);
  return (
    <Button
      style={{
        color: '#fff'
      }}
      endIcon={<CloseIcon color="inherit" />}
      onClick={handleClose}
    >
      <MediaQuery query="(min-width: 1024px)">
        <FormattedMessage id="headbar.closebutton" defaultMessage="Close" />
      </MediaQuery>
    </Button>
  );
}

export function BackButton() {
  const classes = useStyles();
  const history = useHistory();
  const handleBack = () => history.goBack();
  return (
    <IconButton size="small" className={classes.backButton} onClick={handleBack}>
      <ArrowBackIcon htmlColor="#fff" />
    </IconButton>
  );
}

export const getNameFromVersionInfo = (versionInfo) => {
  const name = _.get(versionInfo, 'info.name', null);
  switch (name) {
    case 'Current':
      return <FormattedMessage id="HeadBarRoutes.VersionInfoName.Current" defaultMessage="Current" />;

    default:
      return name;
  }
};
