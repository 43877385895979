import { FormattedMessage } from 'react-intl';
import React from 'react';

export const getPublishingTypeFormatMessage = (publishingType, intl) => {
  if (publishingType == 'PUBLIC') {
    return <FormattedMessage id="publishingType.public" defaultMessage="PUBLIC" />;
  }
  if (publishingType == 'PUBLIC WEB') {
    return <FormattedMessage id="publishingType.sparePartCatalog" defaultMessage="SPAREPART CATALOG" />;
  }
  if (publishingType == 'KOMATSU_ASSERT') {
    return <FormattedMessage id="publishingType.komatsuAssert" defaultMessage="KOMATSU_ASSERT" />;
  }
  return <FormattedMessage id="publishingType.stylesheet" defaultMessage="Stylesheet" />;
};

export const getPublishingTypeTranslation = (publishingType, intl) => {
  if (publishingType == 'PUBLIC') {
    return intl.formatMessage({ id: 'publishingType.public', defaultMessage: 'PUBLIC WEB' });
  }
  if (publishingType == 'WEB') {
    return intl.formatMessage({ id: 'publishingType.sparePartCatalog', defaultMessage: 'SPAREPART CATALOG' });
  }
  if (publishingType == 'KOMATSU_ASSERT') {
    return intl.formatMessage({ id: 'publishingType.komatsuAssert', defaultMessage: 'KOMATSU ASSERT' });
  }
  return intl.formatMessage({ id: 'publishingType.stylesheet', defaultMessage: 'Stylesheet' });
};
