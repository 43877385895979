import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import { exportTermDB } from 'clm/src/plugins/TermDBPlugin/API';
import { toLocaleString } from '@zert-packages/plugins/ExplorerPlugin/SearchConditions/helpers/getLocaleByLanguageCode';
import { checkReportStatus } from '@zert-packages/actions/checklistActions';

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  chipContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignContent: 'center',
    alignItems: 'center',
    gridGap: '1px'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
/*  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  }*/
};

function ExportTermDBDialog({ unmountComponent, onConfirm, onCancel }) {
  const activeLocales = useSelector((state) => state.activeLocales);
  const classes = useStyles();
  const handleBack = () => handleCancel();
  const [fileExtension, setFileExtension] = useState('xls');

  const handleCancel = () => {
    unmountComponent();
    onCancel();
  };
  const onChangeFileExtension = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const options = event.target.value;
    setFileExtension(options);
  };

  const [selectedCriteria, setSelectedCriteria] = useState([]);

  const onChangeCriteria = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const options = event.target.value;
    setSelectedCriteria(options);
  };

  const handleExport = async (e) => {
    e.persist();
    unmountComponent();
    onConfirm(selectedCriteria, fileExtension);
  };

  return (
    <Dialog open onClose={handleCancel} fullWidth>
      <DialogTitle>
        <FormattedMessage id="CancelConfirmDialog.title" defaultMessage="Export termDB dialog" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            id="CancelConfirmDialog.description"
            defaultMessage="Select languages and file format for exporting."
          />
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel>
            <FormattedMessage id="LocalesSelectDialog.ChooseLocale" defaultMessage="Locales" />
          </InputLabel>
          <Select
            multiple
            value={selectedCriteria}
            onChange={onChangeCriteria}
            input={<Input />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value.language}
                    label={
                      <div className={classes.chipContainer}>
                        <span> {value.displayName}</span>
                      </div>
                    }
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {activeLocales &&
              activeLocales.map((locale) => (
                <MenuItem key={locale.language} value={locale}>
                  {locale.displayName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{ marginTop: '16px' }}>
          <InputLabel htmlFor="file-extension-simple">File extension</InputLabel>
          <Select native value={fileExtension} onChange={onChangeFileExtension}>
            <option value="xls">.xls</option>
            <option value="tbx">.tbx</option>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleExport} color="primary" variant="contained" autoFocus>
          <FormattedMessage id="ExportTermDBDialog.buttonExport" defaultMessage="Export" />
        </Button>
        <Button onClick={handleBack} color="primary" variant="outlined" autoFocus>
          <FormattedMessage id="ExportTermDBDialog.buttonCancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExportTermDBDialog;
