import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  wrapperList: {
    display: "grid",
    gridTemplateColumns: "11px 1fr",
    gridColumnGap: 2
  },
  listNumber: {
    display: "flex",
    alignItems: "center",
    fontSize: 14
  },
  wrapperBulletList: {
    fontSize: 30,
    position: 'relative',
    overflow: 'hidden'
  },
  bulletIcon: {
    position: 'absolute',
    bottom: 5,
  }
}));

const AddItemToList = ({ children, block, index, el }) => {
  const cl = useStyles({});
  const { formatTemplate } = useSelector((state) => state);
  const [listType, setListType] = useState("");


  const findTypeList = () => {
    if (el.settings && el.format) {
      if(el.format.identifier === "numbered-list"){
        return "numbered-list"
      }
      if(el.format.identifier === "bullet-list"){
        return "bullet-list"
      }
    }

    const findFormat = formatTemplate.blockFormats.find(el => el.identifier === block.format);
    if (findFormat ) {
      if (findFormat.style === "LOWER_ALPHA_LIST") {
        return "alphabetical";
      }
      if (findFormat.style === "NUMBERED_LIST") {
        return "alphabetical";
      }
      if (findFormat.style === "BULLET_LIST") {
        return "alphabetical";
      }
    }
    return "";
  };


  useEffect(() => {
    if (formatTemplate && block && el) {
      setListType(findTypeList());
    }
  }, [formatTemplate, block, el]);


  if (listType === "bullet-list") {
    return (<div className={cl.wrapperList} name={"allowСlick"}>
      <div className={cl.wrapperBulletList}  style={{height: 'fit-content'}}>
        <div className={cl.bulletIcon}>.</div>
      </div>
      {children}
    </div>);
  }

  if (listType === "numbered-list") {
    const number = el.settings.settings[0].value;
    return (<div className={cl.wrapperList} name={"allowСlick"}>
      <div style={{height: 'fit-content'}}>
        {number[0] === "continue" ? "#" : number}.
      </div>
      {children}
    </div>);
  }

  if (listType === "alphabetical") {
    return (<div className={cl.wrapperList} name={"allowСlick"}>
      <div  style={{height: 'fit-content'}}>
        {String.fromCharCode(65 + index).toLowerCase()}.
      </div>
      {children}
    </div>);
  }

  return children;
};

export default AddItemToList;