import React from 'react';
import ReactDOM from 'react-dom';
import TextareaAutosize from 'react-autosize-textarea';
import './style.css';

class AutoGrowInput extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.textInput = React.createRef();
  }

  componentDidMount() {
    this.setState({
      editingStart: false
    });
    if (this.props.focused) {
      this.focusInput(this.textInput);
    }
  }

  focusInput(component) {
    if (component && component.current) {
      const comp = ReactDOM.findDOMNode(component.current);
      if (comp) {
        comp.focus();
      }
    }
  }

  onChange(e) {
    this.setState({
      editingStart: true
    });
    this.props.onChangeCallback(e);
  }

  render() {
    const growstyle = this.props.style ? this.props.style : {};
    return (
      <div
        className="auto-grow-input"
        style={growstyle}
        onClick={(e) => {
          this.focusInput(this.textInput);
        }}
      >
        {!this.props.readonly && (
          <TextareaAutosize
            data-searchable-element="1"
            ref={this.textInput}
            minRows={1}
            defaultValue={this.props.defaultValue}
            onChange={(e) => this.onChange(e)}
            required={this.state == null || !this.state.editingStart}
          />
        )}
        {this.props.readonly && (
          <div>
            <span className="text-placeholder-readonly">{this.props.placeholder}</span>
            <div data-searchable-element="1">{this.props.defaultValue}</div>
          </div>
        )}
        {!this.props.readonly && <span className="text-placeholder">{this.props.placeholder}</span>}
      </div>
    );
  }
}

export default AutoGrowInput;
