import {
  handlePost,
  handlePostResultAsString,
  handlePostTextAsParam,
  handlePostTextAsParamResultAsText, handlePostToApi, handlePutToApi, handleQuery
} from "@zert-packages/actions/api";
import { API_ROOT_CALLS } from "clm/src/plugins/BlockPlugin/API";
import axios from "@zert-packages/utils/axios";



export const savePhraseApi = (versionId, data) => {
  return handlePostResultAsString(`/phrase/storeBlockPhraseFormatted/${versionId}`, data)
}

export const saveIndexApi = (versionId, data) => {
  return handlePostResultAsString(`/index/storeBlockIndex/${versionId}`, data)
}

export const setTerm = (versionId,phraseVersionId, data={}) => {
  return handlePostResultAsString(`/softwareText/setTerm/${versionId}/${phraseVersionId}`, data)
}

export const storeBlockApi  = (versionId, locale, data) => {
  return handlePostToApi(`/block/store/${versionId}/${locale}`, data)
}

// table start




export const createPhraseTable = (versionId) => {
  return handlePostResultAsString(`/phrase/storeBlockPhraseFormatted/${versionId}`, '{}')
}





// table end


export const createPhraseApi = (data) => {
  return handlePostResultAsString(`/phrase/init`, data)
}

export const createPhraseWithTextApi = (data) => {
  return handlePostResultAsString(`/phrase/initWithText`, data)
}

export const createBlockApi = (data) => {
  return handlePostResultAsString(`/block/init`, data)
}

export const initImgApi  = (data) => {
  return handlePostResultAsString(`/image/init`, data)
}

export const createImgApi = (parentVersionId , locale, data ) => {
  return  handlePost(`/image/createImage/${parentVersionId }/${locale }`, data);
}

//createImgApi
export const getImage = async (versionId) => {
  const token = localStorage.getItem("accessToken");
  try {
    return await axios.get(API_ROOT_CALLS + `/image/getImagePreviewById/${versionId}?jwtToken=${token}`, {
      responseType: "arraybuffer"
    });
  } catch (e) {
    return console.log('getImage Error !', e.messages);
  }
}

export const createTableApi = (data) => {
  return handlePostResultAsString(`/table/init`, data)
}

export const  tableStore = (versionId , locale, data ) => {
  return  handlePost(`/table/store/${versionId}/${locale}`, data)
}

export const createIndexApi = (data) => {
  return handlePostResultAsString(`/index/init`, data)
}

export const approveVersionApi = (companyId, versionId) => {
  return handlePostTextAsParamResultAsText(`/common/approve/${companyId}/${versionId}`, '{}');
}

export function createVersion(companyId, versionId) {
  return handlePostTextAsParam(`/common/createVersion/${companyId}/${versionId}`, '{}');
}

export const copyElementBlockApi = (data) => {
  return handlePostResultAsString(`/common/copy`, data)
}

export const pasteElementBlockApi = () => {
  return handleQuery(`/common/paste`)
}

export const duplicateElementBlockApi = ( versionId, data) => {
  return handleQuery(`/common/duplicate/${versionId}/${data}`)
}

export const saveCanvasItemPhrase = (versionId, locale, data) => {
  return handlePostResultAsString(`/image/store/${versionId}/${locale}`, data)

}

export const storeImagePhraseFormatted = (versionId, data) => {
  return handlePostResultAsString(`/phrase/storeImagePhraseFormatted/${versionId}`, data)
}
