import React, { useState , useEffect} from "react";
import { getApiTemplates } from "./InfoElement/mettaData/API";


const AddMetaData = () => {
  const [filteredStateDefinitions, setFilteredStateDefinitions] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [filteredState, setFilteredState] = useState(null);

  const getMeta = async () => {
    const result = await getApiTemplates();
    if (result) {
      setTemplates(result);
      if (result.templates && result.templates.length > 0) {
        setFilteredState(result.templates[0]);
        if (result.templates[0].metadataDefinitions && result.templates[0].metadataDefinitions.length > 0) {
          setFilteredStateDefinitions(result.templates[0].metadataDefinitions[0]);
        }
      }
    }
  };

  useEffect(() => {
    if (!templates) {
      getMeta();
    }
  }, [templates]);



  return(<div>

  </div>)
}

export default AddMetaData
