import React, {useContext, useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";
import { Dialog, Tab } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import handleValueState from "@zert-packages/utils/handleValueState";
import MetadataSearchTab from "./MetadataSearchTab";
import BrowseTab from "./BrowseTab";
import SearchTab from "./SearchTab";
import SelectedBlockMetaData from "./SelectedBlockMetaData";
import ExplorerView from "@zert-packages/plugins/ExplorerPlugin/ExplorerView";
import {useSelector} from "react-redux";
import { getAllPluginsSorted, getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import {FormattedMessage, useIntl} from "react-intl";
import {columns} from "@zert-packages/components/ElementTile/ElementTileTable";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 1070,
    minHeight: 700,
    padding: 11,
    background: "#E2F2F6"
  },
  wrapperTitle: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    "& svg": {
      cursor: "pointer"
    }
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700,
    textTransform: "capitalize"
  },
  tabsRoot: {
    minHeight: 18,
    marginBottom: 12,
    '& .MuiTab-root': {
      padding: '0px 4px 0px 3px',
      minHeight: 29,
      minWidth: 70,
      background: '#ffffff',
      textTransform: 'capitalize'
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#ffffff',
      background: '#4db1d3',
      outline: 'none'
    },
    '& .MuiTabs-centered': {
      justifyContent: 'left',
      background: '#E3F2F7'
    }
  },
  container: {
    background: '#ffffff',
    minHeight: 548,
    padding: 15
  },
  wrapperButton: {
    display: "flex",
    paddingTop: 30,
    marginTop: 'auto',
    justifyContent: "end"
  },
  button: {
    color: "#ffffff",
    margin: "0 10px",
    background: "#4db1d3",
    minWidth: 80,
    border: "1px solid #4db1d3",
    outline: "none !important",
    cursor: "pointer",
    "&:hover": {
      background: "none",
      color: "#212529",
      border: "1px solid #4db1d3"
    },
    "&:focus": {
      backgroundColor: "#4db1d3"
    }
  }
}));

const dataBlockMetaData = [
  {name : 'Art. No book', value: '1'},
  {name : 'Art. No book', value: '2'}
]


export const checkFilter = (filter, arr) => {
    if(filter === 'All elements'){
      return arr
    }else{
      return  arr?.filter(el => el.mimeType.toLowerCase().includes(filter.toLowerCase()));
    }
}

// const testFilter = ['All elements', 'phrase']
const testFilter = null;

const BrowseDialog = ({filteredArr, onSelect}) => {
  const cl = useStyles();
  const intl = useIntl();
  const [open, setOpen] = useState(true);
  const[activeElement, setActiveElement] = useState(null)
  const CORE = useSelector((state) => state.CORE);
  const { searchConditions } = useSelector((state) => state.CORE);

  const [browse, setBrowse]= useState({
    selectedTab: 0,
    selectedFilter: 'All elements',
    allFilters: filteredArr ?  [...filteredArr] : [],
    selectedMetaData: -1,
    searchValue: '',
    metaSearchValue: '',
    requireAll: false,
    activeBreadCrumbs: '',
    checkRequire: false,
    listMetaData: dataBlockMetaData,
  });


  const columnsNew =
      searchConditions && searchConditions['se.zert.backend.search.SizeCriteria']
          ? [
            ...columns,
            {
              identifier: 'csize',
              numeric: false,
              disablePadding: false,
              align: 'right',
              isFilterable: true,
              label: <FormattedMessage id="Element.TileTable.Size" defaultMessage="Size" />
            }
          ]
          : columns;



  useEffect(() =>{
    const filters = getAllPluginsSorted(intl)
    if(filters && !filteredArr?.length>0){
      const strings = filters.map(el => el.mimeType)
      handleValueState(['All elements',...strings], 'allFilters', setBrowse)
    }else{
      handleValueState(filteredArr[0], 'selectedFilter', setBrowse)
    }
  },[filteredArr])




  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () =>{
    onSelect(activeElement)
    setOpen(false);
  }




  const handleSelectedTab = (event, newValue) => {
    handleValueState(newValue, 'selectedTab', setBrowse, )
  }

  return (<Dialog
    open={open}
    onClose={handleClose}
    classes={{
      paperScrollPaper: cl.root
    }}
  >
    <div className={cl.wrapperTitle}>
      <span className={cl.title}>Open</span>
      <CloseIcon onClick={handleClose} />
    </div>
    <div>
      <Tabs
        indicatorColor=""
        className={cl.tabsRoot}
        value={browse.selectedTab}
        onChange={handleSelectedTab}
        textColor="primary"
        variant="standard"
        scrollButtons="auto"
        centered
      >
        <Tab label={'Browse'} />
        <Tab label={'Search'} />
        <Tab label={'Metadata search'} />
      </Tabs>
    </div>
    <div className={cl.container}>
      {browse.selectedTab === 0 &&
      <BrowseTab  setActiveElement={setActiveElement} browse={browse} setBrowse={setBrowse}/>
      }
      {browse.selectedTab === 1 && CORE &&
      <SearchTab setActiveElement={setActiveElement} browse={browse}
                 columnsNew={columnsNew}
                 setBrowse={setBrowse}/>
      }
      {browse.selectedTab === 2 &&
      <MetadataSearchTab setActiveElement={setActiveElement} browse={browse}
                         columnsNew={columnsNew}
                         setBrowse={setBrowse} />
      }
    </div>

    <div className={cl.wrapperButton}>
      <Button disabled={!activeElement?.length} className={cl.button} onClick={handleConfirm}>
        OK
      </Button>
      <Button  className={cl.button} onClick={handleClose}>
        Cancel
      </Button>
    </div>
  </Dialog>);


};

export default BrowseDialog;