import React, { Fragment, useContext, useEffect } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import MediaQuery from 'react-responsive';
import Typography from '@mui/material/Typography';
import VerticalLinearStepper, {
  getStepContent,
  PublishingMobileStepper,
  PublishingWizardContext,
  PublishingWizardProvider
} from './PublishingWizardStepper';
import { GeneralSettingsPage } from './GeneralSettingsPage';

import { setSelectedPreviewJOBElement } from '../../PreviewPlugin/previewReducers';
import { PurposePage } from './PurposePage';
import SummaryPage from './SummaryPage';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1
  }
});
function PreviewDialogContext({ readOnlyPublihedElement }) {
  const { activeStep, handleNext, handleBack } = useContext(PublishingWizardContext);

  switch (activeStep) {
    case 0:
      return <GeneralSettingsPage readOnlyPublihedElement={readOnlyPublihedElement} />;
    case 1:
      return <PurposePage readOnly={false} />;
    case 2:
      return <SummaryPage />;

    default:
      return <div />;
  }
}

function PublishingWizardDialog({ onConfirm, element, context, publishingId, unmountComponent, unMountParent }) {
  const dispatch = useDispatch();
  const { activeStep, allowComments, notifyMeOnExpire, notifyMeOneNewComment, validUntil, purpose, setPurpose } =
    useContext(PublishingWizardContext);
  useEffect(() => {
    if (context) {
      setPurpose(context);
    }
  }, []);
  useEffect(() => {
    dispatch(setSelectedPreviewJOBElement(element));
  }, element);
  const handleConfirm = () => {
    unmountComponent();
    if (unMountParent) {
      unMountParent();
    }
    onConfirm(allowComments, notifyMeOnExpire, notifyMeOneNewComment, validUntil, purpose);
  };
  return (
    <Dialog open onClose={unmountComponent} fullWidth maxWidth="md">
      <DialogTitle>
        {publishingId && publishingId > 0 ? (
          <FormattedMessage id="PublishWizard.RepublishTitle" defaultMessage="RePublishing" />
        ) : (
          <FormattedMessage id="PublishWizard.Title" defaultMessage="Publishing" />
        )}
      </DialogTitle>
      <DialogContent>
        <MediaQuery query="(min-width: 1024px)">
          <Grid container spacing={3}>
            <Grid item xl={4}>
              <VerticalLinearStepper />
            </Grid>
            <Grid item xl={8}>
              <PreviewDialogContext readOnlyPublihedElement={publishingId !== -1} />
            </Grid>
          </Grid>
        </MediaQuery>
        <MediaQuery query="(max-width: 1024px)">
          <>
            <Typography>{getStepContent(activeStep)}</Typography>
          </>
          <PreviewDialogContext readOnlyPublihedElement={publishingId !== -1} />
        </MediaQuery>
      </DialogContent>
      <DialogActions>
        <Button onClick={unmountComponent} size="small">
          <FormattedMessage id="PublishingWizard.Cancel" defaultMessage="Cancel" />
        </Button>

        <PublishingMobileStepper handleConfirm={handleConfirm} />
      </DialogActions>
    </Dialog>
  );
}

function PublishingWizardContextWrapper({
  onConfirm,
  element,
  publishingId,
  context,
  unMountParent,
  unmountComponent
}) {
  return (
    <PublishingWizardProvider>
      <PublishingWizardDialog
        onConfirm={onConfirm}
        element={element}
        publishingId={publishingId}
        context={context}
        unMountParent={unMountParent}
        unmountComponent={unmountComponent}
      />
    </PublishingWizardProvider>
  );
}

export default PublishingWizardContextWrapper;
