const handleWidth = (interest, activeCell, index) => {
  return interest.reduce((acu, cur, idx) => {
    if (idx === index) {
      return [...acu, {width: activeCell.width, widthDefined: activeCell.widthDefined}]
    }
    return [...acu, cur]
  }, [])
}


export const findWidth = (val, checked, array, activeIndex) => {
  if (+val === 0) {
    const result = handleWidth(array, {widthDefined: checked, width: 1}, activeIndex)
    return { child: result, width: 1, }
  }
  const activeCell = array[activeIndex]
  if (array[activeIndex].width > val) {
    let minusTotal = array[activeIndex].width - val
    const adobeWidth = (array[activeIndex].width - val) / (array.filter(el => el.width > 0).length - 1)
    const minus = Math.floor(adobeWidth)
    const result =  array.reduce((acu, cur, idx) => {
      if (idx === activeIndex) {
        return [...acu, {...cur, width: val, widthDefined: checked,}]
      }
      if (cur.width > 0 && minusTotal > 0) {
        minusTotal = minusTotal - minus
        if (idx === array.length - 1) {
          return [...acu, {...cur, width: cur.width + minus + minusTotal}]
        }
        return [...acu, {...cur, width: cur.width + minus}]
      }
      return [...acu, cur]
    }, [])
    return { child: result, width: val, }
  }

  if (array[activeIndex].width !== 0 && array[activeIndex].width < val) {
    const adobeVal = val === 100 ? val - (array.length - 1) : val
    let minusTotal = adobeVal - array[activeIndex].width
    const adobeWidth = minusTotal / (array.filter(el => el.width > 0).length - 1)
    const minus = Math.floor(adobeWidth)
    const result = array.reduce((acu, cur, idx) => {
      if (idx === activeIndex) {
        return [...acu, {...cur, width: adobeVal, widthDefined: checked,}]
      }
      if (cur.width > 0 && minusTotal > 0) {
        minusTotal = minusTotal - minus
        const width = cur.width - minus
        if (idx === array.length - 1) {
          const w = width - minusTotal
          return [...acu, {...cur, width: w <= 0 ? 1 : w}]
        }
        if (width <= 0) {
          minusTotal = minusTotal + Math.abs(width) +1
          return [...acu, {...cur, width: 1}]
        }
        return [...acu, {...cur, width}]
      }
      return [...acu, cur]
    }, [])
    return { child: result, width: adobeVal}
  }
  const adobeVal = val === 0 ? 1 : val
  const result = handleWidth(array, {...activeCell, widthDefined: checked, width: adobeVal}, activeIndex)
  return { child: result, width: adobeVal}
}