import React from "react";
import styleTableEditor from "./styleTableEditor";
import { findInterest } from "./helpers/main";


const TableIcons = ({table, interest, icon, setTable, editor, border,tableState, setTableState}) => {
  const cl = styleTableEditor();


  return(<div className={cl.wrapperTableIcons}>
    <table style={{width: '100%'}}>
      <tbody>
      <tr>
        {table && table.map((el, i) => <td key={i} style={{
          width: el.width > 0 ? el.width + "%" : ""

        }} className={border ? cl.border : ''}>
          {icon && <div  onClick={() => setTable(i, editor,tableState, setTableState)}>{icon}</div>}
          {interest && table && table[i] && findInterest(table[i], cl)}</td>)}
      </tr>
      </tbody>
    </table>
  </div>)
}

export default TableIcons