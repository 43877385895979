import React, { useContext, useEffect, useState } from 'react';
import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { getPluginByMimeType } from '@zert-packages/utils/getPluginByMimeType';
import { getChildNodes, getNodeContext } from './servicesMidleware';
import { TreeContext } from './ExplorerTree';

export const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: 'transparent'
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent'
    }
  },
  xStyle: {
    margin: '-8px',
    paddingLeft: '4px'
  },
  extraXStyle: {
    margin: '-12px',
    paddingLeft: '8px'
  },

  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: theme.spacing(2),
    '& $content': {
      paddingLeft: theme.spacing(2)
    }
  },
  expanded: {},
  selected: {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
    color: 'var(--tree-view-color)'
  },
  label: {
    fontWeight: 'inherit',
    color: 'inherit'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1
  },
  greyDot: {
    width: '0px',
    height: '0px',
    margin: '3px',
    padding: '8px',
    display: 'flex',
    border: '1px solid grey',
    backgroundColor: 'rgb(128, 128, 128)'
  }
}));

export function ExplorerTreeNode(props) {
  const classes = useTreeItemStyles();
  const { info, root, nodeId, latest, labelInfo, color, bgColor, readyChildren, ...other } = props;
  const [children, setChildren] = useState(readyChildren);
  const dispatch = useDispatch();
  const { expanded, toggleExpanded } = useContext(TreeContext);
  const { selected, setSelected } = useContext(TreeContext);
  const CORE = useSelector((state) => state.CORE);

  useEffect(() => {
    toggle(null);
  }, [info]);

  useEffect(() => {
    if (!children && info.id in CORE.nodes) {
      setChildren(CORE.nodes[info.id]);
    }
  }, [CORE]);

  const toggle = (e) => {
    if (children == null) {
      if (CORE.root && !(info.id in CORE.nodes)) {
        dispatch(getChildNodes(info.id));
      } else if (info.id in CORE.nodes) {
        setChildren(CORE.nodes[info.id]);
      }
      // await expand();
    }
  };

  const expand = () => {
    if (!expanded.includes(info.id)) {
      toggleExpanded([...expanded, info.id]);
    } else if (expanded.includes(info.id) && selected === info.id) {
      const newExpanded = [...expanded];
      newExpanded.splice(newExpanded.indexOf(info.id), 1);
      toggleExpanded(newExpanded);
    }
  };

  const onClick = (e) => {
    expand();
    setSelected([info.id]);
    e.preventDefault();

    dispatch(getNodeContext(info, false));
    toggle();
  };

  const onLabel = (e) => {
    expand();
    setSelected([info.id]);
    e.preventDefault();
    dispatch(getNodeContext(info, false));
    //   await toggle();
  };

  const Icon = getPluginByMimeType(info.mimeType).icon;
  return (
    <TreeItem
      label={
        <div className={selected === info.id ? `${classes.labelRoot} ${classes.selected}` : classes.labelRoot}>
          {/* <LabelIcon color="inherit" className={classes.labelIcon} /> */}
          <div
            className={classes.labelIcon}
            style={{ padding: '0px 5px 0 0', height: '18px', width: '18px', color: 'grey' }}
          >
            <Icon className={classes.labelIcon} style={{ height: '18px', width: '18px', fill: 'grey' }} />
          </div>
          <Typography variant="body2" className={classes.labelText}>
            {info.name}
          </Typography>
        </div>
      }
      nodeId={nodeId}
      onIconClick={onClick}
      onLabelClick={onLabel}
      onClick={onClick}
      expanded={expanded}
      style={{
        '--tree-view-color': '#1a73e8',
        '--tree-view-bg-color': '#e8f0fe'
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label
      }}
      {...other}
    >
      {/*      <LoadingNode  key = {"loading" + nodeId} nodeId = {"nnn" + nodeId} labelIcon={MailIcon}/> */}
      {children && children.map((info) => <ExplorerTreeNode key={info.id} info={info} nodeId={info.id} />)}
    </TreeItem>
  );
}

/*
StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};
*/
