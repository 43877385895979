import React, { memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import ListCore from './ListCore';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-end'
  },

  CreateNewLabel: {
    color: '#1BA1C5',
    fontSize: '1.5rem',
    [theme.breakpoints.down('tablet')]: {
      fontSize: '1.25rem'
    },
    borderLeft: '2px solid #1BA1C5',
    padding: '10px 20px',
    fontWeight: 'normal',
    borderBottom: 'solid 1px rgba(27, 161, 197, .05)',
    marginBottom: 5,
    fontFamily: `'Open Sans', sans-serif`
  }
}));

function CreateNewList({ list, onClick }) {
  const cl = useStyles();
  return (
    <div className={cl.root} data-intro-id="SideBar.CreateNew">
      <CreateNewLabel />
      <ListCore list={list} onClick={onClick} />
    </div>
  );
}

export default CreateNewList;

const CreateNewLabel = memo(() => (
  <div className={useStyles().CreateNewLabel}>
    <FormattedMessage id="Packages.Components.SideBar.CreateNewLabel" defaultMessage="Create new" />
  </div>
));
