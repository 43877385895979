import React from 'react'
import makeStyles from "@mui/styles/makeStyles";
import BlockIcon from "@zert-packages/muiadopticons/BlockIcon";
import {getPluginByMimeTypeIcon} from "@zert-packages/utils/getPluginByMimeType";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        gap: 2.5,
        flexFlow: "wrap",
        alignItems: "center",
    },
    wrapperIcon: {
        position: "relative",
        top: 1,
        height: 15
    },
    wrapperArrow: {
        color: 'rgb(24, 142, 199)',
        display: 'flex',
    },
}))

const CellLabel = ({it}) => {
    const cl = useStyles();
    const cell = it.val
    const list = cell.elementInfo?.properties['zert:Format']

    if (!cell) {
        return <div></div>
    }


    return (
        <div>
            <div className={cl.wrapper}>
                <div className={cl.wrapperIcon}>
                    <BlockIcon svg={getPluginByMimeTypeIcon(cell.elementInfo.mimeType, true)}/>
                </div>
                {it.copy && <div className={cl.wrapperArrow}>
                    <ArrowDownwardIcon />
                </div>}
                <div>{cell.elementInfo.name}</div>
                <div>[{cell.elementInfo.label}]</div>
            </div>

            {list && list !== 'normal' && <div style={{textTransform: 'capitalize',}}>
                {`${list.split('-')[0]} (${list.split('-')[1]})`}
            </div>}
        </div>
    )
}

export default CellLabel