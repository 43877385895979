import React, {useEffect, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import makeStyles from "@mui/styles/makeStyles";
import RectangleComp from "./components/RectangleComp";
import TableRow from "./components/TableRow";
import {checkIfInRect, checkKoords2, handleBorderRect, handleUpdateData} from "./helpers/reusedMethods";
import {
    addColumn,
    addRow,
    handleUnselectCells,
    removeColumnGlobal,
    removeRowGlobal,
    setRowsKoords,
    updateCellValueGlobal
} from "./helpers/tableMethods";
import HeadTable from "./components/head/HeadTable";
import {createTable, findOverflowHeight, findSelectedItems, findSliceRow, saveTableElement} from "./helpers/main";
import {useDispatch, useSelector} from 'react-redux';
import {useSnackbar} from "notistack";
import {handlePluginAction} from "@zert-packages/actions/coreReducers";
import ToolBarEditorTable from "./components/ToolBarEditorTable";
import PhraseEditor from "../../PhrasePlugin/components/PhraseEditor";


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        zIndex: '10'
    },
    table: {
        width: 'Calc(100% - 16px)',
        '& td': {
            cursor: 'pointer',
        }
    },
    blurBlockTable: {
        width: 'Calc(100% + 44px)',
        height: '100%',
        position: 'fixed',
        marginLeft: -44,
    },
    wrapperTable: {
        overflow: 'auto',
        position: 'relative',
        zIndex: 3,
    },
    item: {
        border: "1px solid red",
        width: "180px",
        height: "80px",
        margin: "10px"
    },
}))


let tableKoords = null;

const hoverTableData = {
    all: false,
    selectedAll: false,
    cell: false,
    selectedCell: false,
    deleteColumn: false,
    columnLeft: false,
    columnRight: false,
}

export const createArr = (n, val) => Array.from({length: n}, (value, index) => val ? val : index);


const visibleRows = 16
const rowHeight = 40

const ResizedTable = ({data, schemaGlob, values, setValues, vue}) => {
    const cl = useStyles();
    const ref = useRef(null);
    const rootRef = useRef();
    const snackbar = useSnackbar();
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState(null);
    const [tableDataCopy, setTableDataCopy] = useState(null);
    const [schema, setSchema] = useState(schemaGlob);
    const [selected, setSelected] = useState([])
    const [schemaCopy, setSchemaCopy] = useState(schemaGlob);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [recStartOrigin, setRecStartOrigin] = useState(null);
    const [cellsKoord, setCellsKoord] = useState([]);
    const tableId = uuidv4();
    const [hoverTable, setHoverTable] = useState(hoverTableData);
    const overflowTable = tableData && tableData.map(el => el.val).flat().length > 999
    const [start, setStart] = useState(0);
    const {myLanguage, actionHeader} = useSelector((state) => state);
    const [loading, setLoading] = useState(false)


    const removeRow = (id) => {
        setTableDataCopy(tableData)
        setSchemaCopy(schema)
        removeRowGlobal(id, setSchema, schema, tableData, setTableData, setSelected, setValues)
    };

    const addRowLocal = (index, type, isLastItem) => {
        setSchemaCopy(schema)
        addRow(setSchema, schema, setTableData, index, type, setValues, isLastItem, setSelected)
    }

    const addColumnLocal = (index, type) => {
        setSchemaCopy(schema)
        addColumn(setSchema, setTableData, index, type, setValues, setSelected)
    }


    const removeColumn = (index) => {
        setHoverTable(pre => ({...pre, selectedCell: false, deleteColumn: false,}))
        setTableDataCopy(tableData)
        setSchemaCopy(schema)
        removeColumnGlobal(index, setSchema, schema, tableData, setTableData, setValues, setSelected)
    };

    const updateCellValue = (rowId, itId, value, name) => {
        updateCellValueGlobal(rowId, itId, value, name, setTableData)
    };

    const checkIfOuterselectedCell = (click, active) => {
        return (click?.pageX < active?.left) || (click?.pageX > active?.right) ||
            (click?.pageY < active?.top) || (click?.pageY > active?.bottom)
    }


    const mouseClickOnTable = (e) => {
        if(vue){
            return
        }
        setHoverTable(hoverTableData)
        const name = e.target.getAttribute('name')
        if ((selected.length !== 1 || checkIfOuterselectedCell(e, selected[0]?.koords)) && name !== 'blockMouseDown') {
            handleUnselectCells(setTableData)
            const divElement = document.getElementById(tableId);
            if (divElement) {
                tableKoords = divElement.getBoundingClientRect()
            }
            setIsMouseDown(true)
            handleCellsKoords()
            setRecStartOrigin({top: e.clientY, left: e.clientX})
        }
    }

    const uniteCells2 = (tableData) => {

        const selected = tableData.reduce((ac, cur) => {
            if (cur.val.some(el => el.selected)) {
                const sel = cur.val.filter(el => el.selected)
                const koordsArr = sel.reduce((ac1, cur1) => {
                    const divElement = document.getElementById(cur1.id);
                    const koords = divElement.getBoundingClientRect()
                    return [...ac1, {koords: koords}]
                }, [])
                return [...ac, ...koordsArr]
            } else {
                return ac
            }
        }, [])
        const borderRectInner = handleBorderRect(selected)

        setTableDataCopy(tableData)
        setSchemaCopy(schema)

        const rowsKoords = setRowsKoords(tableData)

        const selectedRows = rowsKoords.reduce((ac, cur) => {
            if ((cur?.koords?.top || 0) >= ((borderRectInner?.top || 0) - 1) &&
                ((cur?.koords?.bottom || 0) <= ((borderRectInner?.bottom || 0) + 1))) {
                return [...ac, cur]
            } else {
                return ac
            }
        }, [])

        const collsTitleKoords = createArr(schema.columns).reduce((ac, cur, ind) => {
            const divEl = document.getElementById(`column${ind}`);
            const item = divEl.getBoundingClientRect()
            return [...ac, {item: item, index: ind}]
        }, [])

        const columnsIncluded = collsTitleKoords.reduce((ac, cur) => {
            if (((cur.item.left || 0) >= ((borderRectInner?.left || 0) - 1)) && ((cur.item.right || 0) <= ((borderRectInner?.right || 0) + 1))) {
                return [...ac, cur]
            } else {
                return ac
            }
        }, [])

        const colls = columnsIncluded.length
        const rowsCount = selectedRows.length

        const updatedData = handleUpdateData(tableData, colls, rowsCount, setSelected, setValues)
        setTableData(updatedData);
    }


    const stopHover = () => {
        setIsMouseDown(false)
    }

    const selectedTableAll = () => {
        setHoverTable(pre => ({...pre, selectedAll: true}))
        setSelected(tableData.map(el => el.val).flat())
    }

    const handleSelected = () => {
        setHoverTable(hoverTableData)
        setSelected([])
    }


    function getTopHeight() {
        return rowHeight * start;
    }

    function getBottomHeight() {
        if (!tableData) {
            return rowHeight
        }
        return rowHeight * (tableData.length - (start + visibleRows + 1));
    }


    const handleStop = (cells, rectOrigin) => {
        setIsMouseDown(false)
        const selected = cells.reduce((ac, curent) => {
            const isSelected = checkKoords2(curent.koords, rectOrigin.top, rectOrigin.left,
                rectOrigin.right, rectOrigin.bottom)
            return isSelected ? [...ac, curent] : ac
        }, [])
        const rect = handleBorderRect(selected);
        const tableDataSelected = tableData.reduce((ac, cur) => {
            const val = cur.val.reduce((ac1, cur1) => {
                const divElement = document.getElementById(cur1.id);
                if (!divElement) {
                    return [...ac1, cur1]
                }
                const koords = divElement.getBoundingClientRect()
                const isSelected = checkIfInRect(koords, rect)

                return [...ac1, {...cur1, selected: isSelected, koords: koords}]
            }, [])
            return [...ac, {...cur, val: val}]
        }, [])
        setTableData(tableDataSelected)
        const selectedItems = tableDataSelected.reduce((ac, cur) => {
            return [...ac, ...cur.val.filter(el => el.selected)]
        }, [])

        if(findSelectedItems(selectedItems, values)){
            setValues(pre=> ({...pre, treeItem: selectedItems[0].val}))
        }
        setSelected(selectedItems)
    }

    const handleCellsKoords = () => {
        const koords = tableData.slice(start, start + visibleRows + 1).reduce((ac, cur) => {

            const rowKoords = cur.val.reduce((ac1, cur1) => {
                const divElement = document.getElementById(cur1.id);
                if (!divElement) {
                    return [...ac1, cur1]
                }
                const koords = divElement.getBoundingClientRect()
                return [...ac1, {koords: koords}]
            }, [])
            return [...ac, ...rowKoords]

        }, [])
        setCellsKoord(koords)
    }



    const handleActionHeader = async () => {
        if (loading) {
            return
        }
        if (actionHeader === 'save') {
            setLoading(true)
            dispatch(handlePluginAction(null));
            await saveTableElement(snackbar, values, myLanguage, tableData)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (data) {
            createTable(data, schemaGlob, setTableData, setTableDataCopy, setSchema, setSchemaCopy)
        }
    }, [data])

    useEffect(() => {
        if (tableData && rootRef.current && overflowTable) {
            function onScroll(e) {
                setTimeout(() => {
                    setStart(Math.min(
                        tableData.length - visibleRows - 1,
                        Math.floor(e.target.scrollTop / rowHeight)
                    ));
                }, 100)
            }

            rootRef.current.addEventListener('scroll', onScroll);
            return () => {
                rootRef.current.removeEventListener('scroll', onScroll);
            }
        }

    }, [tableData?.length, visibleRows, rowHeight, overflowTable]);

    useEffect(() => {
        if (actionHeader) {
            handleActionHeader()
        }
    }, [actionHeader])


    console.log('1', values.tableDefault)

    return (<div id={tableId} className={cl.root}>
            {selected?.length > 0 && <div className={cl.blurBlockTable} onClick={handleSelected}></div>}
            {!vue && <ToolBarEditorTable
                selected={selected}
                values={values}
                setValues={setValues}
                uniteCells2={uniteCells2}
                tableData={tableData}
                setTableData={setTableData}
                setSelected={setSelected}
            />}
            {isMouseDown && recStartOrigin &&
                <RectangleComp setIsMouseDown={setIsMouseDown}
                               cellsKoord={cellsKoord} handleStop={handleStop}
                               recStartOrigin={recStartOrigin}/>
            }
            {/*{tableData && <div>*/}
            {/*    <PhraseEditor el={tableData[0].val[0].val} vue={true}/>*/}
            {/*</div>}*/}


            <div className={cl.wrapperTable} style={{
                maxHeight: overflowTable ? findOverflowHeight(rowHeight, visibleRows) : '90vh',
                paddingBottom: overflowTable ? 30 : 16,
            }} ref={rootRef}>
                {overflowTable && <div style={{height: getTopHeight()}}/>}
                <table className={cl.table} ref={ref} onMouseUp={stopHover}>
                    {vue ? <div style={{marginTop: 5}}></div>
                        : <HeadTable
                            addColumn={addColumnLocal}
                            tableData={tableData}
                            selectedTableAll={selectedTableAll}
                            schema={schema}
                            hoverTable={hoverTable}
                            setHoverTable={setHoverTable}
                            removeColumn={removeColumn}
                            setSelected={setSelected}
                            values={values}
                        />}
                    <tbody>
                    {tableData && tableData.slice(start, findSliceRow(tableData, overflowTable, vue, start, visibleRows))
                        .map((el, i, arr) => (
                            <TableRow
                                index={i}
                                key={el.id}
                                el={el}
                                hoverTable={hoverTable}
                                updateCellValue={updateCellValue}
                                addRowLocal={addRowLocal}
                                removeRow={removeRow}
                                selected={selected}
                                setSelected={setSelected}
                                isLastItem={i === arr.length - 1}
                                setHoverTable={setHoverTable}
                                onMouseDown={mouseClickOnTable}
                                parentRef={rootRef}
                                values={values}
                                tableData={tableData}
                                setValues={setValues}
                                setTableData={setTableData}
                                vue={vue}
                            />
                        ))}
                    </tbody>
                </table>
                {overflowTable && <div style={{height: getBottomHeight()}}/>}
            </div>

        </div>
    );
};

export default ResizedTable;
