import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Structure from './Structure/Structure';
import Aliases from './Aliases';
import Comments from './Comments';

const useStyles = makeStyles((theme) => ({
  rootLeftExpanded: {
    background: '#FFFFFD',
     marginTop: 20,
    marginBottom: 15,
    borderRadius: 5,
    boxShadow: '5px 5px 5px rgb(0 0 0 / 10%)',
    display: 'flex',
    flexDirection: 'column',

    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  }
}));

function LeftExpanded({style={}}) {
  const cl = useStyles();
  const [expandedPane, setExpandedPane] = useState({
    expandedPanelOne: true,
    expandedPanelTwo: false,
    expandedPanelThree: false
  });
  return (
      <div className={cl.rootLeftExpanded} style={style}>
        <Structure expanded={expandedPane} setExpanded={setExpandedPane} />
        <Aliases expanded={expandedPane} setExpanded={setExpandedPane} />
        <Comments expanded={expandedPane} setExpanded={setExpandedPane} />
      </div>

  );
}
export default LeftExpanded;
