import {createInProgressSnack} from "@zert-packages/components/common/snackBar";
import {FormattedMessage} from "react-intl";
import React from "react";
import {
    createBlockApi,
    createIndexApi,
    createPhraseApi, createPhraseTable,
    createTableApi, tableStore
} from "@zert-packages/components/InfoElement/components/API";
import {savePhrase} from "@zert-packages/components/InfoElement/helpers/ToolBarAction";

export const createPhrase = async (snackbar, mylocale, parentVersionId, setBlock, values, it, tableData) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.CreatePhrase" defaultMessage="Create phrase..."/>
    );
    generatingSnackbar;

    const initPhrase = await createPhraseApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        });
    if (!initPhrase || !initPhrase.elementInfo) {
        return snackbar.closeSnackbar(generatingSnackbar);
    }
    const newPhrase = await createPhraseTable(initPhrase.elementInfo.versionId)

    if (!newPhrase) {
        return snackbar.closeSnackbar(generatingSnackbar);
    }

    setBlock(pre => ({...pre, item: [...pre.item, newPhrase]}))
    snackbar.closeSnackbar(generatingSnackbar);
}

export const addNewCellTable = (tableData, it, newValue) => {
    const res = tableData.reduce((acc, cur, i) => {
        if (i === it.posX) {
            const val = cur.val.reduce((accCell, curCell, idx) => {
                if (idx === it.posY) {
                    return [...accCell, {...curCell, val: newValue, default: newValue,}]
                }
                return [...accCell, curCell]
            }, [])
            return [...acc, {...cur, val}]
        }
        return [...acc, cur]
    }, [])
    return res
}

export const handleActionToolBar = async (typeSave, type, item, snackbar, blockCreate, setBlockCreate, activeLocales, myLanguage, selectedItems, values, it, setValues, setTableData) => {
    const mylocale = myLanguage.value
    if (!selectedItems) {
        return
    }
    if (typeSave === "Cancel") {
        return setBlockCreate(pre => ({...pre, createType: false,}))
    }

    if (typeSave === "Save") {
        if (blockCreate.phraseEditor.phrase.length === 0) {
            return
        }
        const generatingSnackbar = createInProgressSnack(
            snackbar,
            <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..."/>
        );
        const treeItem = {...selectedItems, onlyTableSave: true,}

        await savePhrase({
            ...blockCreate,
            treeItem
        }, activeLocales, mylocale, blockCreate, false, false, false, `${item.versionId}`, myLanguage)


        const table = {
            ...values.tableDefault, internalContent: [...values.tableDefault.internalContent,
                {
                    clientItem: selectedItems,
                    clientTablePositionRequest: {
                        "row": it.posX,
                        "column": it.posY,
                    },
                }
            ]
        }
        setValues(pre => ({...pre, tableDefault: table}))
        setTableData(pre => addNewCellTable(pre, it, selectedItems))

        // setTableData

        await tableStore(table.elementInfo.versionId, mylocale, table)
        snackbar.closeSnackbar(generatingSnackbar);
        setBlockCreate(pre => ({...pre, open: false}))


    }
}


export const createBlockItem = async (snackbar, mylocale, parentVersionId, setBlock) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.CreateBlock" defaultMessage="Create block..."/>
    );
    generatingSnackbar;
    const res = await createBlockApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        });
    console.log('res')
    if (res.elementInfo) {
        setBlock(pre => ({...pre, item: res}))
    }
    snackbar.closeSnackbar(generatingSnackbar);
}

export const createTableItem = async (snackbar, mylocale, parentVersionId, setBlock) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.CreatePhrase" defaultMessage="Create Table..."/>
    );
    generatingSnackbar;
    const res = await createTableApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        });
    console.log('res')
    if (res.elementInfo) {
        setBlock(pre => ({...pre, item: res}))
    }
    snackbar.closeSnackbar(generatingSnackbar);
}

export const createIndex = async (snackbar, mylocale, parentVersionId, setBlock) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.CreatePhrase" defaultMessage="Create phrase..."/>
    );
    generatingSnackbar;
    const res = await createIndexApi(
        {
            locale: mylocale,
            parentVersionId: parentVersionId
        });
    if (res.elementInfo) {
        setBlock(pre => ({...pre, item: res}))
    }
    snackbar.closeSnackbar(generatingSnackbar);
}