import React, { useEffect, useState } from 'react';
import { TextField, Typography, Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { FormattedMessage } from 'react-intl';
import UserSuggestBox from '@zert-packages/components/shared/UserSuggestBoxNew/UserSuggestBox';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import { API_ROOT_WAR } from '@zert-packages/actions/api';
import DescriptionField from './components/DescriptionField';
import LongSelectList from './components/LongSelectList';
import MembersTable from './Members';
import { loadTemplateNewRMP } from './redux/servicesMiddlewares';
import { initDemandProjectAPI } from './APIs';

import { WithDemandsTabs } from './components/DemandsComponents';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 40px',
    gridGap: 10,
    padding: '10px 20px',
    overflow: 'hidden'
  },
  contentContainer: {
    display: 'grid',
    gridTemplateRows: '48px 1fr',
    overflow: 'auto',
    padding: '10px'
  },
  generalSettingsContainer: {
    padding: '10px',
    display: 'grid',
    alignItems: 'flex-start',
    gridTemplateRows: '35px 28px 40px 56px 54px 47px 1fr',
    gridGap: 10,
    gridTemplateColumns: '1fr'
  },
  demandsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '48px 1fr',
    gridGap: 10
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 10
    }
  }
});

function NewRmpPage(props) {
  const history = useHistory();
  const cl = useStyles();
  const dispatch = useDispatch();
  const [isCreating, setCreating] = useState(false);
  const templateNewRMP = useSelector((state) => state.RMP.templateNewRMP);
  const [state, setState] = useState({
    name: '',
    projectManager: null,
    subProjects: 1,
    rows: 1,
    description: '',
    clientDirectives: [],
    clientProvision: [],
    clientStandarts: [],
    clientOther: [],
    members: []
  });
  const [tabIndex, setTabIndex] = useState(0);

  const updateProjectManager = (user) => {
    if (user === null) {
      setState((prev) => ({
        ...prev,
        projectManager: null
      }));
      return;
    }

    setState((prev) => {
      const projectManager = {
        user,
        username: user.userName,
        function: 'PROJECTMANAGER',
        name: user.fullname,

        email: user.email,
        active: true
      };
      return {
        ...prev,
        projectManager,

        members: [...prev.members.filter((m) => projectManager.username !== m.username), projectManager]
      };
    });
  };

  const addMember = (member) =>
    setState((prev) => {
      if (prev.members.find((m) => m.email === member.email)) {
        return prev;
      }
      return {
        ...prev,
        members: [...prev.members, member]
      };
    });

  const removeMember = (userName) =>
    setState((prev) => {
      const sameAsProjectManager = userName === (prev.projectManager && prev.projectManager.username);
      return {
        ...prev,
        projectManager: sameAsProjectManager ? null : prev.projectManager,
        members: prev.members.filter((mem) => mem.username !== userName)
      };
    });
  const updateMember = (member) =>
    setState((prev) => {
      const sameAsProjectManager = member.username === (prev.projectManager && prev.projectManager.username);
      if (sameAsProjectManager) {
        updateProjectManager(member);
      }
      return {
        ...prev,
        members: prev.members.map((mem) => {
          if (mem.username !== member.username) return mem;
          return {
            ...member
          };
        })
      };
    });

  const updateStateDemands = (name) => (callback) =>
    setState((prev) => ({
      ...prev,
      [name]: callback(prev[name])
    }));

  const updateState = (name) => (event) => {
    const { value } = event.target;
    setState((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!templateNewRMP) dispatch(loadTemplateNewRMP());
  }, []);
  if (!templateNewRMP) {
    return (
      <Loader>
        <FormattedMessage id="RMP.NewRMP.LoadingTemplate" defaultMessage="Template" />
      </Loader>
    );
  }

  if (isCreating) {
    return (
      <Loader loaderAction={LoaderAction.Creating}>
        <FormattedMessage id="RMP.NewRMP.CREATING" defaultMessage="New demand project" />
      </Loader>
    );
  }

  async function handleCreate() {
    try {
      setCreating(true);
      const demands = [
        ...state.clientDirectives,
        ...state.clientOther,
        ...state.clientStandarts,
        ...state.clientProvision
      ];
      const result = await initDemandProjectAPI(
        state.subProjects,
        state.rows,

        {
          name: state.name,
          demands,
          members: state.members,
          roundRobin: state.projectManager,
          description: state.description
        }
      );

      history.push(`${API_ROOT_WAR}/rmp/${result}`);
    } catch (e) {
      setCreating(false);
    }
  }

  function CreateButton() {
    return (
      state.name.length > 0 &&
      state.projectManager &&
      +state.subProjects > 0 &&
      +state.rows > 0 && (
        <Button variant="contained" color="primary" onClick={handleCreate}>
          <FormattedMessage id="RMP.NewRMP.Create" defaultMessage="Create" />
        </Button>
      )
    );
  }

  return (
    <div className={cl.root}>
      <div className={cl.contentContainer}>
        <WithDemandsTabs
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          yourTabs={[
            <FormattedMessage id="RMP.NewRMP.MainInfo" defaultMessage="Main" />,
            <FormattedMessage id="RMP.NewRMP.MembersTab" defaultMessage="Members" />
          ]}
        />
        {tabIndex === 0 && (
          <div className={cl.generalSettingsContainer}>
            <Typography color="textPrimary" variant="h6">
              <FormattedMessage id="RMP.NewRMP.CreateProjectTitle" defaultMessage="Create your project" />
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedMessage
                id="RMP.NewRMP.SubTitle"
                defaultMessage="This dialog will help you create your project matrix, specifying the size of it and your demands."
              />
            </Typography>

            <TextField
              variant="standard"
              fullWidth
              value={state.name}
              onChange={updateState('name')}
              label={<FormattedMessage id="RMP.NewRMP.Name" defaultMessage="Name" />}
            />
            <UserSuggestBox
              onChange={updateProjectManager}
              label={<FormattedMessage id="RMP.NewRMP.ProjectManager" defaultMessage="Project Manager" />}
              getUserId={(user) => user.username}
              getUserLabel={(user) => user.name}
              getUsers={getUsersQuery}
              symbolsToStart={3}
              initialValue={state.projectManager ? state.projectManager.name : ''}
            />
            <TextField
              variant="standard"
              fullWidth
              label={<FormattedMessage id="RMP.NewRMP.SubProjects" defaultMessage="Sub-projects" />}
              type="number"
              value={state.subProjects}
              onChange={updateState('subProjects')}
            />
            <TextField
              variant="standard"
              fullWidth
              label={<FormattedMessage id="RMP.NewRMP.Rows" defaultMessage="Rows" />}
              type="number"
              value={state.rows}
              onChange={updateState('rows')}
            />
            <DescriptionField onChange={updateState('description')} value={state.description} />
          </div>
        )}
        {tabIndex === 1 && (
          <MembersTable
            members={state.members}
            addMember={addMember}
            removeMember={removeMember}
            updateMember={updateMember}
          />
        )}
        {tabIndex === 2 && (
          <LongSelectList
            setActiveItems={updateStateDemands('clientDirectives')}
            availableItems={templateNewRMP.clientDirectives}
            // 0  template={templateNewRMP}
            activeItems={state.clientDirectives}
          />
        )}
        {tabIndex === 3 && (
          <LongSelectList
            setActiveItems={updateStateDemands('clientProvision')}
            availableItems={templateNewRMP.clientProvision}
            // template={templateNewRMP}
            activeItems={state.clientProvision}
          />
        )}
        {tabIndex === 4 && (
          <LongSelectList
            setActiveItems={updateStateDemands('clientStandarts')}
            availableItems={templateNewRMP.clientStandarts}
            // template={templateNewRMP}
            activeItems={state.clientStandarts}
          />
        )}
        {tabIndex === 5 && (
          <LongSelectList
            setActiveItems={updateStateDemands('clientOther')}
            availableItems={templateNewRMP.clientOther}
            // template={templateNewRMP}
            activeItems={state.clientOther}
          />
        )}
      </div>
      <div className={cl.actionContainer}>
        <CreateButton />

        <Button variant="outlined" color="primary" onClick={() => history.push('/')}>
          <FormattedMessage id="RMP.NewRMP.Cancel" defaultMessage="Cancel" />
        </Button>
      </div>
    </div>
  );
}

export default NewRmpPage;
