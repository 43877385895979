import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import InfoElementWrapper from '@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper';
import BlockWrapper from './BlockWrapper';


class BlockView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      versionId: -1
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    if (params.versionId !== '-1') {
      this.setState({
        versionId: getParameterAsInt(params.versionId)
      });
    }
  }


  render() {
    if (!this.state.versionId || this.state.versionId < 0) {
      return <div className="content-cage"  />;
    }
    return (
      <div className="content-cage" style={{ overflowY: 'auto' }}>
        <InfoElementWrapper>
          <BlockWrapper/>
        </InfoElementWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  news: state.news,
  mynews: state.mynews
});

export default  withRouter(connect(mapStateToProps)(BlockView));
