import React, {useEffect, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import {API_ROOT_CALLS, getBlockApi} from "../../../BlockPlugin/API";
import PhraseEditor from "../../../PhrasePlugin/components/PhraseEditor";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import {
    findBorderTd,
    findDeleteHover,
    findHoverY,
    handleColorCell,
    handleName,
    handleSaveElement
} from "../helpers/main";
import {adobeColor} from "../../../StylesheetPlugin/utils/helpers";
import AddIcon from '@mui/icons-material/Add';
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import AddToolbarDialog from "./dialog/AddToolbarDialog/AddToolbarDialog";
import CellLabel from "./CellLabel";
import BlockWrapper from "../../../BlockPlugin/BlockWrapper";
import PaletteIcon from "@mui/icons-material/Palette";
import BlockItems from "../../../BlockPlugin/BlockItems";
import AbstractWrapper from "@zert-packages/components/InfoElement/components/AbstractWrapper";
import {TableContext} from "../CustomTable";
import {useSelector} from "react-redux";
import {TextFieldItem} from "@zert-packages/components";


const useStyles = makeStyles((theme) => ({
    item: {
        padding: '8px 5px',
        height: 40,
        overflow: "auto",
        border: '1px solid #c1c7d0f2',
    },
    selectedCell: {
        verticalAlign: 'top',
    },
    field: {
        margin: 0,
        '& .MuiInputBase-root': {
            padding: '5px 7px',
            background: '#DDECF0',
        }
    },
    wrapperAddIcon: {
        display: 'flex',
        justifyContent: 'end',
        color: '#188EC7',
        position: 'relative',
        top: -3,
        left: 5,
        '& svg': {
            fontSize: '1.57rem',
        },
        '& button': {
            padding: 3,
            '&:focus': {
                outline: 'none',
            }
        },
    },
    wrapperImg: {
        textAlign: 'center',
        '& img': {
            width: '100%',
            maxHeight: 91,
            maxWidth: 128,
            objectFit: 'contain',
        },
    },
    wrapperColor: {
        display: 'flex',
        gap: 5,
        marginBottom: 5,
        position: 'relative',
        maxWidth: 'fit-content',
        marginLeft: 'auto',
        marginRight: 10,
    },
    selectedColor: {
        width: 21,
        height: 21,
        border: '1px solid #188ec7',
        borderRadius: 5,

    },
    editIcon: {
        zIndex: "10",
        padding: 2,
        width: 20,
        height: 20,
        marginTop: 2,
    }
}))


const RowItem = ({
                     it,
                     el,
                     selected,
                     row,
                     hoverTable,
                     onMouseDown,
                     parentRef,
                     values,
                     setSelected,
                     tableData,
                     setValues,
                     setTableData
                 }) => {

    const token = localStorage.getItem("accessToken");
    const cl = useStyles()
    const [sel, setSel] = useState(false);
    const [block, setBlock] = useState(null)
    const [active, setActive] = useState(false)
    const mimeType = it?.val?.elementInfo?.mimeType
    const deleteHover = findDeleteHover(row, hoverTable, it)
    const selectedItem = selected?.length === 1 && selected[0].id === it.id
    const selectedBlock = (mimeType === "application/zert-block") && selectedItem && block?.items.length > 0
    const { myLanguage, activeLocales, } = useSelector((state) => state);



    const handleBlock = async () => {
        const res = await getBlockApi(it?.val?.elementInfo.versionId, it?.val?.elementInfo.locale).catch(e => console.log(e));
        if (res) {
            setBlock(res);
        }
    }

    const handleActive = () => {
        setActive(prev => !prev)
    }

    const findColor = () => {
        const colorRow = el.colorRow
        const colorCol = it.colorCol
        if (colorCol) {
            return adobeColor(colorCol)
        }
        if (colorRow) {
            return adobeColor(colorRow)
        }
    }

    const addElement = () => {
        renderInExceptionRoot(AddToolbarDialog, {values, it, tableData, setValues, setTableData})
    }

    const handleMouse = (e) => {
        if (selectedItem) {
            return
        }
        onMouseDown(e)
    }

    const handlePhrase = (valueEditor) => {
        setValues(pre => ({...pre, phraseEditor: valueEditor}))
    }

    const handleElement = (typeSave, type, item, snackbar) => {
        handleSaveElement(typeSave, type, item, snackbar, values, activeLocales, myLanguage,)
    }

    const handleNameItem = (val) => {
        handleName(val, it, tableData, setTableData, values, setValues)
    }


    useEffect(() => {
        if (
            row.hover
            || selected?.find(el => el.id === it.id)
            || hoverTable.cell.index === it.posY
            || findHoverY(hoverTable.cell.index, it)
            || hoverTable.selectedCell.index === it.posY
            || findHoverY(hoverTable.selectedCell.index, it)
            || hoverTable.selectedAll
        ) {
            setSel(true);
        } else {
            setSel(false);
        }
    }, [it, row.hover, hoverTable.cell, hoverTable.selectedCell, hoverTable.selectedAll])

    useEffect(() => {
        if (mimeType === "application/zert-block" && selectedItem) {
            handleBlock().catch(e => console.log(e))
        }
        if (!selectedItem && active) {
            setActive(false)
        }

    }, [selectedItem])

    useEffect(() => {
        if (selectedBlock && parentRef.current && it?.koords?.left) {
            parentRef.current.scrollBy({
                left: (it.posY * 90),
                behavior: 'smooth'
            });
        }
    }, [selectedBlock])





    return (
        <td id={it.id}
            key={it.id}
            onMouseDown={handleMouse}
            rowSpan={it.rowspan}
            colSpan={it.colspan}
            className={`${cl.item}  ${selectedItem ? cl.selectedCell : ''}`}
            style={{
                background: findColor(),
                ...findBorderTd(sel, deleteHover, row.hoverAddTop, row.hoverAddBottom, false,
                    hoverTable.columnLeft.index === it.posY, hoverTable.columnRight.index === it.posY),
            }}
        >
            <div>
                {/*setSelected*/}
                {selectedItem && <div>
                    <div className={cl.wrapperColor}
                         onClick={() => handleColorCell(tableData, selected, values, setValues, setTableData, setSelected)}>
                        <div>Cell color</div>
                        <div className={cl.selectedColor}
                             style={{background: it.colorCol ? adobeColor(it.colorCol) : ''}}></div>
                        <div style={{cursor: 'pointer', color: 'rgb(24, 142, 199)',}}>
                            <PaletteIcon/>
                        </div>
                    </div>
                </div>}
                {selectedItem && !it.val && <div className={cl.wrapperAddIcon} onClick={addElement}>
                    <IconButton>
                        <AddIcon name={'blockMouseDown'}/>
                    </IconButton>
                </div>}
                <div style={{zIndex: "110", transition: "0.3s", overflow: "hidden"}}>
                    {mimeType === "application/zert-image" &&
                        <div className={cl.wrapperImg}>
                            <img
                                src={API_ROOT_CALLS + `/image/getImagePreviewById/${it?.val?.elementInfo.versionId}?jwtToken=${token}`}
                            />
                        </div>
                    }


                    <div style={{
                        display: "grid",
                        gridTemplateColumns: '1fr 21px',
                        gridColumnGap: 5,
                    }}>
                        {<div style={{display: selectedItem && active ? 'block' : 'none'}}>
                            {/*{console.log('it.val?', it.val)}*/}
                            <AbstractWrapper
                                id={it.val?.elementInfo?.versionId}
                                info={it.val?.elementInfo}
                                el={it.val}
                                saveOnlyItem={handleElement}
                                context={TableContext}
                            />
                            <PhraseEditor
                                el={it.val}
                                vue={active ? true : false}
                                setPhrase={handlePhrase}
                            />
                        </div>}
                        {!active &&  !selectedItem && <CellLabel it={it}/>}


                        {selectedItem && (mimeType === "application/zert-phrase") && <div
                            name={'blockMouseDown'}
                            onClick={handleActive}
                        >
                            <IconButton className={cl.editIcon} name={'blockMouseDown'}>
                                <EditIcon name={'blockMouseDown'}/>
                            </IconButton>
                        </div>
                        }
                    </div>

                    {(mimeType === "application/zert-block") && !active && (!selected?.[0]?.id === it?.id) &&
                        <p>
                            {it?.val?.elementInfo?.properties?.["zert:Format"]}
                        </p>
                    }
                    {selectedBlock && <div style={{minWidth: 469, marginTop: 5}}>

                         <TextFieldItem
                             values={it.val.elementInfo.name}
                             setValues={handleNameItem}
                         />
                        <BlockWrapper
                            table={true}
                            block={block}
                            idBlock={it?.val?.elementInfo?.versionId}
                        />
                    </div> }
                    {/*<ItemRoot block={items}/>*/}
                </div>
            </div>
        </td>

    )
}

export default RowItem