import React, {useRef, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Fade, Menu, MenuItem } from "@mui/material";
const useStyles = makeStyles({
  burgerMenu: {
    left: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '2rem',
    height: '2rem',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    '&:focus': {
      outline: 'none',
    },

    '& div': {
      width: '2rem',
      height: '0.25rem',
      background: '#EFFFFA',
      borderRadius: '10px',
      transition: 'all 0.3s linear',
      position: 'relative',
      transformOrigin: '1px',
      '&:first-child': {
        transform: ({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)'),
      },

      '&:nth-child(2)': {
        opacity: ({ open }) => (open ? '0' : '1'),
        transform: ({ open }) => (open ? 'translateX(20px)' : 'translateX(0)'),
      },

      '&:nth-child(3)': {
        transform: ({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)'),
      },
    },
  },
  dropMenu: {
    background: 'rgba(5, 49, 67, 0.6)',
  },
  listItem: {
    margin: "0 10px",
    display: "grid",
    gridTemplateColumns: "0.2fr 1fr"
  },
});

const BurgerMenu = ({dropMenu}) => {
  const [open, setOpen] = useState(false);
  const cl = useStyles({open});
  const anchorRef = useRef(null);

  const handleMenu = () => {
    setOpen(pre => !pre)
  };


  return (
    <div>
      <div className={cl.burgerMenu} onClick={handleMenu} ref={anchorRef}>
        <div/>
        <div/>
        <div/>
      </div>
      <Menu
        keepMounted
        anchorEl={anchorRef.current}
        open={Boolean(open)}
        onClose={() => setOpen(null)}
        TransitionComponent={Fade}
        className={cl.dropMenu}
      >
        {dropMenu && dropMenu.map((el, i) => <MenuItem key={i} className={cl.listItem}>
          {el.label}
        </MenuItem>)}
      </Menu>
    </div>
  );
}

export default BurgerMenu