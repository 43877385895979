import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useSelector } from 'react-redux';

export const formStyle = (theme) =>
  createStyles({
    scrollWrapper: {
      position: 'relative',
      width: '100%%',
      height: '70rem',
      // maxHeight: '100%',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      borderRadius: '5px',
      margin: '2px',
      '@media (max-width: 900px)': {
        width: '100%',
        height: '40%'
      }
    },
    scrollContainer: {
      position: 'absolute',
      width: '100%',
      maxHeight: '90%',
      overflow: 'auto',
      scrollbarWidth: 'none',
      padding: '16px'
    },
    divFlex: {
      display: 'flex'
    },
    innerDiv: {
      width: '50%',
      padding: 4
    }
  });
const useStyles = makeStyles(formStyle);

function EntryView({ actionState, entry, setEntry }) {
  const cl = useStyles();
  const CLM = useSelector((state) => state.CLM);

  const textFieldChangeHandler = (event) => {
    event.persist();
    if (actionState.isEdit) {
      setEntry((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value
      }));
    }
  };

  const autoCompleteChangeHandler = (event, value) => {
    event.persist();
    if (actionState.isEdit) {
      setEntry((prevState) => ({
        ...prevState,
        category: value
      }));
    }
  };

  return (
    <div>
      {entry && (
        <div>
          <div style={{ padding: 4 }}>
            <Autocomplete
              disablePortal
              onChange={autoCompleteChangeHandler}
              id="combo-box-demo"
              value={entry.category !== '' ? CLM.index.categories.find((el) => el === entry.category) : ''}
              options={CLM.index.categories}
              renderInput={(params) => <TextField {...params} label="Category:" variant="outlined" fullWidth />}
            />
          </div>
          <div className={cl.divFlex}>
            <div className={cl.innerDiv}>
              <TextField
                label="Text:"
                variant="outlined"
                fullWidth
                value={entry.text}
                name="text"
                onChange={textFieldChangeHandler}
              />
            </div>
            <div className={cl.innerDiv}>
              <TextField
                label="Phonetic Reading:"
                name="textPhoneticReading"
                variant="outlined"
                onChange={textFieldChangeHandler}
                fullWidth
                value={entry.textPhoneticReading}
              />
            </div>
          </div>
          <div className={cl.divFlex}>
            <div className={cl.innerDiv}>
              <TextField
                label="Key1:"
                variant="outlined"
                name="key1"
                onChange={textFieldChangeHandler}
                fullWidth
                value={entry.key1}
              />
            </div>
            <div className={cl.innerDiv}>
              <TextField
                label="Phonetic Reading:"
                onChange={textFieldChangeHandler}
                name="key1PhoneticReading"
                variant="outlined"
                fullWidth
                value={entry.key1PhoneticReading}
              />
            </div>
          </div>
          <div className={cl.divFlex}>
            <div className={cl.innerDiv}>
              <TextField
                label="Key2:"
                onChange={textFieldChangeHandler}
                variant="outlined"
                name="key2"
                fullWidth
                value={entry.key2}
              />
            </div>
            <div className={cl.innerDiv}>
              <TextField
                label="Phonetic Reading:"
                onChange={textFieldChangeHandler}
                name="key2PhoneticReading"
                variant="outlined"
                fullWidth
                value={entry.key2PhoneticReading}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EntryView;
