import React, {createContext, useEffect, useRef, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {useDispatch, useSelector} from "react-redux";
import ResizedTable from "./ResizedTable";
import {v4 as uuidv4} from "uuid";
import {getTableApi, getTableNewApi} from "../../PhrasePlugin/API";
import {adobeSpanMap, adobeSpanMapNew, CustomTableCell} from "../../PhrasePlugin/components/table/helpers/main";
import {useEditor} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableHeader from "@tiptap/extension-table-header";
import ReactComponent from "../../PhrasePlugin/components/table/TableCell/Extension";
import {isInInterval, handleSetData} from "./helpers/tableMethods";
import TextFieldItem from "@zert-packages/components/TextFieldItem";
import {PanelContext} from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";


const useStyles = makeStyles((theme) => ({
    root: {}
}))

const newItem = {
    val: "",
    rowspan: 1,
    colspan: 1,
    posX: 0,
    posY: 0
}

export const TableContext = createContext(null);

const CustomTable = ({versionId, vue, insertedBlock}) => {

    const cl = useStyles()
    const [schema, setSchema] = useState({columns: 0, rows: 0});
    const [tableData, setTableData] = useState(null);
    const [values, setValues] = useState({
        copyCell: null,
        tableDefault: null,
        keepWith: [],
        treeItem: null,
        phraseEditor: {
            editorState: null,
            contentStateJSON: null,
            maxLength: null,
            phrase: '',
        },
    })
    const {myLanguage} = useSelector((state) => state);
    const tableDefault = values.tableDefault
    const [load, setLoad] = useState(false)


    const handleData = async () => {
        if (versionId) {
            setLoad(true)
            // const tableApiResult = await getTableApi(versionId,myLanguage.value);
            const tableApiResultNew = await getTableNewApi(versionId, myLanguage.value);
            if (tableApiResultNew) {
                console.log('tableApiResultNew', tableApiResultNew)
                // const data = {...tableApiResultNew, columnCount: 1, rowCount: 1}
                handleSetData(tableApiResultNew, setSchema, setTableData, setValues)
            }
            setLoad(false)
        }

    }

    const handleName = (val) => {
        const res = {
            ...tableDefault, name: val, elementInfo: {
                ...tableDefault.elementInfo, name: val,
            }
        }
        setValues(pre => ({...pre, tableDefault: res}))

    }

    useEffect(() => {

        if (myLanguage && !load) {
            handleData()
        }
    }, [myLanguage])


    return (
        <TableContext.Provider value={{values, setValues, block: values, setBlock: setValues}}>
            <div className={cl.root}>
                {!insertedBlock && <TextFieldItem
                    values={tableDefault?.name || ''}
                    setValues={handleName}
                />}
                {tableData && <ResizedTable
                    values={values}
                    setValues={setValues}
                    vue={vue}
                    data={tableData}
                    schemaGlob={schema}/>}
            </div>
        </TableContext.Provider>
    )
}

export default CustomTable;
