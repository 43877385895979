import React, { useContext, useEffect, useRef, useState } from "react";
import Table from "@tiptap/extension-table";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TableHeaderMenu from "./TableHeaderMenu";
import styleTableEditor from "./styleTableEditor";
import { CustomTableCell, findInterest } from "./helpers/main";
import { addNewCell, addNewRow, removeCell, removeRow } from "./helpers/tableCommands";
import { PanelContext } from "@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper";
import TableIcons from "./TableIcons";
import ReactComponent from "./TableCell/Extension";
import BlockItems from "../../../BlockPlugin/BlockItems";
import AddToolbar from "../../../BlockPlugin/components/AddToolbar";



const TableEditor = ({  contentDefault, table }) => {
  const { values, setValues } = useContext(PanelContext);
  const cl = styleTableEditor({ blockClick: values.blockClickCreateItemsBlock  });
  const ref = useRef(null);
  const [disableButton, setDisableButton] = useState(true);
  const [tableState, setTableState] = useState({
    interest: null,
    rowCount: null,
  });
  const [rows, setRows] = useState(null)




  const editor = useEditor({
    onFocus({ editor, event }) {
    },
    extensions: [StarterKit,
      Image.configure({
        inline: true,
        allowBase64: true
      }),
      Table.configure({
        resizable: true
      }), TableRow, TableHeader,
      CustomTableCell(),
      ReactComponent
      // History,
    ],
    content: contentDefault,
    editable: false,
    editorProps: {
      attributes: {
        class: "wrapper-table"
      }
    }
  });


  const handleDisable = () => {
    // editor.commands.setCellAttribute('backgroundColor', 'rgba(200, 200, 255, 0.4)')
    if (disableButton) {
      setDisableButton(false);
    }
  };

  const findActiveBlock = (activeBlock) => {
    if (!activeBlock) {
      return;
    }
    const activeId = activeBlock.id;
    const findActiveDropBlock = table.internalContentAdobe.find(el => el.identifier === activeId);
    if (findActiveDropBlock) {
      setValues(pre => ({ ...pre, "dropBlockTable": findActiveDropBlock }));
    } else {
      setValues(pre => ({ ...pre, "dropBlockTable": true }));
    }
  };

  const handleEditorClick = (e) => {
    const findActiveTd = e.target.closest("td");
    const selected = ref.current.getElementsByClassName("selectedCell");
    const activeCell = ref.current.getElementsByClassName("active");
    if (activeCell && activeCell.length > 0) {
      activeCell[0].classList.remove("active");
    }
    if (selected[0] && activeCell.length > 0) {
      activeCell[0].classList.remove("active");
    }
    if (findActiveTd) {
      findActiveTd.classList.add("active");
    }
    if (selected.length !== 0 && selected.length > 1) {
      setValues(pre => ({ ...pre, "dropBlockTable": null }));
    }
  };

  const stopClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (ref.current) {
      const getButton = ref.current.getElementsByClassName("cell");
      for (let i = 0; i < getButton.length; i++) {
        getButton[i].addEventListener("click", function() {
          let current = ref.current.getElementsByClassName("active");
          findActiveBlock(this);
          if (values.dropBlockTable) {
            setValues(pre => ({ ...pre, "dropBlockTable": null }));
          }

          if (current.length > 0) {
            current[0].className = current[0].className.replace(" active", "");
          }
          //
          // this.className += " active";
        }, false);
        getButton[i].removeEventListener("click", () => {
        });
      }

    }
  }, [ref.current, table, editor]);

  useEffect(() => {
    if (table && table.internalColumns) {
      setTableState(pre => ({
        ...pre,
        interest: table.internalColumns, rowCount: table.rowCount
      }));
    }
  }, [table]);
  const getRows = ref.current && ref.current.querySelectorAll("tr")

  useEffect(() => {
    if(ref.current){
      setRows(getRows)
    }
  }, [ref.current, tableState.rowCount, values.tableImg])



  return (<div className={cl.root} onClick={stopClick}>

    {editor && table &&  <>
      <TableHeaderMenu
        editor={editor}
        disableButton={disableButton}
        refProps={ref}
        tableState={tableState}
        setTableState={setTableState}
      />

      <div>
        <div className={`${cl.wrapperAddCellHeader} ${cl.paddingX}`}>
          <TableIcons
            table={tableState.interest}
            editor={editor}
            icon={<AddIcon className={cl.icon} />}
            setTable={addNewCell}
            setTableState={setTableState}
            tableState={tableState}
          />
        </div>
        <div className={cl.cellContainer}>
          <div className={cl.border} style={{borderRight: 'none'}}></div>
          <div className={cl.wrapperTableHeader}>
            <TableIcons border={true} table={tableState.interest} interest={true} />
          </div>
          <div className={cl.border} style={{borderLeft: 'none'}}></div>
        </div>
      </div>

      <div className={cl.wrapper}>
        {editor && editor.getJSON() && editor.getJSON().content[0].content && <div className={cl.wrapperRowIcon}
                                                                                   style={{ minHeight: editor.getJSON().content[0].content.length * 31 + "px" }}>

          {tableState.rowCount && Array.from({ length: tableState.rowCount }, (_, i) => i).map((el, i, array) => (
            <div style={{ height: rows ? rows[i] && rows[i]?.clientHeight : 31 }} key={i}className={cl.wrapperIcon}>
              <AddIcon className={cl.rowIconAdd} onClick={() => addNewRow(i, editor, tableState, setTableState)} />
            </div>
          ))}
        </div>}
        <div className={`${cl.afterAddCell} ${cl.wrapperRowIcon}`}>
          {editor && editor.getJSON() && editor.getJSON().content[0].content && <> {editor.getJSON().content[0].content.map((el, i) => (
            <div key={i} style={{ height: rows ?  rows[i]?.clientHeight : 31 }}
                 className={cl.wrapperIcon}></div>
          ))}
          </>}
        </div>

        <div className={cl.tableWrapper}>
          <div  className={cl.editorWrapper} id={'hyu'} ref={ref} onClick={handleDisable}>

            {editor && <div>
              <EditorContent border={true} editor={editor} onClick={handleEditorClick} />
            </div>}
          </div>
        </div>

        <div className={`${cl.afterAddCell} ${cl.wrapperRowIcon}`}>
          {editor && editor.getJSON() && editor.getJSON().content[0].content && <> {editor.getJSON().content[0].content.map((el, i) => (
            <div key={i} style={{ height: rows ? rows[i] && rows[i].offsetHeight : 31 }}
                 className={cl.wrapperIcon}></div>
          ))}
          </>}
        </div>

        <div className={cl.wrapperRowIcon}>
          {tableState.rowCount && Array.from({ length: tableState.rowCount }, (_, i) => i).map((el, i, array) => (
            <div style={{ height: rows ? rows[i] && rows[i].offsetHeight : 31 }} key={i} className={cl.wrapperIcon}>
              <RemoveIcon className={cl.rowIconAddRemove}
                          onClick={() => removeRow(i, editor, tableState, setTableState)} />
            </div>
          ))}
        </div>
      </div>
      <div>

        <div className={cl.cellContainer}>
          <div className={cl.border} style={{borderRight: 'none'}}></div>
          <div className={cl.wrapperTableHeader}>
            <TableIcons border={true} table={tableState.interest}/>
          </div>
          <div className={cl.border} style={{borderLeft: 'none'}}></div>
        </div>

        <div className={`${cl.wrapperRemove} ${cl.paddingX}`}>
          <TableIcons
            table={tableState.interest}
            editor={editor}
            icon={<RemoveIcon className={cl.icon} />}
            setTable={removeCell}
            setTableState={setTableState}
            tableState={tableState}
          />
        </div>
      </div>
    </>}

  </div>);
};

export default TableEditor;
