import React, { useEffect, useState } from "react";
import TableEditor from "./TableEditor";
import SkeletonLoader from "@zert-packages/components/SkeletonLoader";
import { getTableApi, getTableNewApi } from "../../API";
import { createTable } from "./helpers/main";
import TableVue from "./TableVue";
import { useSelector } from "react-redux";


const TableEditorWrapper = ({ el, vue, init, setBlock = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [contentDefault, setContentDefault] = useState(null);
  const [contentDefaultVue, setContentDefaultVue] = useState(null);
  const [tableVue, setTableVue] = useState(null);
  const [table, setTable] = useState(null);
  const { myLanguage } = useSelector((state) => state);
  //const { values, setValues } = useContext(context ? context : PanelContext);


  const getTable = async () => {

    setLoading(true);
    if (init) {
      const adobeTable = {
        ...init,
        internalContent: {},
        internalSpanmap: [],
        "internalColumns": [
          {
            "width": 0,
            "widthDefined": false
          }
        ]
      };
      await createTable(adobeTable, setTable, setContentDefault);
      setLoading(false);
      return;
    }
    const tableApiResult = await getTableApi(el.elementInfo.currentVersionId, myLanguage.value);
    const tableApiResultNew = await getTableNewApi(el.elementInfo.currentVersionId, myLanguage.value);



    await createTable(tableApiResult, setTableVue, setContentDefaultVue, vue);
    await createTable(tableApiResult, setTable, setContentDefault);
    setBlock(pre=> ({...pre, table: tableApiResult}))
    setLoading(false);
  };

  useEffect(() => {
    if(myLanguage){
      getTable();
    }
  }, [myLanguage]);

  if (loading) {
    return <SkeletonLoader height={38} />;
  }




  return (
    <div>
      {
        vue ? <TableVue  contentDefault={contentDefaultVue} table={tableVue} />
          : <>{contentDefault && <TableEditor contentDefault={contentDefault} table={table} />}</>
      }
    </div>
  );
};

export default TableEditorWrapper;