export const addNewCell = (idx, editor, tableState, setTableState) => {
  const result = tableState.interest.reduce((acu, cur, i) => {
    if (i === idx) {
      return [...acu, {}, cur];
    }
    return [...acu, cur];
  }, []);

  setTableState(pre => ({ ...pre, interest: result }));
  const focusIndex = idx === 0 ? 0 : idx * 10 + 10;

  editor.chain().focus(focusIndex).addColumnBefore().focus(false).run();
  editor.commands.blur();
};


export const removeCell = (idx, editor, tableState, setTableState) => {
  if (tableState.interest.length === 1) {
    return;
  }
  const result = tableState.interest.reduce((acu, cur, i) => {
    if (i === idx) {
      return acu;
    }
    return [...acu, cur];
  }, []);

  setTableState(pre => ({ ...pre, interest: result }));

  const focusIndex = idx === 0 ? 0 : idx * 5 + 10;
  editor.chain().focus(focusIndex).deleteColumn().focus(false).run();
  editor.commands.blur();

};


export const addNewRow = (idx, editor, tableState, setTableState) => {
  const tableRow = tableState.rowCount;

  setTableState(pre => ({ ...pre, rowCount: tableRow + 1 }));
  const focusIndex = idx === 0 ? 0 : tableState.interest.length * 10 * idx;

  editor.chain().focus(focusIndex).addRowBefore().run();
  editor.commands.blur();
};

export const removeRow = (idx, editor, tableState, setTableState) => {

  if (tableState.rowCount === 1) {
    return;
  }
  setTableState(pre => ({ ...pre, rowCount: pre.rowCount - 1 }));
  const focusIndex = idx === 0 ? 0 : tableState.interest.length * 10 * idx + 5;


  editor.chain().focus(focusIndex).deleteRow().focus(false).run();
  editor.commands.blur();
};
