import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import EntryView from './EntryView';
import { loadIndex } from '../TranslationJobPlugin/tjReducers';

function EntryDialog({ handleClose, actionState, entry, setEntry, setIndex }) {
  const [action, setAction] = useState('NONE');

  const successHandler = () => {
    setIndex((prevState) => ({
      ...prevState,
      entries: [...prevState.entries, entry]
    }));
    handleClose();
  };

  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle>{actionState.isRemove ? `Remove entry?` : 'Add new entry?'}</DialogTitle>
      <EntryView actionState={actionState} entry={entry} setEntry={setEntry} />
      <DialogActions>
        <Button onClick={successHandler} variant="contained" color="primary">
          {actionState.isRemove ? 'REMOVE' : 'ADD'}
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EntryDialog;
