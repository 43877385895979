import React, { createRef, useEffect, useState, Fragment , useContext} from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import style from './styles/style';
import InputCell from './components/InputCell';
import DropMenu from './components/DropMenu';
import DropMenuEnums from './components/DropMenuEnums';
import { getApiTemplates } from './API';
import { useSelector } from "react-redux";
import { PanelContext } from "../InfoElementWrapper/InfoElementWrapper";
import { FormattedMessage } from 'react-intl';

function MettaData({ expanded, setExpanded, context }) {
  const [cellElement, setCellElement] = useState(null);
  const [showMettaButton, setShowMettaButton] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [filteredState, setFilteredState] = useState(null);
  const [filteredStateDefinitions, setFilteredStateDefinitions] = useState(null);
  const [titleCategory, setTitleCategory] = useState(null);
  const [nevCategory, setNevCategory] = useState(null);
  const [height, setHeight] = useState();
  const cl = style({ expanded});
  const { versionInfo } = useSelector((state) => state);
  const { values, setValues } = useContext(context ? context : PanelContext);
  const refScrollBlock = createRef();


  const findId = () => {
    if(context && values?.treeItem?.versionId){
      return values?.treeItem?.versionId
    }
    if(values?.treeItem) {
      return values?.treeItem?.elementInfo?.versionId
    }
    return versionInfo?.info?.versionId
  }
  const idBlock = findId()

  const handleShowMettaButton = () => {
    setShowMettaButton(!showMettaButton);
  };


  const getMeta = async (id) => {
    const result = await getApiTemplates(id);
    if (result) {
      setTemplates(result);
      if (result.templates && result.templates.length > 0) {
        setFilteredState(result.templates[0]);
        if (result.templates[0].metadataDefinitions && result.templates[0].metadataDefinitions.length > 0) {
          setFilteredStateDefinitions(result.templates[0].metadataDefinitions[0]);
        }
      }
    }
  };

  const getCellValue = async () => {
    const findValue = templates.values.map((el, i) => ({ ...el, value: el?.value?.value, idSort: i }));
    setCellElement(findValue);
  };

  const handleChange = () => {
    setExpanded({ ...expanded, expandedPanelThree: !expanded.expandedPanelThree });
  };




  useEffect(() => {
    if ( templates) {
      getCellValue();
    }
  }, [templates]);

  useEffect(() => {
    if (idBlock) {
      getMeta(idBlock);
    }
  }, [values?.treeItem]);


  return (
    <div className={cl.root} ref={refScrollBlock} style={{
      flexGrow: expanded.expandedPanelThree ? 1 : '',
    }}>
      <Accordion expanded={expanded.expandedPanelThree} onChange={() => handleChange()} className={cl.rootAccordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={cl.ExpandIcon}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <FormattedMessage id="metadata.current" defaultMessage="Metadata" />
        </AccordionSummary>
        <AccordionDetails>
          <div className={cl.dropMenuButtonIcon} onClick={() => handleShowMettaButton()}>
            <AddIcon />
          </div>
          {showMettaButton && templates && (
            <DropMenu
              titleCategory={titleCategory}
              setTitleCategory={setTitleCategory}
              itemProps={templates}
              filteredState={[filteredState, setFilteredState]}
              filteredDefinitions={[filteredStateDefinitions, setFilteredStateDefinitions]}
              setNevCategory={setNevCategory}
            />
          )}
          {showMettaButton && filteredState && (
            <DropMenu
              titleCategory={titleCategory}
              setTitleCategory={setTitleCategory}
              filteredState={[filteredState, setFilteredState]}
              filteredDefinitions={[filteredStateDefinitions, setFilteredStateDefinitions]}
              setNevCategory={setNevCategory}
              category
            />
          )}

          <div className={cl.scrollContainerWrapper} style={{minHeight: `${height}px`,}}>
            <div className={cl.scrollContainer} >
              {showMettaButton && filteredStateDefinitions && (
                <>
                  {filteredStateDefinitions.enums ? (
                    <div>
                      {filteredStateDefinitions && (
                        <DropMenuEnums
                          itemProps={filteredStateDefinitions}
                          setShowMettaButton={setShowMettaButton}
                          setCellElement={setCellElement}
                          cellElement={cellElement}
                        />
                      )}
                    </div>
                  ) : (
                    <div>
                      <InputCell
                        valueProps={filteredStateDefinitions}
                        lastCell={false}
                        resultCell={[cellElement, setCellElement, setShowMettaButton]}
                        create
                        setNevCategory={setNevCategory}
                        nevCategory={nevCategory}
                        idBlock={idBlock}
                      />
                    </div>
                  )}
                </>
              )}

              {showMettaButton && nevCategory && (
                <div className={cl.wrapperInput}>
                  {nevCategory.map((el, idx) => (
                    <InputCell
                      valueProps={el}
                      key={idx}
                      lastCell={false}
                      resultCell={[cellElement, setCellElement, setShowMettaButton]}
                      create
                      setNevCategory={setNevCategory}
                      nevCategory={nevCategory}
                      idBlock={idBlock}
                    />
                  ))}
                </div>
              )}

              {cellElement && (
                <div className={cl.wrapperInput}>
                  {cellElement.map((el, idx, arr) => (
                    <InputCell
                      key={cellElement + idx}
                      valueProps={el}
                      firstCell={idx === 0}
                      lastCell={idx === arr.length - 1}
                      resultCell={[cellElement, setCellElement, setShowMettaButton]}
                      idBlock={idBlock}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default MettaData;
