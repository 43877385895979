import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import TileList from '@zert-packages/components/ElementTile/TileList';
import OriginLink from '@zert-packages/components/common/OriginLink';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { TASK_ACTIONS_TYPES } from '@zert-packages/components/ElementTile/shared/ELEMENT_TILE_CONTS';
import {
  getChildNodes,
  getChildNodesAfterCut,
  getNodeContext,
  updateNodeContextWithOutRefresh
} from './servicesMidleware';
import ExplorerTree from './ExplorerTree';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from "@mui/material/IconButton";
import {checkFilter} from "clm/src/plugins/BlockPlugin/components/dialog/browse/BrowseDialog";
import {columns} from "@zert-packages/components/ElementTile/ElementTileTable";


const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    formControl: {
      margin: theme.spacing(),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    treeWrapper:{
      padding: "40px 0 0 0 !important",
      position: "relative",
      transition: "0.3s",
      maxWidth:props => props.hide?  "50px" : '300px'
    },
    treeArrow:{
      position: 'absolute',
      top: '10px',
      right: '10px',
      zIndex: "100"
    },
    treeRoot: {
      position: 'absolute',
      top: '60px',
      right: '15px',
      fontSize: '1rem',
      color: '#1799c7'
    }
  };
});

function ExplorerView({setActiveElement, hideToolBar, search, filter, hideGlob=false}) {
  const [showTree, setShowTree] = useState(true);
  const[hide, setHide] = useState(hideGlob)
  const classes = useStyles({hide:hide});
  const dispatch = useDispatch();
  const CORE = useSelector((state) => state.CORE);

  useEffect(() => {}, []);

  const reloadElement = ({ actionType, onFinishState }) => {
    if (actionType === TASK_ACTIONS_TYPES.duplicate) {
      const duplicatedElement = onFinishState;
      dispatch(updateNodeContextWithOutRefresh([duplicatedElement, ...CORE.selectedNodeChildren]));
      return;
    }
    dispatch(getNodeContext(CORE.selectedNode, true));
    if (
      (Array.isArray(onFinishState) &&
        onFinishState.length > 0 &&
        onFinishState[0].mimeType &&
        onFinishState[0].mimeType.indexOf('folder') > 0) ||
      (onFinishState.mimeType && onFinishState.mimeType.indexOf('folder') > 0)
    ) {
      if (!CORE.cutNode) {
        dispatch(getChildNodes(CORE.selectedNode.id, true));
      } else {
        // eslint-disable-next-line no-undef
        dispatch(getChildNodesAfterCut(CORE.selectedNode.id, true));
      }
    }
  };

  const handleHide = () =>{
    setHide(prev => !prev)
  }

  return (
    <div className="catalog_for_mobile">
      {showTree && (
        <div id="catalog" className={classes.treeWrapper}>
          <div className={classes.treeArrow} >
            <IconButton onClick={handleHide} size={"small"}>
              {!hide &&
                <ArrowBackIosNewIcon/>
              }
              {hide &&
                <ArrowForwardIosIcon/>
              }
            </IconButton>
          </div>

          <ExplorerTree />

          <MediaQuery query="(max-width: 600px)">
            <div
              className={classes.treeRoot}
              onClick={(e) => {
                setShowTree(false);
              }}
            >
              <FormattedMessage id="explorer.collapse" defaultMessage="Collapse" />
            </div>
          </MediaQuery>
        </div>
      )}

      <div id="workingArea" style={{ overflowY: 'hidden', height: '100%' }}>
        <MediaQuery query="(max-width: 600px)">
          <div
            className="catalog_caption"
            onClick={(e) => {
              setShowTree(true);
            }}
          >
            {/* {this.state.selectedLabel} */}
            {!showTree && (
              <span
                style={{
                  fontSize: '1rem',
                  color: '#1799c7'
                }}
              >
                {' '}
                <FormattedMessage id="explorer.expand" defaultMessage="Expand" />{' '}
              </span>
            )}
          </div>
        </MediaQuery>

        <div
          className="catalog_bread_crumbs"
          onClick={(e) => {
            setShowTree(true);
          }}
        />
        <TileList
          columnsNew={columns.filter(column => column.identifier !== 'lastCompletedDate')}
          explorer_infos={filter ? checkFilter(filter,  CORE.selectedNodeChildren) : CORE.selectedNodeChildren}
          setActiveElement={setActiveElement}
          hideToolBar={hideToolBar}
          loading={CORE.isLoadingNodeContent}
          reloadElement={reloadElement}
          search={search}
          showCut={true}
          showFileUpload={true}
          bradCrumbComponent={
            CORE.selectedNode && <OriginLink versionId={CORE.selectedNode.versionId} up mine={CORE.selectedNode} />
          }
        />
      </div>
    </div>
  );
}

export default ExplorerView;
