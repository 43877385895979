import React, { useState , useContext} from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getMultipleItems } from "./helpers/getSideBarMenu";

import { FormattedMessage } from "react-intl";
import { useSnackbar } from "notistack";
import { createInProgressSnack } from "../common/snackBar";
import { PanelContext } from "./InfoElementWrapper/InfoElementWrapper";
import { approveElement } from "./helpers/ToolBarAction";
import { multipleApprove, multipleRemove } from "./helpers/MultipleAction";


const useStyles = makeStyles((theme) => ({
  rootAccordion: {
    borderRadius: "0px !important",
    borderBottom: "1px solid #E2F2F6",
    height: "100%"
  },
  ExpandIcon: {
    "& .MuiAccordionSummary-expandIcon": (props) => ({
      transform: props.expanded ? "rotate(180deg)" : "rotate(270deg)"
    })
  },
  AccordionDetailsRoot: {
    display: "block",
    paddingBottom: "30px !important"
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    padding: '5px 18px',
    minHeight: 44,
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      background: '#e8e8e8',
    },
  }
}));

const MultipleItems = () => {
  const { values, setValues } = useContext( PanelContext);
  const [expanded, setExpanded] = useState(true);
  const cl = useStyles({ expanded });
  const snackbar = useSnackbar();
  const items = values.severalSelectedTreeItem



  const handleItems = async (el) => {

    if(!items || items.length === 0){
      return
    }
    if(el.type === "Approve"){
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Approving" defaultMessage="Approving..." />
      );
       multipleApprove(generatingSnackbar, snackbar, items)
      snackbar.closeSnackbar(generatingSnackbar);
    }
    if (el.type === "Remove") {
      const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Removing" defaultMessage="Removing..." />
      );
      multipleRemove(generatingSnackbar,items,  values, setValues, snackbar)
    }

  }

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(pre => !pre)}
      className={cl.rootAccordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={cl.ExpandIcon}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        Multiple items selected
      </AccordionSummary>
      <AccordionDetails className={cl.AccordionDetailsRoot}>
        {getMultipleItems().map((el, i) => <div key={i} className={cl.action} onClick={() => handleItems(el)}>
          {el.icon} {el.name}
        </div>)}
      </AccordionDetails>
    </Accordion>
  );
};

export default MultipleItems;