import React, { Fragment, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import { ListItemSecondaryAction, ListItemText, ListItem, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import DeleteIcon from "@mui/icons-material/Delete";
import { turnOnCLMLoader } from "../TranslationJobPlugin/tjReducers";
import {deleteSoftWareText, removeSoftWareTerm, removeTerm} from "./API";
import SearchTerms from "./SearchTerms";
import SkeletonLoader from "@zert-packages/components/SkeletonLoader";
import moment from "moment";
import {createPhraseApi, setTerm} from "@zert-packages/components/InfoElement/components/API";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import AddLanguageDialog from "./components/AddLanguageDialog";
import ConfirmDeleteDialog from "./components/ConfirmDeleteDialog";
import BrowseDialog from "../BlockPlugin/components/dialog/browse/BrowseDialog";
import {createInProgressSnack} from "@zert-packages/components/common/snackBar";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
  scrollWrapper: {
    position: "relative",
    width: "25%",
    /*  height: '100%',
        maxHeight: '100%', */
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    overflow: 'hidden',
    "@media (max-width: 900px)": {
      width: "100%",
      height: "40%"
    }
  },
  scrollContainer: {
    position: "absolute",
    width: "100%",
    maxHeight: "100%",
    overflow: "auto",
    scrollbarWidth: "none",
    padding: "1em",
    '& span': {
      wordWrap: 'break-word',
    }
  },
  container: {
    padding: "0px 1em"
  },
  listItem: {
    transition: "all .3s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      transition: "all .3s"
    }
  },
  listItemSelected: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    transition: "all .3s",
  },

  addIcon: {
    height: "16px",
    width: "16px"
  },
  addIconButton: {
    position: "absolute",
    left: -11.25,
    bottom: -11.25,
    zIndex: 1,

    outline: "none"
  },
  addPhrase: {
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    width: '100%',
    color: '#ffffffff',
    textTransform: 'capitalize',
    marginTop: 10,
    border: '1px solid' + theme.palette.primary.light,
    '&:focus': {
      color: theme.palette.primary.light,
      outline: 'none',
    },
    '&:hover': {
      color: theme.palette.primary.light,
      border: '1px solid' + theme.palette.primary.light,
    },
  }
}));
const scrollableListRef = React.createRef();

function SoftwareTextsViewList({
                                 termsList,
                                 terms,
                                 handleAddNewTerm,
                                 handleScrollToItem,
                                 versionId,
                                 scrollToItem,
                                 versionManager,
                                 setTerms,
                                 handlePhrase,
                                 setPhase,
                                 phase,
                                 params
                               }) {
  const cl = useStyles();
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);

  const intl = useIntl();
  const { mylocale, versionInfo } = useSelector((state) => state);
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (scrollableListRef && scrollableListRef.current && scrollableListRef.current.scrollTo) {
      const LIST_ITEM_HEIGHT = 33.5;
      const NUM_OF_VISIBLE_LIST_ITEMS = 9;
      const index = termsList.findIndex((term, index) => {
        return scrollToItem == term.id;
      });

      const amountToScroll = LIST_ITEM_HEIGHT * index;
      scrollableListRef.current.scrollTo(0, amountToScroll);
    }
  }, [scrollToItem]);

  const handleChangeTextField = (e) => {
    e.persist();

    setValue(e.target.value);
  };

  const handleProcessingAddNewTerm = async (e) => {
    e.persist();
    setError(null);

    try {
      await handleAddNewTerm(e, value);
      setValue("");
    } catch (err) {
      if (err && err.message) setError(err.message);
    }
  };

  const approveDelete = async (item) =>{
    const res = termsList.filter(el => el.id !== item.id);
    setTerms(res);
    handlePhrase(res[0], "treeItem");
    await deleteSoftWareText( item.id, versionId);
  }

  const handleDeleteTerm = async (e, item) => {

    e.persist();

    renderInExceptionRoot(ConfirmDeleteDialog, {
      item: item,
      approveDelete:approveDelete
    })
  };


  const addPhrase =async () => {

    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.Loading" defaultMessage="Loading..." />
    );

    const res = await createPhraseApi(
        {
          locale: versionInfo?.info?.locale,
          parentVersionId: params?.versionId
        });

     setPhase(pre=> ({...pre,
       languages: null,
       newPhrase: res,
       selectedPhases: null,
       treeItem: {...res, isSoftwareNewPhrase: true, parentVersionId:params?.versionId }}));

    snackbar.closeSnackbar(generatingSnackbar)
  }


  const handleInsert = async (val) =>{
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="RMP.SnackBar.Loading" defaultMessage="Loading..." />
    );

    const r = await setTerm(params?.versionId,val[0]?.versionId)

    snackbar.closeSnackbar(generatingSnackbar)
    if(r){

      setPhase(prev =>({
        ...prev,
        refresh: !prev.refresh
      }))
    }

  }

  const handleBrowse = () => {
    renderInExceptionRoot(BrowseDialog,
        {
          filteredArr: ['phrase'],
          onSelect: (val) => handleInsert(val)
        })
  }

  return <>
    {termsList ?
      <div className={cl.scrollWrapper}>
        {/*{!versionManager.elementDisabled && (*/}
        {/*  <Box display="grid" padding="1em 1em 0em 1em" gap="0.5em">*/}
        {/*    <TextField*/}
        {/*      id="standard-basic"*/}
        {/*      fullWidth*/}
        {/*      margin="0"*/}
        {/*      label={intl.formatMessage({ id: "SoftwareTextViewList.NewTerm", defaultMessage: "New term" })}*/}
        {/*      onChange={handleChangeTextField}*/}
        {/*      value={value}*/}
        {/*    />*/}
        {/*    <Button color="primary" variant="outlined" onClick={handleProcessingAddNewTerm}>*/}
        {/*      <FormattedMessage id="SoftwareTextViewList.Add" defaultMessage="Add" />*/}
        {/*    </Button>*/}
        {/*  </Box>*/}
        {/*)}*/}
        <div className={cl.container}>
          <div style={{paddingRight: 8}}>
            <SearchTerms
              terms={terms}
            />
            {!versionManager.elementDisabled &&
            <Button onClick={addPhrase} className={cl.addPhrase}>
              add phrase
            </Button>
            }
            {!versionManager.elementDisabled &&
            <Button onClick={handleBrowse} className={cl.addPhrase}>
              Browse
            </Button>
            }
          </div>
        </div>
        <div
          className={cl.scrollContainer}
          ref={scrollableListRef}
          style={{ height: !versionManager.elementDisabled ? "calc(100% - 110px)" : "100%" }}
        >
          {error && (
            <Typography variant="subtitle2" style={{ color: "red" }}>
              {error}
            </Typography>
          )}
          <List dense selected={cl.listItemSelected}>
            {termsList.map((item) => (
              <Fragment key={`termsList${item.id}`}>
                <ListItem
                  classes={{ selected: cl.listItemSelected, root: cl.listItem }}
                  selected={scrollToItem && scrollToItem === item.id}
                  onClick={(e) => handleScrollToItem(e, item)}
                >
                  <ListItemText primary={item.name} />
                  {scrollToItem && scrollToItem === item.id && (
                    <ListItemSecondaryAction>
                      <IconButton style={{
                        color: "red"
                      }} size="small" onClick={(e) => handleDeleteTerm(e, item)}>
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
                <Divider variant="middle" component="li" />
              </Fragment>
            ))}
          </List>
        </div>
      </div>
      : <div className={cl.scrollWrapper}>
        <div className={cl.container}>
          <SkeletonLoader height={38} />
        </div>
      </div>
    }
  </>;
}

export default SoftwareTextsViewList;
