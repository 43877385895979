import React, { useState } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import ExpanderElementProperties from "./ExpanderElementProperties";
import ExpanderLanguageFilters from "./ExpanderLanguageFilters";
import MettaData from "@zert-packages/components/InfoElement/mettaData";

const useStyles = makeStyles((theme) => ({
  rootRightExpanded: {
    height: '100%',
    // maxHeight: 'Calc(100vh - 97px)',
    background: '#FFFFFD',
    marginBottom: 15,
    borderRadius: 5,
    boxShadow: '5px 5px 5px 5px rgb(0 0 0 / 10%)',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  }
}));

const RightMenu = ({context}) => {
  const cl = useStyles();
  const [expandedPane, setExpandedPane] = useState({
    expandedPanelOne: true,
    expandedPanelTwo: true,
    expandedPanelThree: true
  });

  return (<div className={cl.rootRightExpanded}>
    <ExpanderElementProperties
      context={context}
      expanded={expandedPane}
      setExpanded={setExpandedPane}
    />
    {/*<ExpanderLanguageFilters*/}
    {/*  context={context}*/}
    {/*  expanded={expandedPane}*/}
    {/*  setExpanded={setExpandedPane}*/}
    {/*/>*/}
    <MettaData
      context={context}
      expanded={expandedPane}
      setExpanded={setExpandedPane}
    />
  </div>)
}

export default RightMenu