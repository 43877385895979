import {tableStore} from "@zert-packages/components/InfoElement/components/API";
import {isInInterval} from "./tableMethods";
import {v4 as uuidv4} from "uuid";
import {createInProgressSnack} from "@zert-packages/components/common/snackBar";
import {FormattedMessage} from "react-intl";
import React from "react";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import DialogColors from "@zert-packages/components/dialog/DialogColors";
import {adobeColor, adobeRgba} from "../../../StylesheetPlugin/utils/helpers";
import {savePhrase} from "@zert-packages/components/InfoElement/helpers/ToolBarAction";
import {addNewCellTable} from "../components/dialog/AddToolbarDialog/utils";


export const newItem = {
    val: "",
    rowspan: 1,
    colspan: 1,
}

export const findBorderTd = (hoverBlock, hoverDelete, hoverTop, hoverBottom, background, hoverLeft, hoverRight) => {
    if (hoverDelete) {
        return {
            border: '2px solid #FF0000',
            background: background ? '#ff9999' : '',
        }
    }
    if (hoverBlock) {
        return {
            border: '2px solid #388bff',
            background: background ? '#007bff99' : '',
        }
    }

    if (hoverTop) {
        return {
            border: '1px solid #c1c7d0f2',
            borderTop: '2px solid #388bff',
        }
    }
    if (hoverBottom) {
        return {
            border: '1px solid #c1c7d0f2',
            borderBottom: '2px solid #388bff'
        }
    }

    if (hoverLeft) {
        return {
            border: '1px solid #c1c7d0f2',
            borderLeft: '2px solid #388bff'
        }
    }

    if (hoverRight) {
        return {
            border: '1px solid #c1c7d0f2',
            borderRight: '2px solid #388bff'
        }
    }

    return {border: '1px solid #c1c7d0f2'}
}

export const findSelectedItems = (selectedItems, values) => {
    if (selectedItems.length !== 1) {
        return false
    }
    return values?.treeItem?.id !== selectedItems[0].id;
}

export const createTable = (data, schemaGlob, setTableData, setTableDataCopy, setSchema, setSchemaCopy) => {
    setTableData([...data])
    setTableDataCopy([...data])
    setSchema(schemaGlob)
    setSchemaCopy(schemaGlob)
}


export const findHoverY = (i, it) => {
    return isInInterval(it.posY, it.posY + it.colspan - 1, i)
}

export const findDeleteHover = (row, hoverTable, it) => {
    return row.hoverDelete
        || hoverTable.deleteColumn.index === it.posY
        || findHoverY(hoverTable.deleteColumn.index, it)
}

export const findOverflowHeight = (rowHeight, visibleRows) => {
    const h = window.innerHeight;
    if (h < 750) {
        return '55.9vh'
    }
    return rowHeight * visibleRows + 2
}


export const copyElement = (setValues, copyCell) => {
    setValues(pre => ({...pre, copyCell}))
}


export const findKeepRow = (values, selected) => {
    if (selected.length === 0) {
        return 'None'
    }
    const keepRow = values.tableDefault?.rowsKeepTogethers
    const findKeepRow = keepRow.length > 0 && keepRow.find(el => el.row === selected[0].posX)

    if (keepRow.length > 0 && findKeepRow) {
        return findKeepRow.clientTableKeepTogether.toLowerCase()
    }
    return 'None'
}

const handleKeep = (val, setValues) => {
    setValues(pre => ({...pre, tableDefault: {...pre.tableDefault, rowsKeepTogethers: val}}))
}

export const handleKeepWidth = (val, selected, values, setValues) => {
    const rowsKeepTogethers = values.tableDefault.rowsKeepTogethers
    const adobe = selected.map(el => ({row: el.posX, clientTableKeepTogether: val.toUpperCase()}))

    if (rowsKeepTogethers.length === 0) {
        return handleKeep(adobe, setValues)
    }

    const findSelected = rowsKeepTogethers.filter(el => !adobe.find(it => el.row === it.row))
    return handleKeep([...adobe, ...findSelected].sort((a, b) => a.row - b.row), setValues)

}
export const findSelectedColorCell = (val) => {
    const selected = val.length > 0 && val[0]?.colorCol

    if (!selected) {
        return '#ffffff'
    }
    if (selected) {
        return adobeColor(selected)
    }
    return '#ffffff'
}

export const findSelectedColorRow = (selected, tableData) => {

    if (selected.length === 0 || !tableData) {
        return '#ffffff'
    }
    const colorRow = tableData[selected[0].posX]?.colorRow
    if (colorRow) {
        return adobeColor(colorRow)
    }
    return '#ffffff'
}

const findRowsBackgroundColors = (rowsBackgroundColors, selected, color) => {
    const adobe = selected.map(el => ({row: el.posX, clientColorRequest: color}))

    if (rowsBackgroundColors.length === 0) {
        return adobe
    }
    const findSelected = rowsBackgroundColors.filter(el => !adobe.find(it => el.row === it.row))
    return [...adobe, ...findSelected].sort((a, b) => a.row - b.row)

}


const findColorRow = (values, val, selected, setValues, setTableData) => {
    const rowsBackgroundColors = values.tableDefault.rowsBackgroundColors
    const color = {...adobeRgba(val), alpha: 255}

    setValues(pre => ({
        ...pre,
        tableDefault: {
            ...pre.tableDefault,
            rowsBackgroundColors: findRowsBackgroundColors(rowsBackgroundColors, selected, color)
        }
    }))

    setTableData(pre => pre.map(el => {
        if (selected.find(it => it.posX === el?.val[0].posX)) {
            return {...el, colorRow: color, id: uuidv4(),}
        }
        return el
    }))
}

export const handleColorRow = (tableData, selected, values, setValues, setTableData) => {

    if (selected.length === 0) {
        return
    }

    const handleSelectedColor = (val, name) => {
        findColorRow(values, val, selected, setValues, setTableData)
    }

    renderInExceptionRoot(DialogColors, {
        text: {label: 'Selected color'},
        type: 'rowColor',
        setText: handleSelectedColor,
        color: findSelectedColorRow(selected, tableData)
    })
}

const findCellBackgroundColors = (backgroundColors, selected, color) => {

    const adobe = selected.map(el => ({
        clientTablePositionRequest: {column: el.posY, row: el.posX},
        clientColorRequest: color
    }))

    if (backgroundColors.length === 0) {
        return adobe
    }
    const findSelected = backgroundColors.filter(el => !adobe.find(it =>
        el.clientTablePositionRequest.row === it.clientTablePositionRequest.row && el.clientTablePositionRequest.column === it.clientTablePositionRequest.column
    ))
    return [...adobe, ...findSelected]

}

const findColorCell = (val, selected, values, setValues, tableData, setTableData, setSelected) => {
    const colorCol = {...adobeRgba(val), alpha: 255}


    const resultTable = tableData.map((it) => {
        const res = it.val.map((cel) => {
            if (selected.find(el => el.posX === cel.posX && el.posY === cel.posY)) {
                return {...cel, colorCol}
            }
            return cel
        })
        return {...it, val: res}
    })
    setSelected(pre => pre.map(el => ({...el, colorCol})))
    setTableData(resultTable)


    setValues(pre => ({
        ...pre,
        tableDefault: {
            ...pre.tableDefault,
            cellsBackgroundColors: findCellBackgroundColors(pre.tableDefault.cellsBackgroundColors, selected, colorCol)
        }
    }))
}


export const handleColorCell = (tableData, selected, values, setValues, setTableData, setSelected) => {
    if (selected.length === 0) {
        return
    }

    const handleSelectedColor = (val, name) => {
        findColorCell(val, selected, values, setValues, tableData, setTableData, setSelected)
    }

    renderInExceptionRoot(DialogColors, {
        text: {label: 'Selected color'},
        type: 'color',
        setText: handleSelectedColor,
        color: findSelectedColorCell(selected)
    })

}


const findPosX = (copyCell, cur, preCur, firstSelected) => {
    return cur.posX === preCur.posX
        ? preCur.selectedPosX ? preCur.selectedPosX : firstSelected.posX
        : preCur.selectedPosX + 1
}

const findPosY = (copyCell, cur, preCur, firstSelected) => {
    if (cur.posX !== preCur.posX) {
        return firstSelected.posY
    }
    return cur.posY === preCur.posY ? firstSelected.posY : preCur.selectedPosY + 1
}

const adobeCopyCell = (copyCell, firstSelected) => {
    if (copyCell.length === 1) {
        return
    }
    return copyCell.reduce((acu, cur, idx) => {
        if (idx === 0) {
            return [...acu, {
                ...cur,
                selectedPosX: firstSelected.posX,
                selectedPosY: firstSelected.posY,
            }]
        }
        const preCur = acu[idx - 1]
        const adobeCur = {
            ...cur,
            selectedPosX: findPosX(copyCell, cur, preCur, firstSelected),
            selectedPosY: findPosY(copyCell, cur, preCur, firstSelected)
        }

        return [...acu, adobeCur]
    }, [])
}

const createNewCell = (curCell, copyCur) => ({
    ...curCell,
    id: uuidv4(),
    val: copyCur.val,
    default: copyCur.default,
    selected: true,
});

export const pastElement = (copy, selected, tableData, setTableData, setSelected, setValues) => {
    const addCell = []
    const firstSelected = selected[0]
    const copyCell = copy.filter(el => el.val)

    const adobeCells = adobeCopyCell(copyCell, firstSelected)

    const res = tableData.reduce((acc, cur, i) => {
        const val = cur.val.reduce((accCell, curCell, idx) => {

            if (copyCell.length === 1 && i === firstSelected.posX && idx === firstSelected.posY) {
                const newCell = createNewCell(curCell, copyCell[0])
                addCell.push({...newCell, copy: true})
                return [...accCell, {...newCell, copy: true}]
            }

            if (copyCell.length > 1) {
                const findCopyCells = adobeCells.find(el => el.selectedPosX === i && el.selectedPosY === idx)
                if (findCopyCells) {
                    const newCell = createNewCell(curCell, findCopyCells)
                    addCell.push({...newCell, copy: true})
                    return [...accCell, {...newCell, copy: true}]
                }
            }

            return [...accCell, curCell]
        }, [])

        return [...acc, {...cur, val}]
    }, [])
    setTableData(res)
    if (addCell.length > 0) {
        setSelected(addCell)
    }
    setValues(pre => ({...pre, copyCell: null}))
}

const findInternalContent = (cels) => {
    const content = cels.filter(el => el.default)
    if (content.length === 0) {
        return []
    }
    return content.map(el => ({
        clientItem: el.default,
        clientTablePositionRequest: {column: el.posY, row: el.posX}
    }))
}

export const saveTableElement = async (snackbar, values, myLanguage, tableData) => {
    const generatingSnackbar = createInProgressSnack(
        snackbar,
        <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..."/>
    )
    await saveTable(values.tableDefault, myLanguage.value, tableData)
    snackbar.closeSnackbar(generatingSnackbar);
}

const filterInternalSpanmap = (internalSpanmap, selected) => {
    if (!internalSpanmap || internalSpanmap.length === 0) {
        return []
    }

    return internalSpanmap.filter(el => !(el.keyPosition.row === selected.posX
        && el.keyPosition.column === selected.posY))
}

export const handleReuniteCell = (tableData, selected, setSelected, setTableData, setValues) => {
    let rowInd = 0
    let collInd = 0

    // setValues

    setValues(pre => ({
        ...pre, tableDefault: ({
            ...pre.tableDefault,
            internalSpanmap: filterInternalSpanmap(pre.tableDefault.internalSpanmap, selected[0])
        })
    }))

    const rowWithSelected = tableData.reduce((ac, cur, ind) => {
        if (cur.val.some(el => el.id === selected[0].id)) {
            rowInd = ind
            const val = cur.val.reduce((ac1, cur1, ind1) => {
                if (cur1.id === selected[0].id) {
                    collInd = ind1
                    return ac1
                } else {
                    return [...ac1, cur1]
                }
            }, [])
            return [...ac, {...cur, val: val}]
        } else {
            return [...ac, cur]
        }
    }, [])
    const findSelected = []
    for (let i = rowInd; i < rowInd + selected[0].rowspan; i++) {
        for (let j = collInd; j < collInd + selected[0].colspan; j++) {
            const res = {...newItem, id: uuidv4(), posX: i, posY: j}
            if (findSelected.length === 0) {
                findSelected.push(res)
            }
            rowWithSelected[i].val.splice(j, 0, res)
        }
    }
    setSelected(findSelected)
    setTableData(rowWithSelected)
}

export const checkCellLength = (cell) => {
    return cell.colspan > 1 || cell.rowspan > 1
}

export const findSliceRow = (tableData, overflowTable, vue, start, visibleRows) => {
    if (vue) {
        return 3
    }
    if (overflowTable || !tableData) {
        return start + visibleRows + 1
    }
    return tableData.length
}


export const saveTable = async (table, locale, tableData) => {
    console.log('table', table,);
    console.log(' tableData', tableData);
    const cels = tableData.map(el => el.val).flat()
    console.log('cels', cels)
    const adobeTable = {...table, internalContent: findInternalContent(cels)}
    console.log('adobeTable', adobeTable);
    const res = await tableStore(table.elementInfo.versionId, locale, adobeTable)
}

export const remove = (tableData, values, setValues, selected, setTableData, setSelected) => {
    const selectedCell = selected[0]
    if (selected.length !== 1 || !selectedCell.val) {
        return
    }
    const res = tableData.reduce((acu, cur, i) => {
        const val = cur.val.reduce((acuCell, curCell, idx) => {
            if (selectedCell.posX === i && selectedCell.posY === idx) {
                return [...acuCell, {...curCell, val: '', default: false}]
            }
            return [...acuCell, curCell]
        }, [])
        return [...acu, {...cur, val}]
    }, [])
    setTableData(res)
    setSelected([])
    const handleInternalContent = (content) => {
        if (!content || !content.tableDefault || !content.tableDefault.internalContent) {
            return
        }
        return content.tableDefault.internalContent.filter(el => {
            const position = el.clientTablePositionRequest
            return !(position.row === selectedCell.posX && position.column === selectedCell.posY)
        })

    }
    setValues(pre => ({...pre, tableDefault: {...pre.tableDefault, internalContent: handleInternalContent(pre)}}))
}

export const handleSaveElement = async (typeSave, type, item, snackbar, values, activeLocales, myLanguage,) => {
    const mylocale = myLanguage.value


    if (typeSave === "Save") {
        const generatingSnackbar = createInProgressSnack(
            snackbar,
            <FormattedMessage id="ToolBarPhraseEditor.SnackBar.Saving" defaultMessage="Saving..."/>
        );
        if (type === "zert-phrase") {
            const treeItem = {...values.treeItem, onlyTableSave: true,}
            await savePhrase({
                ...values,
                treeItem
            }, activeLocales, mylocale, values, false, false, false, `${item.versionId}`, myLanguage)
        }
        snackbar.closeSnackbar(generatingSnackbar);
    }
}

export const handleName = (val, it, tableData, setTableData, values, setValues) => {

    const newNameElement = {...it.val, elementInfo: {...it.val.elementInfo, name: val}}
    const addName = addNewCellTable(tableData, it, newNameElement)
    setTableData(addName)
    const internalContent = values.tableDefault.internalContent.map(el => {
        const {row, column} = el.clientTablePositionRequest
        if(row === it.posX && column === it.posY){
            return {...el, clientItem: {...el.clientItem,
                    elementInfo: {...el.clientItem.elementInfo, name: val}
                }}
        }
      return el
    })
    console.log('internalContent', internalContent)
    setValues(pre => ({...pre, tableDefault:{...pre.tableDefault, internalContent}}))

}