import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import EntryView from './EntryView';
import EntryDialog from './EntryDialog';
import { loadIndex } from '../TranslationJobPlugin/tjReducers';

const initEntry = {
  category: '',
  text: '',
  textPhoneticReading: '',
  key1: '',
  key1PhoneticReading: '',
  key2: '',
  key2PhoneticReading: ''
};

const initActionState = {
  isOpen: false,
  isEdit: false,
  isCreate: false,
  selectedElement: -1,
  initEntry
};

function EntryTab() {
  const CLM = useSelector((state) => state.CLM);
  const [action, setAction] = useState(initActionState);
  const dispatch = useDispatch();
  const [index, setIndex] = useState(CLM.index);
  const [selectedEntry, setSelectedEntry] = useState(
    action.selectedElement >= 0 ? CLM.index.entries[action.selectedElement] : initEntry
  );

  const handlerCloseDialog = () => {
    setAction((prevState) => ({
      ...prevState,
      selectedElement: -1,
      isEdit: false,
      isCreate: true,
      isOpen: false
    }));
  };

  const handleListItemClick = (event, index) => {
    setAction((prevState) => ({
      ...prevState,
      selectedElement: index,
      isEdit: true
    }));
  };

  const handlerOpenDialog = () => {
    setAction((prevState) => ({
      ...prevState,
      isOpen: true,
      isEdit: true,
      selectedElement: -1
    }));
    setSelectedEntry(initEntry);
  };

  useEffect(() => {
    if (action.selectedElement >= 0) {
      setSelectedEntry(CLM.index.entries[action.selectedElement]);
    }
  }, [action]);

  useEffect(() => {
    if (action.selectedElement >= 0 && action.isEdit) {
      setIndex((prevState) => ({
        ...prevState,
        entries: prevState.entries.map((item, idx) => {
          if (idx === action.selectedElement) {
            return selectedEntry;
          }
          return item;
        })
      }));
    }
  }, [selectedEntry]);

  useEffect(() => {
    if (action.isEdit) {
      dispatch(loadIndex(index));
    } else if (action.isCreate) {
      dispatch(loadIndex(index));
      setAction(initActionState);
    }
  }, [index]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '25%' }}>
        <List component="nav" aria-label="main mailbox folders">
          {CLM.index.entries &&
            CLM.index.entries.map((el, idx) => {
              return (
                <ListItem
                  button
                  selected={action.selectedElement === idx}
                  onClick={(event) => handleListItemClick(event, idx)}
                >
                  <Typography variant="h5">
                    {el.key2} / {el.key1} / {el.text}
                  </Typography>
                </ListItem>
              );
            })}
        </List>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}
        >
          <IconButton aria-label="add" size="large">
            <AddIcon fontSize="large" onClick={handlerOpenDialog} />
          </IconButton>
          <IconButton aria-label="delete" size="large">
            <DeleteIcon fontSize="large" onClick={handlerOpenDialog} />
          </IconButton>
        </div>
      </div>
      {action.selectedElement >= 0 && (
        <div style={{ width: '75%' }}>
          <EntryView actionState={action} entry={selectedEntry} setEntry={setSelectedEntry} />
        </div>
      )}
      {action.isOpen && (
        <EntryDialog
          handleClose={handlerCloseDialog}
          actionState={action}
          setIndex={setIndex}
          entry={selectedEntry}
          setEntry={setSelectedEntry}
        />
      )}
    </div>
  );
}
export default EntryTab;
