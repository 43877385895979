import getParameterAsInt from '@zert-packages/utils/getParameterAsInt';
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from '@mui/material';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import get from 'lodash/get';
import { injectIntl } from 'react-intl';
import useVersionManager from '@zert-packages/utils/useVersionManager';
import { getVersionInfo } from '@zert-packages/actions/coreReducers';
import TabsWithVersionController from '@zert-packages/components/common/TabsWithVersionController';
import CategoriesTab from './СategoriesTab';
import EntryTab from './EntryTab';
import { turnOnCLMLoader } from '../TranslationJobPlugin/tjReducers';
import { fetchIndex } from './servicesMiddlewares';
import { storeIndex } from './API';
import { generatePreviewReport } from '../PreviewPlugin/servicesMiddlewares';
import { generateTermDBExport } from '../TermDBPlugin/serviceMiddleware';

export const formStyle = (theme) =>
  createStyles({
    mainContainer: {
      width: '100%',
      height: '100%'
    },
    rootBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '5px'
    },
    bodyContainer: {
      padding: '8px',
      height: 'calc(100% - 48px)',
      width: '100%',
      minHeight: '300px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',

      '@media (max-width: 900px)': {
        flexDirection: 'column'
      }
    },
    AppBar: {
      display: 'grid',
      gridTemplateColumns: '1fr auto auto',
      zIndex: '10',
      overflow: 'hidden',
      paddingRight: 8
    },
    formControl: {
      margin: theme.spacing(1),
      width: '100%'
    },
    tabs: {
      backgroundColor: '#50c2ea',
      height: '10px',
      '&.MuiTabs-root': {
        minHeight: '21px'
        // backgroundColor: "orange"
      }
    },
    tab: {
      '&.MuiTab-root': {
        minHeight: 0,
        lineHeight: '0.75'
      }
    },
    scrollWrapper: {
      position: 'relative',
      width: '100%%',
      height: '65rem',
      // maxHeight: '100%',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      borderRadius: '5px',
      margin: '2px',
      '@media (max-width: 900px)': {
        width: '100%',
        height: '40%'
      }
    },
    scrollContainer: {
      position: 'absolute',
      width: '100%',
      maxHeight: '90%',
      overflow: 'auto',
      scrollbarWidth: 'none',
      padding: '16px'
    }
  });
const useStyles = makeStyles(formStyle);

function IndexView(props) {
  const cl = useStyles();
  const CLM = useSelector((state) => state.CLM);
  const isLoading = useSelector((state) => state.CLM.isLoading);
  const {
    match: { params }
  } = props;
  const versionId = getParameterAsInt(params.versionId);
  const dispatch = useDispatch();
  const [chosenTab, setChosenTab] = useState('1');
  const routeVersionId = get(props, 'match.params.versionId', null);
  const [versionManager, versionInfo] = useVersionManager();

  useEffect(() => {
    if (routeVersionId === null) return;
    dispatch(getVersionInfo(routeVersionId));
    dispatch(turnOnCLMLoader());
    dispatch(fetchIndex(versionId));
  }, [routeVersionId, versionId]);

  const handleTabChange = (event, newValue) => {
    setChosenTab(newValue);
  };

  const saveIndexVersionHandler = async () => {
    console.log(`TRY TO STORE WITH VERSION ID: ${versionId}`, CLM.index);
    await storeIndex(versionId, CLM.index);
  };

  return (
    <div className={cl.mainContainer}>
      <TabsWithVersionController
        canCreateVersion={versionManager.canCreateNewVersion}
        canSaveVersion={versionManager.canSaveVersion}
        canApproveVersion={versionManager.canApproveVersion}
        onCreateNewVersion={versionManager.createNewVersion}
        onApproveVersion={versionManager.approveVersion}
        onSaveVersion={saveIndexVersionHandler}
        canPreview
        onPreview={() => {
          dispatch(generatePreviewReport(versionInfo.info, -1));
        }}
        canExport
        onExport={() => {
          dispatch(generateTermDBExport(versionId));
        }}
        tabs={[]}
      />

      {CLM.index && (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '20%' }}>Hello world!</div>
          <div style={{ width: '80%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={chosenTab} className={cl.tabs} onChange={handleTabChange}>
                <Tab className={cl.tab} label="Entries" value="1" />
                <Tab className={cl.tab} label="Categories" value="2" />
              </Tabs>
            </Box>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                  {chosenTab === '1' && <EntryTab />}
                  {chosenTab === '2' && <CategoriesTab />}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default injectIntl(IndexView);
