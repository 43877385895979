import { v4 as uuidv4 } from "uuid";
import { getBlockApi, getCountChildApi } from "../API";
import addId from "@zert-packages/utils/addId";
import {useParams} from "react-router-dom";

export const handleValue = (value, name, set, group) => {
  if (group) {
    return set(pre => ({ ...pre, ...group }));
  }
  set(prev => ({
    ...prev,
    [name]: value
  }));
};




export const getChild = async (it, setBlock, mylocale) => {
  const info = it.elementInfo;
  if (info.mimeType.split("/").pop() !== "zert-block") {
    return;
  }
  handleValue(true, "loader", setBlock);
  const result = info && await getBlockApi(info.versionId, mylocale);
  if (result) {
    return setBlock(pre => ({ ...pre,
      "child": { ...result, items: addId(result.items) },
      loader: false
    }));
  }
  setBlock(pre => ({ ...pre, loader: false }));
};


export const addItemId = (item) => {
  return { ...item, identifierParent: item.identifier, identifier: uuidv4(), typeKey: "insert" };
};

export const addItem = (items, item, index) => {
  return items.reduce((acu, cur, idx) => {
    if (idx === index) {
      return [...acu, cur, item];
    }
    return [...acu, cur];
  }, []);
};

export const deleteBlockItems = (itemSelected, setSelectedRange, values, setValues) => {
  handleValue(false, false, setSelectedRange, {
    lastSelectedIndex: -1,
    blockParentSelectedLvl: false
  });
  const valuesProps = {
    blockKey: true,
    treeItemDelete: itemSelected,
    closeChild: null,
    dropBlockTable: false,
    blockKeyRedactor: false
  };
  setValues(pre => ({
    ...pre,
    ...valuesProps,
    stepperKeyIndex: pre.stepperKeyIndex + 1,
    stepperKey: [...pre.stepperKey, {
      ...itemSelected,
      investmentLevelBlock: values.investmentLevel,
      typeKey: "delete"
    }]
  }));
};

export const deleteSelectedBlockItems = (setSelectedRange, setValues) => {
  handleValue(false, false, setSelectedRange, {
    lastSelectedIndex: -1,
    blockParentSelectedLvl: false
  });
  handleValue(false, false, setValues, {
    blockKey: true,
    closeChild: null,
    dropBlockTable: false,
    blockKeyRedactor: false,
    treeItem: null,
    // treeItem: {type: 'create', createItem: true},
    getTreeItemChild: false,
    // blockClickItemsBlock: true,
    //  blockClickCreateItemsBlock: true
  });
};


export const findBlockChild = (selectedBlockChild, el, setBlock, setSelectedRange, arrayParent, index) => {
  if (selectedBlockChild) {
    if (selectedBlockChild.identifier !== el.identifier) {
      return;
    }
    setBlock(pre => ({
      ...pre,
      child: selectedBlockChild
    }));
    return setSelectedRange(pre => ({
      ...pre,
      lastSelectedIndex: 0,
      parentSelectedLvl: {
        ...el,
        child: selectedBlockChild,
        arrayParent,
        index
      },
      selectedBlockChild: null
    }));
  }
};

export const addBlockChild = (blocks, items, index) => {
  return blocks?.reduce((acu, cur, idx) => {
    if (idx === index) {
      return [...acu, items, cur];
    }
    return [...acu, cur];
  }, []);
};


export const handleStepperKey = (values, setValues, valuesBlock, setValueBlock, handleSelectedBlock, insert, looksState, selectedRange, currentFocus, setSelectedRange) => {
  const item = values.stepperKey[values.stepperKey.length - 1];

  if (item.typeKey === "delete") {
    const findIndex = currentFocus !== -1 ? currentFocus : valuesBlock.items.length - 1;
    const copyBlock = {
      insert: true,
      blockItems: item,
      blockItemsParent: selectedRange.blockParentSelectedLvl,
      looksState,
      findIndex,
      stepperKeyIndex: values.stepperKeyIndex - 1,
      stepperKey: values.stepperKey.slice(0, -1)
    };
    const deleteState = {
      treeItemDelete: null
    };
    if (item.investmentLevelBlock < 2) {
      const res = addItem(valuesBlock.items, item, findIndex);
      setValueBlock(pre => ({ ...pre, items: res }));
      return handleSelectedBlock(item, findIndex, copyBlock, deleteState);
    }
    handleSelectedBlock(item, findIndex, copyBlock, deleteState);
    return;
  }

  if (item.typeKey === "insert" || item.typeKey === "create") {
    if (values.investmentLevel === 1 || values.investmentLevel === 0) {
      setValueBlock(pre => ({ ...pre, items: pre.items.filter(el => el.identifier !== item.identifier) }));
    }
    handleValue(false, false, setSelectedRange, {
      lastSelectedIndex: -1,
      blockParentSelectedLvl: false
    });
    setValues(pre => ({
      ...pre,
      treeItem: item,
      stepperKeyIndex: values.stepperKeyIndex - 1,
      stepperKey: values.stepperKey.slice(0, -1),
      blockKey: true,
      treeItemDelete: item,
      closeChild: null,
      dropBlockTable: false,
      blockKeyRedactor: false
    }));
  }
};

export const findSelectedText = (items, text, setValues) => {
  // const result = items.filter(el => el.elementInfo.name.includes(text) && !el.elementInfo.mimeType.includes("/zert-table"));
  const result = items.filter(el => findWord(el, text));
  console.log('result', result);
  setValues(pre => ({
    ...pre, searchNawBar:
    { ...pre.searchNawBar, searchTextItems: result, selectedIndexText: 0 }
  }));
};

const findWord = (el, word) => {
  if(el.elementInfo.mimeType.includes("/zert-table")){
    return
  }
  const name = el.elementInfo.name
  return  name.split(' ').find(el => el.toLowerCase().includes(word.toLowerCase()))
}

export const findSelectedTreeItem = (values, el) => {
  const adaptSelectedItem = values.severalSelectedTreeItem || [values.treeItem]
  const findSelected = adaptSelectedItem.find(it => it?.identifier === el?.identifier)
  if(findSelected){
    return  adaptSelectedItem.filter(it => it?.identifier !== el?.identifier)
  }
  return [...adaptSelectedItem.filter(it => it), el]

}

export const findBorderItem = (el, values, selectedRange, selected) => {
  //|| !block.viewMode
  if(!el ){
    return
  }
  if(values?.severalSelectedTreeItem?.length > 0){
    return values.severalSelectedTreeItem.find(it => (it?.identifier  === el.identifier))
  }
  if (!selectedRange) {
    return !!(values?.treeItem?.identifier === el?.identifier || selected);
  }
  if(el.elementInfo && values?.treeItem?.elementInfo && el.elementInfo?.versionId === values.treeItem.elementInfo?.versionId){
    return  true
  }
  return !!(values?.treeItem?.identifier === el?.identifier || selected);
}

export const findIndexList = (items) => {
  let index = -1
  return items.reduce((acu , cur) => {
    const type = cur?.elementInfo?.mimeType.split("/").pop()
    if(type !== "zert-block"){
      const indexList = index + 1
      index = indexList
      return [...acu , { ...cur, indexList:  indexList }]
    }
    return [...acu , cur]
  }, [])
}

export const getCountChild = async (versionId, handleExpanded) => {
  if(!versionId){
    return
  }
  const count = await getCountChildApi(versionId)
  if(count && count > 0 && count < 6){
    handleExpanded()
  }
}

export const saveInitItem = (res, setValues) => {
  setValues(pre => ({
    ...pre,
    createItem: res.elementInfo,
    beforeTreeItem: [],
    treeItem: res
  }));
}

export const handleTreeItem = (el, setValues, setValueBlock) => {
  setValueBlock(pre => ({...pre,
    items: pre.items.map(item => {
      if(item.elementInfo.currentVersionId === el.elementInfo.currentVersionId){
        return {...item, publishingConditions: el.publishingConditions}
      }
      return item
    })
  }))
  setValueBlock(pre => ({...pre, addPublishingFilters: null}))
}