import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  languageFiltersRoot: {
    flexGrow: 1
  },
  ExpandIcon: {
    '& .MuiAccordionSummary-expandIcon':  (props) => ({
      transform: props.expanded.expandedPanelTwo ? 'rotate(180deg)' : 'rotate(270deg)'
    })
  },
  rootAccordion: {
    borderRadius: '0px !important',
    borderBottom: '1px solid #E2F2F6',
    height: '100%'
  }
}));

function Aliases({ expanded, setExpanded }) {


  const cl = useStyles({expanded});
  const handleChange = () => {
    setExpanded({ ...expanded, expandedPanelTwo: !expanded.expandedPanelTwo });
  };

  return (
    <div className={expanded.expandedPanelTwo ? cl.languageFiltersRoot : ''}>
      <Accordion
        expanded={expanded.expandedPanelTwo}
        onChange={() => handleChange('panel1')}
        className={cl.rootAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={cl.ExpandIcon}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          Aliases
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo
          lobortis eget.
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default Aliases;
