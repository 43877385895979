import React, {memo, useContext, useEffect} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {getPluginByMimeType, getPluginByMimeTypeIcon} from '@zert-packages/utils/getPluginByMimeType';
import useSelectionModel from '@zert-packages/components/EnhancedTable/useSelectionModel';
import EnhancedTable from '@zert-packages/components/EnhancedTable/EnhancedTable';
import selectiveMemoComparison from '@zert-packages/utils/selectiveMemoComparison';
import Icon from '@mui/material/Icon';
import {AudienceSwitcher} from '../PublishingPlugin/components/AudienceSwitcher';
import {usePublishingSorter} from '../PublishingPlugin/helpers/usePublishingSorter';
import useElementActions from './utils/useElementActions';
import {SparePartContext} from "./store/SparePartContext";

export const columns = [
  {
    identifier: 'icon',
    numeric: false,
    disablePadding: false,
    component: 'th',
    scope: 'row'
    // isFilterable: true,
    // label: <FormattedMessage id="PublishingTable.TileTable.Icon" defaultMessage=" "/>
  },
  {
    identifier: 'position',
    numeric: false,
    disablePadding: false,
    component: 'th',
    scope: 'row',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Pos." defaultMessage="Pos." />
  },
  {
    identifier: 'partNum',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Part number" defaultMessage="Part number" />
  },
  {
    identifier: 'name',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Name" defaultMessage="Name" />
  },
  {
    identifier: 'function',
    numeric: false,
    disablePadding: false,
    align: 'left',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Function" defaultMessage="Function" />
  },
  {
    identifier: 'afterSales',
    numeric: true,
    disablePadding: false,
    align: 'right',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.After sales" defaultMessage="After sales" />
  },
  {
    identifier: 'quantity',
    numeric: true,
    disablePadding: false,
    align: 'right',
    isFilterable: true,
    label: <FormattedMessage id="PublishingTable.TileTable.Quantity" defaultMessage="Quantity" />
  }
];

function PartMachineTable({ elements, maxHeight, loading, intl }) {
  const { getSorter } = usePublishingSorter();
  const {values, setValues} = useContext(SparePartContext)

  const getCustomFilter = (element, name) => {
    switch (name) {
      case 'name':
        return element.info.name;
      case 'mimeType':
        const plugin = getPluginByMimeType(element.info.mimeType);
        if (intl && plugin && plugin.trkey) {
          return intl.formatMessage({ id: plugin.trkey, defaultMessage: plugin.defaultMessage });
        }
        return element.info.mimeType;
      default:
        return null;
    }
  };

  const columnHandler = () => {
    const _handler = {
      get(target, key) {
        if (key == 'customComparator') {
          return getSorter(target.identifier);
        }
        if (key == 'customClickHandler' && target.identifier != 'nameNew') {
          return null;
        }
        return target[key];
      }
    };
    return _handler;
  };

  const handler = () => {
    const _handler = {
      get(target, key) {
        if (key === 'name') {
          return (
            <label style={{ display: 'flex', alignItems: 'center', margin: 'unset' }}>
              <Icon
                style={{ color: '#1ba1c5', padding: '0px 3px 0 0' }}
                className="icon-box"
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()

                  setValues(prev => ({
                    ...prev,
                    dialog: {
                      open: true,
                      type: "editPart",
                      editPart: target
                    }
                  }))
                }}
              >
                {target.elementInfo &&
                  target.elementInfo.mimeType &&
                  getPluginByMimeTypeIcon(target.elementInfo.mimeType)}
              </Icon>
              {target.part.formatSettings.settings[0].value && target.part.formatSettings.settings[0].value}
            </label>
          );
        }
        if (key === 'icon') {
          return (
            <Icon
              style={{ backgroundColor: 'green', padding: '0px 3px 0 0', width: 11, height: 11 }}
              className="icon-box"
            />
          );
        }
        if (key === 'partNum') {
          return target.part.articleNumber;
        }
        if (key === 'function') {
          return target.settings.settings[0].value;
        }
        return target[key];
      }
    };
    return _handler;
  };

  const model = elements ? elements.map((element) => new Proxy(element, handler(element))) : [];
  const columnModel = columns ? columns.map((column) => new Proxy(column, columnHandler(column))) : [];
  const selectionModel = useSelectionModel(model, true, 'identifier');

  useEffect(() => {
    if(values?.selectedPart && selectionModel.selected[0] !== values?.selectedPart?.identifier) {
      selectionModel.setSelected([values.selectedPart.identifier])
    }
    // else if(values?.selectedPart === null) {
    //   console.log(values?.selectedPart)
    //   selectionModel.setSelected([])
    // }
  }, [values])

  useEffect(() => {
    if (elements && selectionModel.selected && selectionModel.selected[0] !== values?.selectedPart?.identifier) {
      const selectedElement = elements.find((el) => el.identifier === selectionModel.selected[0]);
      setValues(prev => ({
        ...prev,
        selectedPart: selectedElement,
        selectedElement: selectedElement
      }))
    }
  }, [selectionModel.selected]);

  return (
      <>
        <EnhancedTable
            rows={model}
            identifier="identifier"
            columns={columnModel}
            sorting={<AudienceSwitcher />}
            actions={useElementActions}
            maxHeight={maxHeight}
            sortingKey="position"
            loading={loading}
            getCustomFilter={getCustomFilter}
            sortingOrder="asc"
            selectionModel={selectionModel}
            doNotShowToolbar
        />
      </>
  );
}

export default injectIntl(
  memo(
    ({ elements, maxHeight, loading, intl, versionId }) => (
      <PartMachineTable
        loading={loading}
        intl={intl}
        versionId={versionId}
        elements={elements}
        maxHeight={maxHeight}
      />
    ),
    selectiveMemoComparison(
      (p) => p.elements,
      (a) => a.loading,
      (c) => c.maxHeight
    )
  )
);
