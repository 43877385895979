import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BlockIcon from '@zert-packages/muiadopticons/BlockIcon';
import Tooltip from '@mui/material/Tooltip';
import { PanelContext } from '@zert-packages/components/InfoElement/InfoElementWrapper/InfoElementWrapper';
import renderInExceptionRoot from '@zert-packages/utils/renderInExceptionRoot';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { BlockItemsContext } from '../BlockItems';
import { deleteSelectedBlockItems, handleValue } from '../utils/main';
import AlertTextDialog from './dialog/AlertTextDialog';
import SetupQRDialog from './dialog/SetupQRDialog';
import BrowseDialog from './dialog/browse/BrowseDialog';
import { createImgItem } from '../utils/createItem';
import getCLMPlugins from '../../Environment';
import TextFieldItem from './TextFieldItem';

const useStyles = makeStyles((theme) => ({
  wrapperNewElement: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    border: '1px solid #01A1C7',
    padding: 12
  },
  labelSaveImg: {
    marginTop: 3,
    '& input': {
      display: 'none'
    }
  },
  newElementText: {
    fontSize: 11,
    marginBottom: 0
  },
  tooltipRoot: {
    background: '#ffffff !important',
    color: '#000000',
    border: '1px solid #000000',
    marginTop: 5,
    fontSize: 10
  },
  buttonBrowse: {
    borderRadius: 15,
    color: '#ffffff',
    backgroundColor: '#01A1C7',
    boxSizing: 'border-box',
    fontSize: 12,
    height: 25,
    width: '100%',
    maxWidth: 50,
    textTransform: 'capitalize',
    marginLeft: 10,
    '&:hover': {
      background: '#ffffff',
      color: '#01A1C7',
      border: '1px solid #01A1C7'
    },
    '&:focus': {
      backgroundColor: '#ffffff',
      border: '1px solid #01A1C7',
      outline: 'none',
      color: '#01A1C7'
    }
  },
  wrapperImg: {
    display: 'flex',
    gap: 10,
    alignItems: 'center'
  },
  imgType: {
    width: 25,
    cursor: 'pointer',
    display: 'block'
  }
}));

function AddToolbar({ setSelectedRange, parentItemCreate, contextBlock, createImg }) {
  const cl = useStyles();
  const blockContext = useContext(contextBlock || BlockItemsContext);
  const panelContext = useContext(PanelContext);
  const block = blockContext ? blockContext.block : null;
  const setBlock = blockContext ? blockContext.setBlock : null;
  const values = panelContext ? panelContext.values : null;
  const setValues = panelContext ? panelContext.setValues : null;

  const { mylocale } = useSelector((state) => state);
  const { versionId } = useParams();
  const dispatch = useDispatch();
  const [icons, setIcons] = useState(null);
  const id = values?.parentItem?.elementInfo?.versionId;
  const parentVersionId = id && values?.investmentLevel > 1 ? id : versionId;
  const snackbar = useSnackbar();
  const intl = useIntl();



  const addBlocItems = async (e, el) => {
    e.stopPropagation();
    e.preventDefault();
    if (block.createType === el.mimeType) {
      return;
    }
    if (el.mimeType === 'image') {
      if(createImg){
        return  createImg()
      }
      return createImgItem(
        mylocale,
        parentVersionId,
        dispatch,
        parentItemCreate,
        values,
        setValues,
        setBlock,
        snackbar,
        block
      );
    }
    if(setValues){
      deleteSelectedBlockItems(setSelectedRange, setValues);
    }

    if (el.mimeType === 'terminology-database') {
      const text = intl.formatMessage({
        id: 'formatTemplate.cant.insert',
        defaultMessage: "You can't insert this item in element with format Group."
      });
      return renderInExceptionRoot(AlertTextDialog, { text });
    }
    if (el.mimeType === 'QR') {
      return renderInExceptionRoot(SetupQRDialog, {});
    }
     handleValue(el.mimeType, 'createType', setBlock);
  };

  useEffect(() => {
    const res = getCLMPlugins()
      .filter((el) => el.editor)
      .sort((a, b) => a.sort - b.sort)
      .reduce((acu, cur, idx) => {
        if (idx === 3) {
          const addImg = {
            name: 'QR code',
            mimeType: 'QR'
          };
          return [...acu, addImg, cur];
        }
        return [...acu, cur];
      }, []);
    setIcons(res);
  }, []);



  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const adobeBlockItems = (val) => {
    const keys = Object.keys(val);
    const newObject = {};
    keys.forEach((el) => {
      if (el !== 'references' && el !== 'properties' && el !== 'parents') {
        newObject[el] = val[el];
      }
    });
    return newObject;
  };

  const addBlockItems = (val) => {
    const res = val.map((el) => adobeBlockItems(el)).map((el) => ({ ...el, elementInfo: { ...el } }));
    if (res?.length > 0) {
      setSelectedRange((pre) => ({ ...pre, browseBlock: res }));
    }
  };

  const handleBrowse = () => {
    renderInExceptionRoot(BrowseDialog, {
      filteredArr: null,
      onSelect: (val) => addBlockItems(val)
    });
  };

  return (
    <div className={cl.wrapperNewElement}>
      <p className={cl.newElementText}>
        <FormattedMessage id="add.new.element" defaultMessage="Add new element:" />
      </p>
      <div className={cl.wrapperImg}>
        {icons &&
          icons.map((el, i) => (
            <Tooltip
              key={i}
              title={el.name}
              arrow
              placement="top"
              classes={{
                tooltip: cl.tooltipRoot
              }}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -8]
                      }
                    }
                  ]
                }
              }}
            >
              <div>
                {el.mimeType === 'image' ? (
                  <div
                    onMouseDown={(e) => {
                      addBlocItems(e, el);
                    }}
                  >
                    <BlockIcon
                      color={block.createType === el.mimeType ? '#02A1C8' : '#000000'}
                      svg={el.icon}
                      height={20}
                      width={20}
                      hoverColor="#02A1C8"
                    />
                  </div>
                ) : el.mimeType === 'QR' ? (
                  <div
                    onMouseDown={(e) => {
                      addBlocItems(e, el);
                    }}
                  >
                    <BlockIcon
                      color={block.createType === el.mimeType ? '#02A1C8' : '#000000'}
                      svg={<QrCodeIcon />}
                      height={20}
                      width={20}
                      hoverColor="#02A1C8"
                    />
                  </div>
                ) : (
                  <div
                    onMouseDown={(e) => {
                      addBlocItems(e, el);
                    }}
                  >
                    <BlockIcon
                      color={block.createType === el.mimeType ? '#02A1C8' : '#000000'}
                      svg={el.icon}
                      height={20}
                      width={20}
                      hoverColor="#02A1C8"
                    />
                  </div>
                )}
              </div>
            </Tooltip>
          ))}
      </div>

      <p
        className={cl.newElementText}
        style={{
          marginLeft: 5,
          marginRight: 5
        }}
      >
        <FormattedMessage id="add.existing.element" defaultMessage="Add existing element:" />
      </p>
      <TextFieldItem
        placeholder={intl.formatMessage({ id: 'quick.search', defaultMessage: 'Quick search' })}
        name="quickSearch"
        maxWidth={130}
      />
      <Button className={cl.buttonBrowse} onMouseDown={handleBrowse}>
        <FormattedMessage id="browse" defaultMessage="Browse" />
      </Button>
    </div>
  );
}

export default AddToolbar;
