import { useCallback, useState, useEffect } from "react";
import { handleValue } from "clm/src/plugins/BlockPlugin/utils/main";
import log from "zert-publications/src/view/Log/Log";

function useRoveFocus(size, focus, focusActive = -1, setSelectedRange, setLooksState, blockFocus, crudOperations) {
  const [currentFocus, setCurrentFocus] = useState(-1);
  const [addTranslateEnter, setAddTranslateEnter] = useState(false);
  const [{ left, right, up, down, copyKey, deleteKey, insert, ctrlZ,  }, setKey] = useState({
    left: false,
    right: false,
    up: false,
    down: false,
    copyKey: false,
    deleteKey: false,
    insert: false,
    ctrlZ: false,
  });

  const adobePre = (pre) => {
    if(!pre){
      return 1
    }
    return +pre + 1
  }




  const handleKeyDown = useCallback(e => {

    // if (e.ctrlKey && e.keyCode === 17) {
    //   if (setLooksState) {
    //     setLooksState(pre => !pre);
    //   }
    //   return setKey({ multipleItems: true})
    // }
    if (crudOperations) {
      if(e.ctrlKey && e.keyCode === 90){
        if (setLooksState) {
          setLooksState(pre => !pre);
        }
        return setKey(pre => ({ ctrlZ: adobePre(pre.ctrlZ)}));
      }
      if (e.ctrlKey && e.keyCode === 67) {
        //  Ctrl + C
        if (setLooksState) {
          setLooksState(pre => !pre);
        }
        return setKey({ copyKey: true });
      }
      if (e.ctrlKey && e.keyCode === 86) {
        //  Ctrl + V
        if (setLooksState) {
          setLooksState(pre => !pre);
        }
        return setKey({ insert: true });
      }
      if (e.keyCode === 46) {
        //  Delete
        if (setLooksState) {
          setLooksState(pre => !pre);
        }
        return setKey({ deleteKey: true });
      }
    }
    if (blockFocus) {
      return;
    }
    // document.body.focus();
    if (e.keyCode === 13 && !focus) {
      setAddTranslateEnter(true);
    } else {
      setAddTranslateEnter(false);
    }
    if (e.keyCode === 37) {
      //  left
      if (setLooksState) {
        setLooksState(pre => !pre);
      }
      return setKey({ left: true });
    }
    if (e.keyCode === 39) {
      //right
      if (setLooksState) {
        setLooksState(pre => !pre);
      }
      return setKey({ right: true });
    }
    if (e.keyCode === 38) {
      // up
      setKey({ up: true });
      if (setSelectedRange) {
        handleValue(false, "blockKey", setSelectedRange);
      }
      const res = currentFocus - 1 >= 0 ? currentFocus - 1 : 0;
      return setCurrentFocus(res);
    }
    if (e.keyCode === 40) {
      setKey({ down: true });
      if (setSelectedRange) {
        handleValue(false, "blockKey", setSelectedRange);
      }
      return setCurrentFocus(currentFocus === size - 1 ? 0 : currentFocus + 1);
    }

  }, [size, currentFocus, setCurrentFocus, focus]);



  useEffect(() => {
    if (focusActive >= 0) {
      setCurrentFocus(focusActive);
    }
  }, [focusActive]);



  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [handleKeyDown]);

  return { currentFocus, setCurrentFocus, addTranslateEnter, left, right, down, up, copyKey, deleteKey, insert, ctrlZ, setKey,  };
}

export default useRoveFocus;