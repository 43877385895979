import React, { Fragment, Component } from 'react';
import Select from 'react-select';
import { getUsersQuery } from '@zert-packages/actions/actionplan';
import { FormattedMessage } from 'react-intl';
import AutoGrowInput from '../../shared/AutoGrowInput/AutoGrowInput';
import DeadlinePicker from '../../shared/DeadlinePicker/DeadlinePicker';
import InlineElementEditor from './InlineElementEditor';
import UserSuggestBox from '../../shared/UserSuggestBoxNew/UserSuggestBox';
import Spacer from '../../shared/Spacer';

export const hasMandatoryDataFilled = (deviationtemplate, deviation, columnActivityDisplay, clientTask) => {
  try {
    const templatesFilled = deviationtemplate.columns.filter((col) => {
      let value = null;
      const isMandatory = isMandatoryFunc(columnActivityDisplay, col, clientTask);
      if (!isMandatory) {
        return false;
      }
      const mycell = deviation.cells.find((cell) => cell.columnIdentifier == col.identifier);
      if (mycell != null) {
        value = mycell.value;
      }
      if (!value) {
        return true;
      }
    });
    return templatesFilled.length == 0;
  } catch (e) {
    return true;
  }
};
export const isMandatoryFunc = (columnActivityDisplay, col, clientTask) => {
  try {
    const taskme =
      columnActivityDisplay[`${col.identifier}-${clientTask.currentActivity.id}`].split('-')[
        clientTask.taskAdmin ? 1 : 0
      ];
    return taskme == 'mandatory';
  } catch (e) {
    return false;
  }
};

export const isHiddenFunc = (columnActivityDisplay, col, clientTask) => {
  try {
    const taskme =
      columnActivityDisplay[`${col.identifier}-${clientTask.currentActivity.id}`].split('-')[
        clientTask.taskAdmin ? 1 : 0
      ];
    return taskme == 'hidden';
  } catch (e) {
    return false;
  }
};

export default class InlineDeviationEditor extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const cells = this.props.deviationtemplate.columns.map((col) => {
      // this.props.deviationtemplate.columnActivityDisplay[]
      //
      const isMandatory = isMandatoryFunc(this.props.deviationtemplate.columnActivityDisplay, col, this.props.task);
      const isHidden = isHiddenFunc(this.props.deviationtemplate.columnActivityDisplay, col, this.props.task);
      if (!isHidden) {
        // this.props.deviationtemplate.columnActivityDisplay[col.identifier + ""+this.props.clientTask.currentActivity.id]
        if (col.class.includes('IntegerColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform">
                <InlineDeviationIntegerEditorSelect
                  column={col}
                  isMandatory={isMandatory}
                  onUpdate={this.props.onUpdate}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
        if (col.class.includes('UserColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform">
                <InlineDeviationUserEditorSelect
                  column={col}
                  onUpdate={this.props.onUpdate}
                  isMandatory={isMandatory}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
        if (col.class.includes('MultiSelectColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform">
                <InlineMultiDeviationEditorSelect
                  column={col}
                  isMandatory={isMandatory}
                  onUpdate={this.props.onUpdate}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
        if (col.class.includes('SelectColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform">
                <InlineDeviationEditorSelect
                  column={col}
                  onUpdate={this.props.onUpdate}
                  isMandatory={isMandatory}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
        if (col.class.includes('TextColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform" key={col.identifier}>
                <InlineDeviationEditorText
                  column={col}
                  isMandatory={isMandatory}
                  onUpdate={this.props.onUpdate}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
        if (col.class.includes('ElementColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform">
                <InlineElementEditor
                  column={col}
                  isMandatory={isMandatory}
                  onUpdate={this.props.onUpdate}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
        if (col.class.includes('DateColumn')) {
          return (
            <Fragment key={col.identifier}>
              <Spacer />
              <div className="riskform">
                <InlineDeviationDateEditor
                  column={col}
                  isMandatory={isMandatory}
                  onUpdate={this.props.onUpdate}
                  deviation={this.props.deviation}
                  template={this.props.deviationtemplate}
                />
              </div>
            </Fragment>
          );
        }
      }
    });
    return <>{cells}</>;
  }
}

class InlineDeviationDateEditor extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onChangeDateCallback = this.onChangeDateCallback.bind(this);
  }

  onChange(e) {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    this.props.deviation.changed = true;
    cell.value = e.target.value;
    this.props.onUpdate(this.props.deviation);
  }

  onChangeDateCallback(date) {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });

    cell.value = date;
  }

  render() {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    // if (cell.value) {
    const { value } = cell;
    // }
    return (
      <div className="row-wrapper">
        <span className="captionDeviation">
          {this.props.column.name.defaultLabel + ' ' + (this.props.isMandatory ? '*' : '')}
        </span>
        <DeadlinePicker deadline={value} onChangeCallback={this.onChangeDateCallback} />
      </div>
    );
  }
}

class InlineDeviationEditorText extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    this.props.deviation.changed = true;
    cell.value = e.target.value;
    this.props.onUpdate(this.props.deviation);
  }

  render() {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });

    const { value } = cell;

    return (
      <div className="row-wrapper">
        <AutoGrowInput
          defaultValue={value}
          placeholder={`${this.props.column.name.defaultLabel} ${this.props.isMandatory ? '*' : ''}`}
          onChangeCallback={this.onChange}
        />
      </div>
    );
  }
}

class InlineDeviationUserEditorSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeUser = this.onChangeUser.bind(this);
  }

  onChangeUser(e) {
    if (e && e.name) {
      const condition = this.props.column.identifier;
      const cell = this.props.deviation.cells.find(function (cell) {
        if (cell.columnIdentifier === condition) {
          return true;
        }
      });
      this.props.deviation.changed = true;
      cell.value = e;
      this.props.onUpdate(this.props.deviation);
    }
  }

  render() {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });

    const { value } = cell;

    return (
      <div className="row-wrapper">
        <label htmlFor={this.props.column.identifier} className="captionDeviation">
          {`${this.props.column.name.defaultLabel} ${this.props.isMandatory ? '*' : ''}`}
        </label>
        <div>
          <UserSuggestBox
            readonly={this.props.readonly}
            onChange={this.onChangeUser}
            getUsers={getUsersQuery}
            getUserId={(user) => user.username}
            getUserLabel={(user) => user.name}
            initialValue={value && value.name}
            label={<FormattedMessage id="DelegateTasksSettingsDialog.UserLabel" defaultMessage="User" />}
          />
        </div>
      </div>
    );
  }
}

class InlineDeviationIntegerEditorSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    this.number = React.createRef();
    const { value } = cell;
    this.state = { value };
  }

  onChange(e) {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    this.props.deviation.changed = true;
    cell.value = e.target.value;
    this.setState({ value: cell.value });
    this.props.onUpdate(this.props.deviation);
  }

  render() {
    return (
      <div className="row-wrapper">
        <label htmlFor={this.props.column.identifier} className="captionDeviation">
          {`${this.props.column.name.defaultLabel} ${this.props.isMandatory ? '*' : ''}`}
        </label>
        <div>
          <input type="number" ref={this.number} onChange={this.onChange} min="1" value={this.state.value} />
        </div>
      </div>
    );
  }
}

class InlineMultiDeviationEditorSelect extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    let multiSelectValue = '';
    cell.value.map((currentValue) => {
      multiSelectValue = `${multiSelectValue},${currentValue}`;
    });
    this.state = { value: multiSelectValue };
  }

  handleSelectChange(value) {
    const ids = value.split(',');
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });
    cell.value = ids;
    this.setState({ value });
    this.props.onUpdate(this.props.deviation);
  }

  render() {
    const alternatives = this.props.column.alternatives.map((participant, index) => {
      return { value: index, label: participant.text.defaultLabel };
    });

    return (
      <div className="row-wrapper">
        <label htmlFor={this.props.column.identifier} className="captionDeviation">
          {`${this.props.column.name.defaultLabel} ${this.props.isMandatory ? '*' : ''}`}
        </label>
        <div>
          <Select
            closeOnSelect={false}
            disabled={false}
            multi
            onChange={this.handleSelectChange}
            options={alternatives}
            placeholder="Select alternatives"
            removeSelected
            simpleValue
            value={this.state.value}
          />
        </div>
      </div>
    );
  }
}

class InlineDeviationEditorSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    if (e.target.value != -1) {
      this.props.deviation.changed = true;
      const condition = this.props.column.identifier;
      const cell = this.props.deviation.cells.find(function (cell) {
        if (cell.columnIdentifier === condition) {
          return true;
        }
      });
      cell.value = e.target.value;
      this.props.onUpdate(this.props.deviation);
    }
  }

  render() {
    const condition = this.props.column.identifier;
    const cell = this.props.deviation.cells.find(function (cell) {
      if (cell.columnIdentifier === condition) {
        return true;
      }
    });

    const { value } = cell;

    return (
      <div className="row-wrapper">
        <label htmlFor={this.props.column.identifier} className="captionDeviation">
          {`${this.props.column.name.defaultLabel} ${this.props.isMandatory ? '*' : ''}`}
        </label>
        <div>
          <select
            id={this.props.column.identifier}
            defaultValue={value}
            onChange={this.onChange}
            className="form-control animated"
          >
            {this.props.column.alternatives.map((l) => {
              return (
                <option value={l.value} key={l.value}>
                  {l.text.defaultLabel}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }
}
