import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import handleValueState from "@zert-packages/utils/handleValueState";
import renderInExceptionRoot from "@zert-packages/utils/renderInExceptionRoot";
import GroupDialog from "./dialog/GroupDialog";
import { useSelector } from "react-redux";


const useStyles = makeStyles((theme) => ({
  group: (props) => ({
    textTransform:   'capitalize',
    color:  props.select ? '#01A1C7' : '#919191',
    cursor: props.blockClick ? 'not-allowed': 'pointer',
    '&:hover': {
      textDecoration: props.blockClick ? '': 'underline',
    },
  }),
}));


const FormatBlock = ({ select, blockClick, type, el, setBlock, parentItemCreate}) => {
  const cl = useStyles({ select, blockClick })
  const [format, setFormat] = useState({
    selectFormat: null,
    formats: null,
  })
  const info = el?.elementInfo || el?.info
  const getFormat = info?.properties?.["zert:Format"];


  const openGroupDialog = (e) => {
    if (select) {
      e.stopPropagation();
    }
    if (blockClick) {
      return;
    }
    renderInExceptionRoot(GroupDialog, {format, setBlock, el, parentItemCreate, });
  };


  useEffect(() => {
    if(getFormat && !format.selectFormat){
      handleValueState(getFormat, 'selectFormat', setFormat, {
        selectFormat: getFormat,
        formats: [getFormat]
      })
    }
  }, [el])


  if(type !== "zert-block"){
    return
  }




  return(<div className={cl.group} onClick={(e) => openGroupDialog(e)}>
    {format?.selectFormat}
  </div>)
}

export default FormatBlock

